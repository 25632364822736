import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import reducers from 'src/reducers';
import config from 'config';

const middleware = getDefaultMiddleware();

if(__DEVELOPMENT__) {
    middleware.push(createLogger(config['redux-logger'] || {}));
}

const store = configureStore({
    devTools: false,
    reducer : reducers,
    middleware
});

export default store;
