import React, { useEffect, useState, useMemo, Fragment, useCallback, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import Loader from 'component/loader';
import Error from 'component/error';
import Input from 'component/form/input';
import Button from 'component/button';
import Form, { useRegistry } from 'component/form';

import { updateUserInfo } from 'component/api/user';
import { set } from 'component/user/actions';

import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';

import style from './style.pcss';

// @ts-ignore
import contract from './docs/contract.pdf';
import { useAlert } from 'component/alert/provider';

const Phone = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [tokenEditPhone] = useCancelTokens(1);
    const dispatch = useDispatch();
    const phoneRegistry = useRegistry();
    const [error, setError] = useState<string | null>(null);
    const { show } = useAlert();

    const [pending, setPending] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);

    const userPhone = useSelector<IStore, string | undefined>((store) => store[keyUser].fps_phone_number);
    let moderationPhone = useSelector<IStore, string | undefined>((store) => store[keyUser]?.moderation_user?.fps_phone_number || '');

    useEffect(() => {

        return () => {
            tokenEditPhone.remove();
        };
    }, []);

    const _requestEditPhone = (formatPhone: string) => {
        setPending(true);
        setError(null);

        updateUserInfo({
            data: {
                fps_phone_number: formatPhone
            },
            cancelToken: tokenEditPhone.new()
        })
            .then(() => {
                dispatch(set({
                    moderation_user: {
                        fps_phone_number: formatPhone
                    }
                }));

                moderationPhone = formatPhone;
                setEditMode(!editMode);
                setPending(false);
                show(t('route.dashboard.phone.message-ok'));
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);
                    setPending(false);
                    setError(err.message);
                }
            });
    };

    const onChangeEditPhone = useCallback(() => {
        setEditMode(!editMode);
    }, [editMode]);

    const onSubmit = useCallback(() => {
        const fields = phoneRegistry.form.getFields();
        if (fields) {
            const fieldPhone = fields.get('user_phone');
            if (fieldPhone) {
                if (!fieldPhone.value) {
                    fieldPhone.setError(t('route.dashboard.phone.error'));
                } else {
                    const re = /^(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
                    const isPhone = re.exec(fieldPhone.value);
                    if (!isPhone) {
                        fieldPhone.setError(t('route.dashboard.phone.error-format'));
                    } else {
                        const formatPhone = fieldPhone.value.replace(/\D/g, '');
                        _requestEditPhone(formatPhone);
                    }
                }
            }
        }
    }, [userPhone, editMode]);

    const elContract = (text: string): ReactNode => {
        return (
            <a
                href={contract}
                target="_blank"
                className={cn('dashboard__grid-item_phone-agreement')}
            >
                {text}
            </a>
        );
    };

    const elPhone = useMemo(() => {
        if (pending) {
            return <Loader />;
        }

        const formatPhone = userPhone
            ? userPhone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2-$3-$4')
            : '';

        if (moderationPhone) {
            const formatModerationPhone = moderationPhone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2-$3-$4');

            return (
                <Fragment>
                    <h4 className={cn('dashboard__grid-header')}>{formatPhone}</h4>
                    <h4 className={cn('dashboard__grid-item_phone-moderation')}>
                        {t('route.dashboard.phone.moderation', {phone: formatModerationPhone})}
                    </h4>
                    {elContract(t('route.dashboard.contract.agreement'))}
                </Fragment>
            );
        }

        if (!editMode && userPhone) {
            return (
                <Fragment>
                    <h4 className={cn('dashboard__grid-header')}>{formatPhone}</h4>
                    <h4 className={cn('dashboard__grid-item_phone-change')} onClick={onChangeEditPhone}>
                        {t('route.dashboard.phone.button-change')}
                    </h4>
                    {elContract(t('route.dashboard.contract.view'))}
                </Fragment>
            );
        }

        return (
            <>
                <Form registry={phoneRegistry.form} onSubmit={onSubmit} className={cn('dashboard__grid-item_phone-form')}>
                    <Input
                        registry={phoneRegistry.field}
                        name="user_phone"
                        defaultValue={formatPhone}
                        className={cn('dashboard__grid-item_phone-input')}
                        placeholder="(XXX) XXX-XX-XX"
                    />
                    <Button
                        type="submit"
                        disabled={pending}
                        isLoading={pending}
                        className={cn('dashboard__grid-item_phone-button')}
                    >
                        {t('route.dashboard.phone.button-save')}
                    </Button>
                </Form>
                {error && <Error>{error}</Error>}
                {elContract(t('route.dashboard.contract.agreement'))}
            </>
        );
    }, [userPhone, editMode, pending, error]);

    return (
        <>
            <div className={cn('dashboard__grid-item', 'dashboard__grid-item_phone')}>
                <h4 className={cn('dashboard__item-header')}>{t('route.dashboard.phone.header')}</h4>
                {elPhone}
            </div>
        </>
    );
};

export default Phone;
