import React, { FC } from 'react';
import Carousel, { RenderArrowProps } from 'react-elastic-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

import { useSelector } from 'react-redux';
import { IStore } from 'src/types/reducers';
import { key as keyDeviceInfo } from 'component/device-info/reducer';
import { useClassnames } from 'hook/use-classnames';

import { IProps } from './types';
import style from './index.pcss';

const PersonCarousel: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const isMobile = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].mobile);
    const itemsCount = isMobile ? 3 : 5;

    const elNavBtn = ({ type, onClick, isEdge }: RenderArrowProps) => {
        const pointer = type === 'PREV'
            ? <FontAwesomeIcon icon={faChevronCircleLeft} />
            : <FontAwesomeIcon icon={faChevronCircleRight} />;

        return (
            <div
                className={cn('carousel__btn', {
                    'carousel__btn-disable': isEdge
                })}
            >
                <button
                    onClick={onClick}
                    disabled={isEdge}
                >
                    {pointer}
                </button>
            </div>
        );
    };

    const params = {
        isRTL: false,
        itemsToShow: itemsCount,
        itemsToScroll: itemsCount,
        pagination: false,
        enableMouseSwipe: false,
        renderArrow: elNavBtn
    };

    return (
        <Carousel
            {...params}
        >
            {props.children}
        </Carousel>
    );
};

export default PersonCarousel;
