import { combineReducers } from 'redux';

import deviceInfoReducer, { key as deviceInfoKey } from 'component/device-info/reducer';
import userReducer, { key as userKey } from 'component/user/reducer';
import cartReducer, { key as cartKey } from 'route/cart/reducer';
import localConfigReducer, { key as localConfigKey } from 'component/local-config/reducer';

import { IStore } from './types/reducers';

export default combineReducers<IStore>({
    [userKey]      : userReducer,
    [deviceInfoKey]: deviceInfoReducer,
    [cartKey]: cartReducer,
    [localConfigKey]: localConfigReducer
});
