import React, { FC } from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Question: FC<IProps> = (props) => (
    <svg {...defaultProps} {...props}>
        <path
            d="M13,8c0,1.061-0.421,2.078-1.172,2.828C11.078,11.579,10.061,12,9,12s-2.078-0.421-2.828-1.172
            C5.421,10.078,5,9.061,5,8s0.421-2.078,1.172-2.828C6.922,4.421,7.939,4,9,4s2.078,0.421,2.828,1.172C12.579,5.922,13,6.939,13,8z
             M17,18v2H1v-2c0-2.21,3.58-4,8-4S17,15.79,17,18z M20.5,14.5V16H19v-1.5H20.5z M18.5,9.5H17V9c0-0.796,0.316-1.559,0.879-2.121
            C18.441,6.316,19.204,6,20,6s1.559,0.316,2.121,0.879C22.684,7.441,23,8.204,23,9c0,0.97-0.5,1.88-1.29,2.41l-0.3,0.19
            c-0.57,0.4-0.91,1.01-0.91,1.7v0.2H19v-0.2c0-1.19,0.6-2.3,1.59-2.95l0.29-0.19C21.27,9.9,21.5,9.47,21.5,9
        	c0-0.398-0.158-0.779-0.439-1.061S20.398,7.5,20,7.5s-0.779,0.158-1.061,0.439S18.5,8.602,18.5,9V9.5z"
        />
    </svg>
);

export default Question;
