import React, { useMemo, useCallback, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { useClassnames } from 'hook/use-classnames';
import Avatar from 'component/avatar';

import { IProps } from './types';
import style from './index.pcss';

const MiniInfo = (props: IProps) => {
    const cn = useClassnames(style, props.className, true);

    const onClick = useCallback(() => {
        props.onClick && props.onClick();
    }, [props.onClick]);

    const elTitle = useMemo(() => {
        if(props.title) {
            const attrs = {
                className: cn('mini-info__title'),
                children : props.title
            };

            if(props.titleTo) {
                return <Link {...attrs} to={props.titleTo} />;
            }

            if(typeof props.title !== 'string') {
                return <div {...attrs} />;
            }

            return <strong {...attrs} />;
        }
    }, [props.title, props.titleTo]);

    const elDesc = useMemo(() => {
        if(props.desc) {
            const attrs = {
                className: cn('mini-info__desc'),
                children : props.desc
            };

            if(props.descTo) {
                return <Link {...attrs} to={props.descTo} />;
            }

            if(typeof props.desc !== 'string') {
                return <div {...attrs} />;
            }

            return <span {...attrs} />;
        }
    }, [props.desc, props.descTo]);

    return (
        <div
            onClick={onClick}
            className={cn('mini-info', {
                'mini-info__func-hover': props.onClick
            })}
        >
            <Avatar {...props.avatar} size={props.size} />
            <div className={cn('mini-info__content')}>
                {elTitle}
                {elDesc}
            </div>
        </div>
    );
};

MiniInfo.defaultProps = {
    size: 48
};

export default MiniInfo;
