import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { YaPhotoCreate } from './types';

export const yandexPhotoCreate = (
    paths: Array<string>,
    photo_data?: {
        price: number,
        event_id: number,
        album_id?: number
    }
): Promise<AxiosResponse<YaPhotoCreate>> => {
    return httpClient.post<YaPhotoCreate>('/api/v2/cloud/yandex/photo/', {
        paths,
        photo_data
    });
};
