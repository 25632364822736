import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import UI from 'component/ui';
import Form from 'component/form';
import { useClassnames } from 'hook/use-classnames';
import InputPhoto from 'component/form/input-photo';
import IconCross from 'component/icon/cross';
import { DataFilesItem } from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { getPersonListByTmplFile } from 'component/api/main';
import { useCancelToken } from 'component/core/cancel-token';

import { IProps } from './types';
import style from './styles.pcss';

const SearchPerson: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const token = useCancelToken();

    const $canvas = useRef<HTMLCanvasElement>(null);
    const $image = useRef<HTMLImageElement>(null);

    const [image, setImage] = useState<DataFilesItem | null>(null);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    useEffect(() => {
        if (image) {
            props.onFileLoaded && props.onFileLoaded(image);
        }
    }, [image]);

    const onFileLoaded = (file: DataFilesItem): void => {
        setImage(file);

        if (!file?.id) {
            return;
        }

        getPersonListByTmplFile({
            cancelToken: token.new(),
            params     : {
                tmp_file_id: file?.id
            }
        })
            .then((resp) => {
                if (resp?.persons.length) {
                    const filePersons = {
                        ...file,
                        tmp_faces: [
                            {
                                box: [],
                                persons: resp?.persons || []
                            }
                        ]
                    };
                }
            })
            .catch((error) => {
                if(!axios.isCancel(error)) {
                    console.error((error));
                }
            });
    };

    const onClickRemove = useCallback((e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        const fields = props.form.getFields();

        if (fields) {
            const photo = fields.get('photo');

            if (photo) {
                photo.clear();
            }
        }

        setImage(null);
    }, []);

    const elImageResult = () => {
        if(image?.url) {
            const ctx = $canvas.current?.getContext('2d');
            const rects = image.tmp_faces?.map((item) => item.box);

            // TODO тестовая версия
            rects?.forEach((rect) => {
                if(ctx && $image.current) {
                    // const imageWidthRatio = $image.current.naturalWidth / $image.current.clientWidth;
                    const imageHeightRatio = $image.current.naturalHeight / $image.current.clientHeight;

                    ctx.beginPath();
                    ctx.strokeStyle = 'black';
                    ctx.moveTo(rect[0] / imageHeightRatio , rect[1] / imageHeightRatio);
                    ctx.lineTo(rect[2] / imageHeightRatio , rect[1] / imageHeightRatio); // top left to top right
                    ctx.lineTo(rect[2] / imageHeightRatio , rect[3] / imageHeightRatio); // top right to bottom right
                    ctx.lineTo(rect[0] / imageHeightRatio , rect[3] / imageHeightRatio); // bottom right to bottom left
                    ctx.lineTo(rect[0] / imageHeightRatio , rect[1] / imageHeightRatio); // bottom left to top left
                    ctx.stroke();
                    ctx.closePath();
                }
            });

            return (
                <div className={cn('search-person__image-box')}>
                    {/* <canvas ref={$canvas} className={cn('search__canvas')} /> */}
                    <img ref={$image} alt={image.url} className={cn('search-person__photo-image')} src={image.url} />
                </div>
            );
        }
    };

    return (
        <UI.Box padding={true} className={cn('search-person')}>
            <UI.BoxHeader>Поиск по фото</UI.BoxHeader>
            <Form registry={props.form}>
                {elImageResult()}
                <InputPhoto
                    defaultValue={image || undefined}
                    onFileLoaded={onFileLoaded}
                    registry={props.field}
                    name="photo"
                    skipRecognition={true}
                    hideBtn={true}
                />
                {image?.url && (
                    <div className={cn('search-person__photo-menu')}>
                        <div onClick={onClickRemove} className={cn('search-person__photo-menu-item')}>
                            <IconCross className={cn('search-person__photo-menu-icon')} />
                            Сбросить поиск
                        </div>
                    </div>
                )}
            </Form>
        </UI.Box>
    );
};

export default SearchPerson;
