import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';

import { useClassnames } from 'hook/use-classnames';
import { useCancelToken } from 'component/core/cancel-token';
import UI from 'component/ui';
import Loader from 'component/loader';
import ErrorBlock from 'component/error';

import { getOrderHistory } from 'component/api/payment';
import { DataOrderListItem } from 'component/api/types/api/payment/order/history/get/code-200';

import style from './index.pcss';
import { Link } from 'react-router-dom';

const History = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const token = useCancelToken();

    const [error, setError] = useState<string | null>(null);
    const [pending, setPending] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [list, setList] = useState<Array<DataOrderListItem>>([]);

    useEffect(() => {
        _request();
    }, []);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const _request = useCallback(() => {
        setPending(true);

        getOrderHistory({
            params: {
                payment_status: 'PAYED'
            },
            cancelToken: token.new()
        })
            .then((resp) => {
                const newList = resp.order_list.filter((item) => item.total_price > 0 && item.payment_status === 'PAYED');

                setTotal(resp.total_count);
                setList(newList);
                setPending(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);
                    setError(err.message || t('route.history.error'));
                    setPending(false);
                }
            });
    }, []);

    const elOrderList = useMemo(() => {
        if (list.length) {
            return (
                <div className={cn('history__order-list')}>
                    {list.map((item) => {
                        const photoList = item.order_items.filter(({ item_type }) => (item_type === 'PHOTO'));
                        const adList = item.order_items.filter(({ item_type }) => (item_type === 'AD'));
                        const subscribeList = item.order_items.filter(({ item_type }) => (item_type === 'FIND_PARTNER_MONTH'));

                        return (
                            <Link to={`/history/${item.id}`} className={cn('history__list-item')} key={item.id}>
                                <span className={cn('history__item-text')}>{t('route.history.orders-header.order-number', { id: item.id })}</span>
                                <span className={cn('history__item-text')}>{t('route.history.orders-header.order-date', { date: moment(item.paid_at).format('LL') })}</span>
                                <span className={cn('history__item-text')}>
                                    {photoList.length > 0 && (
                                        <span>{t('route.history.orders-header.photo-count', { count: photoList.length })}<br /></span>
                                    )}
                                    {adList.length > 0 && (
                                        <span>{t('route.history.orders-header.ad-count', { count: adList.length })}<br /></span>
                                    )}
                                    {subscribeList.length > 0 && (
                                        <span>{t('route.history.orders-header.subscribe-count', { count: subscribeList.length })}<br /></span>
                                    )}
                                </span>
                                <span className={cn('history__item-text')}>{t('route.history.orders-header.order-price', { count: item.total_price })}</span>
                            </Link>
                        );
                    })}
                    <div className={cn('history__item-count')}>{t('route.history.count', { count: list.length, total })}</div>
                </div>
            );
        }
    }, [JSON.stringify(list)]);

    const elError = useMemo(() => {
        if(error) {
            return <ErrorBlock className={cn('history__error')}>{error}</ErrorBlock>;
        }
    }, [error]);

    if(pending) {
        return <Loader className={cn('history__loader')} />;
    }

    return (
        <UI.Main className={cn('history')}>
            <h1 className={cn('history__header')}>{t('route.history.header')}</h1>
            <UI.Box padding={true} className={cn('history__content')}>
                {elOrderList}
                {elError}
            </UI.Box>
        </UI.Main>
    );
};

export default History;
