import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import useClassnames from 'hook/use-classnames';
import { useCancelToken } from 'component/core/cancel-token';
import { getPhotoBanner } from 'component/api/banners';
import { Data as PhotoBannerData } from 'component/api/types/api/photo_ad/photo_ad/get_photo_ad_by_photo_id/get/code-200';
import Loader from 'component/loader';
import Button from 'component/button';
import PhotoAdForm from 'component/form/banner-form/photo-ad-form';
import { useRegistry } from 'component/form';
import Modal from 'component/modal';
import { IPayload } from 'component/form/types';

import { IProps } from './types';
import style from './styles.pcss';

const Banner: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);
    const { t } = useTranslation();
    const token = useCancelToken();
    const registry = useRegistry();

    const [banner, setBanner] = useState<PhotoBannerData | null>(null);
    const [pending, setPending] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);

    useEffect(() => {
        _request();

        return () => {
            token.remove();
        };
    }, [props.photoId]);

    const _request = useCallback(() => {
        setPending(true);

        getPhotoBanner({
            cancelToken: token.new(),
            params: {
                photo_id: props.photoId
            }
        }).then((resp) => {
            setBanner(resp);
            setPending(false);
        }).catch((err) => {
            if(!axios.isCancel(err)) {
                setBanner(null);
                setPending(false);
            }
        });
    }, [props.photoId]);

    const onSubmit = useCallback((payload: IPayload): void => {
        setModal(false);

        _request();
    }, [modal]);

    const elModal = useMemo(() => {
        if (modal) {
            return (
                <Modal onClickClose={() => setModal(false)}>
                    <h2>{t('components.banner.title')}</h2>
                    <PhotoAdForm
                        registry={registry}
                        photoId={props.photoId}
                        onSubmit={onSubmit}
                    />
                </Modal>
            );
        }
    }, [modal, props.photoId]);

    const elContent = useMemo(() => {
        if(pending) {
            return <Loader />;
        }

        if (banner?.reserve && !banner?.is_active) {
            return (
                <div>
                    <span className={cn('banner__content-text')}>
                        {t('components.banner.reserve', {
                            date: moment(banner.reserve).format('LT')
                        })}
                    </span>
                </div>
            );
        }

        if (!banner || !banner?.banner || !banner?.is_active) {
            return (
                <div className={cn('banner__content')}>
                    <Button onClick={() => setModal(true)}>
                        {t('components.banner.add-banner')}
                    </Button>
                </div>
            );
        }

        return (
            <div className={cn('banner__content')}>
                <span className={cn('banner__content-text')}>{banner.banner.theme?.name}</span>
                <div className={cn('banner__content-banner')}>
                    <div className={cn('banner__content-img')}>
                        {
                            banner.banner.link
                                ? (
                                    <a href={banner.banner.link} target="_blank">
                                        <img alt={banner.banner.text} src={banner.banner.image_url} />
                                    </a>
                                )
                                : <img alt={banner.banner.text} src={banner.banner.image_url} />
                        }
                    </div>
                </div>
            </div>
        );
    }, [pending, banner]);

    return (
        <div className={cn('banner')}>
            {elModal}
            {elContent}
        </div>
    );
};

export default Banner;
