import React, { FC } from 'react';
import { useClassnames } from 'hook/use-classnames';
import { Link } from 'react-router-dom';

import Avatar from 'component/avatar';
import Button from 'component/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTshirt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { IProps, IAdInfoProps } from './types';
import style from './style.pcss';

const AdInfo: FC<IAdInfoProps> = (props) => {
    const cn = useClassnames(style);

    return (
        <div className={cn('list-item__wrapper')}>
            <div className={cn('list-item__content', 'list-item__content-icon')}>
               {props.icon}
            </div>
            <div className={cn('list-item__content')}>
                {props.info}
            </div>
            {props.comment && (
                <div className={cn('list-item__content-comment')}>
                    {props.comment}
                </div>
            )}
        </div>
    );
};

const ListItem: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    const partner_profile = props.person.find_partner_profile;

    const onClickRespond = () => {
        props.onClickRespond && props.onClickRespond(props.person.id);
    };

    const elButton = () => {
        if (partner_profile) {
            if (props.person.isRespond) {
                return (
                    <div className={cn('list-item__email')}>{partner_profile?.user?.email}</div>
                );
            } else {
                return (
                    <Button onClick={onClickRespond} isSecondary={true} isSmall={true}>
                        Откликнуться
                    </Button>
                );
            }
        }
    };

    return (
        <div className={cn('list-item')}>
            {props.isAvatarName && (
                <div className={cn('list-item__wrapper')}>
                    <Avatar imgSrc={props.person.photo_url} size={48} />
                    <div className={cn('list-item__content')}>
                        <a target="_blank" href={`/persons/${props.person.id}`} title="Перейти на страницу персоны">
                            {props.person.name}
                        </a>
                    </div>
                </div>
            )}
            {/* {partner_profile && (
                <AdInfo
                    icon={(
                        <div data-tip={'Ищу партнера'} className={cn('photo__person-list-item')} style={{color: '#fda536'}}>
                            <FontAwesomeIcon icon={faUserPlus} />
                        </div>
                    )}
                    info={(
                        <>
                            <div className={cn('list-item__content-info')}>г.{partner_profile?.location?.name}</div>
                            <div className={cn('list-item__content-info')}>
                                {partner_profile?.gender === 'MALE' ? 'муж.' : 'жен.'}, {partner_profile?.age} лет
                            </div>
                            {partner_profile?.dance_grade_standard && (
                                <div className={cn('list-item__content-info')}>
                                    класс стандарт: {partner_profile?.dance_grade_standard?.name}
                                </div>
                            )}
                            {partner_profile?.dance_grade_latin && (
                                <div className={cn('list-item__content-info')}>
                                    класс латина: {partner_profile?.dance_grade_latin?.name}
                                </div>
                            )}
                            <div className={cn('list-item__content-info')}>рост {partner_profile?.height} см</div>
                            {partner_profile.dance_site_link && (
                                <Link to={{pathname: partner_profile.dance_site_link}} target="_blank">
                                    <div className={cn('list-item__content-info')}>профиль на ФТСАРР</div>
                                </Link>
                            )}
                        </>
                    )}
                    comment={partner_profile?.comment}
                />
            )}
            {elButton()} */}
        </div>
    );
};

ListItem.defaultProps = {
    isAvatarName: true
};

export default ListItem;
