import EventAPI from 'src/api/events';
import PersonAPI from 'src/api/persons';
import PartnerAPI from 'src/api/find-partner';
import AuthAPI from 'src/api/auth';
import { EventAPIType } from './events/types';
import { PersonAPIType } from './persons/types';
import { FindPartnerAPIType } from './find-partner/types';
import { AuthAPIType } from './auth/types';

export type APIType = {
    event: EventAPIType;
    person: PersonAPIType;
    partner: FindPartnerAPIType;
    auth: AuthAPIType;
};

export default {
    event: EventAPI,
    person: PersonAPI,
    partner: PartnerAPI,
    auth: AuthAPI
} as APIType;
