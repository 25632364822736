import React, { useEffect, useMemo, useState, useCallback, FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';

import UI from 'component/ui';
import Error from 'component/error';
import Button from 'component/button';
import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';

import { subscribe, unsubscribe } from 'component/api/subscriptions';
import { Data as ISubscription } from 'component/api/types/api/subscription/subscription/create/post/code-200';

import { IProps } from './types';
import style from './index.pcss';

const Subscribe: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const history = useHistory();
    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);
    const [tokenUnsubscribe, tokenSubscribe] = useCancelTokens(2);

    const [pendingSubscribe, setPendingSubscribe] = useState<boolean>(false);
    const [subscription, setSubscrirption] = useState<ISubscription | null | undefined>(props.subscription);
    const [errorSubscribe, setErrorSubscribe] = useState<string | null>(null);

    useEffect(() => {
        if(props.subscription) {
            setSubscrirption(props.subscription);
        }
    }, [JSON.stringify(props.subscription)]);

    const _requestUnsubscribe = useCallback(() => {
        if(!isAuth) {
            history.push('/login');

            return;
        }

        setPendingSubscribe(true);

        if(errorSubscribe) {
            setErrorSubscribe(null);
        }

        unsubscribe({
            cancelToken: tokenUnsubscribe.new(),
            data       : {
                subscription_id: subscription?.id
            }
        })
            .then(() => {
                setSubscrirption(null);

                setPendingSubscribe(false);
            })
            .catch((err) => {
                if(err.message) {
                    setErrorSubscribe(err.message);
                }

                setPendingSubscribe(false);

                console.warn(err);
            });
    }, [JSON.stringify(props.persons), JSON.stringify(subscription), errorSubscribe]);

    const _requestSubscribe = useCallback(() => {
        if(!isAuth) {
            history.push('/login');

            return;
        }

        setPendingSubscribe(true);

        if(errorSubscribe) {
            setErrorSubscribe(null);
        }

        const data = {
            subscription_type : props.type,
            search_tmp_file_id: props.search_file_id,
            name             : `Подписка от ${moment().format('YYYY-MM-DD hh:ss')}`,
            ...(props.persons?.length && !props.photographerId && { search_person_ids: props.persons}),
            ...(props.photographerId && { search_photographer_id: props.photographerId }),
            ...(props.name && { search_person_name: props.name }),
            ...(props.location && { search_location_id: props.location }),
            ...(props.club && { search_club_id: props.club }),
            ...(props.event && { search_event_id: props.event }),
            ...(props.date_range?.date_from && { search_event_date_from: props.date_range.date_from }),
            ...(props.date_range?.date_to && { search_event_date_to: props.date_range.date_to })

        };

        subscribe({
            data,
            cancelToken: tokenSubscribe.new()
        })
            .then((resp) => {
                setSubscrirption(resp);
                setPendingSubscribe(false);

                if(props.onSetSubscription) {
                    props.onSetSubscription(resp);
                }
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    if(err.message) {
                        setErrorSubscribe(err.message);
                    }

                    setPendingSubscribe(false);

                    console.warn(err);
                }
            });
    }, [JSON.stringify(props.persons), props.photographerId]);

    useEffect(() => {
        return () => {
            tokenSubscribe.remove();
            tokenUnsubscribe.remove();
        };
    }, []);

    const elSubscriptionError = useMemo(() => {
        if(errorSubscribe) {
            return <Error className={cn('subscribe__subscription-error')}>{errorSubscribe}</Error>;
        }
    }, [errorSubscribe]);

    const elSubscription = () => {
        const buttonProps = {
            onClick    : subscription ? _requestUnsubscribe : _requestSubscribe,
            disabled   : pendingSubscribe || props.disabled,
            isLoading  : pendingSubscribe,
            children   : t('components.subscription.button', { context: subscription ? 'unsubscribe' : 'subscribe' }),
            isSecondary: !!subscription,
            className  : cn('subscribe__button')
        };

        return (
            <Fragment>
                <div>
                    <strong className={cn('subscribe__subscription-title')}>{props.title || t('components.subscription.title')}</strong>
                    <p className={cn('subscribe__subscription-desc')}>{props.text || t('components.subscription.text')}</p>
                    {elSubscriptionError}
                </div>
                <div className={cn('subscribe__button-wrapper')}>
                    <Button {...buttonProps} />
                </div>
            </Fragment>
        );
    };

    return (
        <UI.Box className={cn('subscribe')} padding={true}>
            {elSubscription()}
        </UI.Box>
    );
};

export default Subscribe;
