import React, { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import UI from 'component/ui';
import Loader from 'component/loader';
import Carousel from 'component/carousel';
import { DataPhotoListItem } from 'component/api/types/api/photo/get-photo-list/get/code-200';
import { useCancelToken } from 'component/core/cancel-token';
import { smartSearchPhotos } from 'component/api/photo';
import { DataEventsPhotosItem } from 'component/api/types/api/main/smart-search/events/get/code-200';
import { useClassnames } from 'hook/use-classnames';
import { parse, stringify } from 'query-string';
import { normalizeObject } from 'component/helper/normalize-object';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { IStore } from 'src/types/reducers';
import { IStore as IStoreCart } from 'route/cart/types/reducer';
import { key as cartKey } from 'route/cart/reducer';
import { addCart } from 'route/cart/actions';
import Button from 'component/button';
import Banner from 'component/banner';
import PersonList from 'component/person-list';
import { useRespondAd } from 'hook/use-respond-ad';

import style from './index.pcss';
import ReactTooltip from 'react-tooltip';
import { DataAlbumSalesItem } from 'component/api/types/api/payment/sale/get-photo-sales-by-photo-id/get/code-200';
import { getPhotoSaleByPhotoId } from 'component/api/payment';
import { key as keyUser } from 'component/user/reducer';
import { createPhotoCartItem, deleteCartItem } from 'component/api/cart';
import api from 'src/api';
import { PersonItem } from 'src/api/persons/types';
import Avatar from 'component/avatar';
import SearchPartnerModal from '../search-partner/search-partner-modal';
import { PersonPhotoItem } from './person-photo-item';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};
const bytesToMegaBytes = (bytes: number) => (bytes / (1024 * 1024)).toFixed(2);
const getColor = (photoColorId: number): string => {
    let styleName = '';
    const blue_id = 6;
    const red_id = 1;
    const white_id = 9;
    const orange_id = 2;
    const yellow_id = 3;
    const cyan_id = 5;
    const green_id = 4;
    const violet_id = 7;
    const black_id = 8;

    switch (photoColorId) {
        case blue_id: styleName = 'blue'; break;
        case red_id: styleName = 'red'; break;
        case white_id: styleName = 'white'; break;
        case orange_id: styleName = 'orange'; break;
        case yellow_id: styleName = 'yellow'; break;
        case cyan_id: styleName = 'cyan'; break;
        case green_id: styleName = 'green'; break;
        case violet_id: styleName = 'violet'; break;
        case black_id: styleName = 'black'; break;
    }

    return styleName;
};

const PERSONS_LIMIT = 30;

const PersonPhoto = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const token = useCancelToken();
    const history = useHistory();
    const { id }: { id: string } = useParams();
    const dispatch = useDispatch();
    const [onClickRespondCheck, elMessagesSubscription] = useRespondAd();

    const cart = useSelector<IStore, IStoreCart>((store) => store[cartKey]);
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const phoneNumber = useSelector<IStore, string | undefined>((store) => store[keyUser].fps_phone_number);
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);

    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    // Photo
    const [pending, setPending] = useState<boolean>(true);
    const [photoList, setPhotoList] = useState<Array<DataPhotoListItem>>();
    const [currentPhoto, setCurrentPhoto] = useState<DataPhotoListItem | null>(null);
    const [selected, setSelected] = useState<number>(0);

    // Persons
    const personToken = useCancelToken();
    const [pendingPersons, setPendingPersons] = useState<boolean>(false);
    const [personList, setPersonList] = useState<Array<PersonItem>>([]);
    // const [showSearchPartner, setShowSearchPartner] = useState<boolean>(false);
    const [currentPersonId, setCurrentPersonId] = useState<number>();

    // Sale
    const saleToken = useCancelToken();
    const [albumSales, setAlbumSales] = useState<Array<DataAlbumSalesItem> | null>(null);
    const [defaultSales, setDefaultSales] = useState<Array<DataAlbumSalesItem> | null>(null);

    // Cart
    const cartToken = useCancelToken();

    useEffect(() => {
        _requestPersons();
        _requestPhotoSales();
    }, [id]);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        _requestPhoto();

        return () => {
            token.remove();
            saleToken.remove();
        };
    }, []);

    useEffect(() => {
        if(id && photoList) {
            const newSelected = photoList.findIndex((item) => item.id === parseInt(id, 10));

            setSelected(newSelected || 0);
        }
    }, [id, JSON.stringify(photoList)]);

    useEffect(() => {
        return () => {
            cartToken.remove();
        };
    }, []);

    const _requestPhotoSales = () => {
        getPhotoSaleByPhotoId({
            cancelToken: saleToken.new(),
            params: {
                photo_id: id
            }
        }).then((resp) => {
            setAlbumSales(resp.album_sales ? resp.album_sales : null);
            setDefaultSales(resp.default_sales ? resp.default_sales : null);
        }).catch((err) => {
            if (!axios.isCancel(err)) {
                console.error(err);
            }
        });
    };

    const _requestPhoto = () => {
        setPending(true);

        smartSearchPhotos({
            params: {
                before: 24,
                after: 25,
                cursor: id,
                ...queryParams
            },
            cancelToken: token.new()
        }).then((resp) => {
            setPhotoList(resp.photo_list);
            setPending(false);
        }).catch((err) => {
            if (!axios.isCancel(err)) {
                setPending(false);
            }
        });
    };

    const _requestPersons = () => {
        api.person.getPersonsList(1, PERSONS_LIMIT, {
            photo_id: Number(id)
        })
            .then((resp) => {
                setPersonList(resp.data.results);
                setPendingPersons(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingPersons(false);
                }
            });
    };

    const _requestCreateCartItem = () => {
        if (currentPhoto) {
            createPhotoCartItem({
                data: {
                    vendor_code: currentPhoto.vendor_code
                },
                cancelToken: cartToken.new()
            })
                .then((resp) => {
                    dispatch(addCart(resp));
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                    }
                });
        }
    };

    const _requestDeleteCartItem = () => {
        if (currentPhoto) {
            deleteCartItem({
                data: {
                    vendor_code: currentPhoto.vendor_code
                },
                cancelToken: cartToken.new()
            })
                .then((resp) => {
                    dispatch(addCart(resp));
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                    }
                });
        }
    };

    // const onClickSearchPartner = () => {
    //     if (!isAuth) {
    //         history.push(`/login?from=/persons/photos/${id}`);

    //         return;
    //     }

    //     setShowSearchPartner(true);
    // };

    // Carousel
    const onSelectSlide = (slide: DataEventsPhotosItem): void => {
        const newCurrentPhoto = photoList?.find((item) => item.id === slide.id) || null;

        if (newCurrentPhoto) {
            history.replace({
                pathname: `/persons/photos/${newCurrentPhoto?.id}`,
                search: stringify(queryParams, {
                    arrayFormat: 'none'
                }),
                state: {
                    noScroll: true
                }
            });
        }

        setCurrentPhoto(newCurrentPhoto);
    };

    const elHeader = useMemo(() => {
        if (currentPhoto && currentPhoto.event) {
            return <UI.BoxHeader>{currentPhoto.event.name}</UI.BoxHeader>;
        }
    }, [JSON.stringify(currentPhoto)]);

    const elContent = useMemo((): ReactNode => {
        if (photoList?.length) {
            return (
                <UI.Box padding={true}>
                    {elHeader}
                    <Carousel
                        onSelectSlide={onSelectSlide}
                        isModal={false}
                        selected={selected}
                    >
                        {photoList}
                    </Carousel>
                </UI.Box>
            );
        }

        if (!pending && !photoList) {
            return <Redirect to="/404" />;
        }
    }, [pending, JSON.stringify(photoList), JSON.stringify(currentPhoto)]);

    // Sidebar
    const onClickAddToCart = () => {
        if (!isAuth) {
            history.push(`/login?from=${history.location.pathname}${history.location.search}`);
        }

        _requestCreateCartItem();
    };

    const onClickRemoveFromCart = (): void => {
        _requestDeleteCartItem();
    };

    const preparePhotoSales = (sales: Array<DataAlbumSalesItem>): Array<number> => {
        let sale1 = 0;
        let sale2 = 0;
        let sale3 = 0;

        sales.forEach((sale) => {
            switch (sale.photo_count) {
                case 'THREE_OR_MORE':
                    sale1 = sale.value;
                    break;
                case 'SIX_OR_MORE':
                    sale2 = sale.value;
                    break;
                case 'MORE_THAN_TEN':
                    sale3 = sale.value;
                    break;
            }
        });

        return [sale1, sale2, sale3];
    };

    const elSaleInfo = useMemo(() => {
        return (
            <div className={cn('person-photo__sale-recommendations')}>
                <span>
                    У фотографа <b>{currentPhoto?.photographer?.first_name} {currentPhoto?.photographer?.last_name}</b> действует скидка на фотографии.
                </span>
                {albumSales && (
                    <div className={cn('person-photo__sale-table')}>
                        <span>На фотографии из данного события:</span>
                        <div className={cn('person-photo__sale-table-header')}>
                            <div>3-5 фото</div>
                            <div>6-10 фото</div>
                            <div>более 10 фото</div>
                        </div>
                        <div className={cn('person-photo__sale-table-body')}>
                            {
                                preparePhotoSales(albumSales).map((sale, index) => {
                                    return (
                                        <div key={index}>{sale}%</div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )}
                {defaultSales && (
                    <div className={cn('person-photo__sale-table')}>
                        <span>Общая скидка*:</span>
                        <div className={cn('person-photo__sale-table-header')}>
                            <div>3-5</div>
                            <div>6-10</div>
                            <div>более 10</div>
                        </div>
                        <div className={cn('person-photo__sale-table-body')}>
                            {
                                preparePhotoSales(defaultSales).map((sale, index) => {
                                    return (
                                        <div key={index}>{sale}%</div>
                                    );
                                })
                            }
                        </div>
                        <span>* - данная скидка не суммируется с индивидуальной скидкой на событие.</span>
                    </div>
                )}
            </div>
        );
    }, [JSON.stringify(currentPhoto), albumSales, defaultSales]);

    const elSellBlock = useMemo((): ReactNode => {
        if (currentPhoto) {
            const alreadyInCart = Object.keys(cart).find((item) => item === String(currentPhoto.vendor_code));
            const mainButtonProps = {
                onClick  : alreadyInCart ? undefined : onClickAddToCart,
                to       : alreadyInCart ? '/cart' : undefined,
                className: cn('photo__button'),
                disabled : !currentPhoto,
                children : alreadyInCart ? t('route.photo.sidebar.already-in-cart') : t('route.photo.sidebar.add-to-cart')
            };

            if (currentPhoto.is_free) {
                return (
                    <UI.Box>
                        <div className={cn('person-photo__download')}>
                            {
                                isAuth && (
                                    <a
                                        href={currentPhoto.original_url}
                                        download={true}
                                        target="_blank"
                                    >
                                        <Button>Скачать фото</Button>
                                    </a>
                                )
                            }
                            {
                                !isAuth && (
                                    <Fragment>
                                        <ReactTooltip />
                                        <div data-tip={'Чтобы скачать фото, пожалуйста, авторизируйтесь.'}>
                                            <Button disabled={true}>
                                                Скачать фото
                                            </Button>
                                        </div>
                                    </Fragment>
                                )
                            }
                        </div>
                    </UI.Box>
                );
            }

            return (
                <UI.Box padding={true} className={cn('person-photo__sell')}>
                    <div className={cn('person-photo__sell-price')}>
                        <b>{currentPhoto?.price}₽</b>
                    </div>
                    <div className={cn('person-photo__sell-container')}>
                        <div className={cn('person-photo__sell-btn')}>
                            {!!alreadyInCart && (
                                <Button
                                    className={cn('photo__button', 'photo__button_remove')}
                                    isSecondary={true}
                                    onClick={onClickRemoveFromCart}
                                >
                                    {t('route.photo.sidebar.remove-from-cart')}
                                </Button>
                            )}
                            <Button {...mainButtonProps} />
                        </div>
                        {(albumSales || defaultSales) && (
                            <div className={cn('person-photo__sell-sale')}>
                                <ReactTooltip
                                    id="photo-sale"
                                    place="left"
                                    effect="solid"
                                    border={true}
                                    borderColor="#dee1e4"
                                    backgroundColor="#fff"
                                    className={cn('person-photo__sale-tooltip')}
                                >
                                    {elSaleInfo}
                                </ReactTooltip>
                                <span>
                                    <b>% СКИДКИ</b> <FontAwesomeIcon data-tip={true} data-type="light" data-for="photo-sale" icon={faQuestionCircle} />
                                </span>
                            </div>
                        )}
                    </div>
                </UI.Box>
            );
        }
    }, [JSON.stringify(currentPhoto), JSON.stringify(cart), albumSales, defaultSales]);

    const elPhotoColors = useMemo(() => {
        const colors = currentPhoto?.colors;
        const eventId = currentPhoto?.event
            ? currentPhoto.event.id
            : 0;

        if (Array.isArray(colors) && colors.length) {
            return (
                <div className={cn('person-photo__info-item')}>
                    <span>Цвета</span>
                    <div className={cn('person-photo__colors')}>
                        {
                            colors.map((item) => {
                                const color = getColor(item.id);
                                const link = `/events/${eventId}?color_ids=${item.id}`;

                                return (
                                    <a href={link} target="_blank" key={item.id}>
                                        <div
                                            className={
                                                cn('person-photo__colors-item', `person-photo__colors-item-${color}`)
                                            }
                                        />
                                    </a>
                                );
                            })
                        }
                    </div>
                </div>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    const elPhotoNumbers = useMemo(() => {
        const numbers = currentPhoto?.person_numbers;
        const eventId = currentPhoto?.event
            ? currentPhoto.event.id
            : 0;

        if (Array.isArray(numbers) && numbers.length) {
            return (
                <div className={cn('person-photo__info-item')}>
                    <span>Номера</span>
                    <div className={cn('person-photo__numbers')}>
                        {
                            numbers.map((item) => {
                                const link = `/events/${eventId}?number=${item}`;

                                return (
                                    <a href={link} target="_blank" key={item}>
                                        <div
                                            className={cn(
                                                'person-photo__numbers-item',
                                                'person-photo__info-item-link'
                                            )}
                                        >
                                            {item}
                                        </div>
                                    </a>
                                );
                            })
                        }
                    </div>
                </div>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    const elPhotoTime = useMemo(() => {
        const date = currentPhoto?.created_at;
        const eventId = currentPhoto?.event
            ? currentPhoto.event.id
            : 0;

        if (date) {
            const time = moment(date).format('HH:mm:ss');
            const [h, m, s] = time.split(':');
            const d = new Date();

            d.setHours(Number(h), Number(m) - 1, Number(s), 0);

            const timeFrom = moment(d).format('HH:mm:ss');

            d.setHours(Number(h), Number(m) + 1, Number(s), 0);

            const timeTo = moment(d).format('HH:mm:ss');

            return (
                <div className={cn('person-photo__info-item')}>
                    <span>Время</span>
                    <Link
                        to={`/events/${eventId}?time_from=${timeFrom}&time_to=${timeTo}`}
                        className={cn('person-photo__info-item-link')}
                    >
                        {time}
                    </Link>
                </div>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    const elPhotoInfo = useMemo(() => {
        if (currentPhoto) {
            const sale = currentPhoto.sales ? Math.max(...currentPhoto.sales) : null;

            return (
                <UI.Box padding={true} className={cn('person-photo__info')}>
                    <UI.BoxHeader>О снимке</UI.BoxHeader>
                    <div className={cn('person-photo__info-item')}>
                        <span>Разрешение</span>
                        <span>{`${currentPhoto.original_width}x${currentPhoto.original_height}`}</span>
                    </div>
                    {currentPhoto?.original_size && (
                        <div className={cn('person-photo__info-item')}>
                            <span>Размер</span>
                            <span>{`${bytesToMegaBytes(currentPhoto?.original_size)} Мб`}</span>
                        </div>
                    )}
                    {currentPhoto?.photographer?.id && (
                        <div className={cn('person-photo__info-item')}>
                            <span>Фотограф</span>
                            <Link
                                to={`/photographer/${currentPhoto.photographer.id}`}
                                className={cn('person-photo__info-item-link')}
                            >
                                {`${currentPhoto.photographer.first_name} ${currentPhoto.photographer.last_name}`}
                            </Link>
                        </div>
                    )}
                    {sale && (
                        <div className={cn('person-photo__info-item')}>
                            <span>Скидка</span>
                            <ReactTooltip />
                            <span
                                style={{color: 'rgba(255, 84, 135, 0.8)'}}
                                data-tip={`Скидка до ${sale}% при покупке от 3 фотографий у фотографа ${currentPhoto.photographer?.last_name} ${currentPhoto.photographer?.first_name}`}
                            >
                                до {sale}%
                            </span>
                        </div>
                    )}
                    {elPhotoTime}
                    {elPhotoColors}
                    {elPhotoNumbers}
                </UI.Box>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    const elBanner = useMemo(() => {
        if (currentPhoto) {
            return (
                <UI.Box padding={true}>
                    <UI.BoxHeader>{t('route.photo.sidebar.banner.title')}</UI.BoxHeader>
                    <Banner photoId={Number(currentPhoto.id)} />
                </UI.Box>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    // const elSearchPartner = useMemo(() => {
    //     if (showSearchPartner && userId) {
    //         return (
    //             <SearchPartnerModal
    //                 id={1}
    //                 onCloseModal={() => setShowSearchPartner(false)}
    //                 user={userId}
    //             />
    //         );
    //     }
    // }, [showSearchPartner]);

    const elAdBlock = useMemo(() => {
        if (personList.length) {
            return (
                <UI.Box padding={true}>
                    <PersonList>
                        {personList.map((item) => {
                                return (
                                    <PersonPhotoItem key={item.id} item={item} id={Number(id)} />
                                );
                        })}
                    </PersonList>
                </UI.Box>
            );
        }
    }, [JSON.stringify(personList)]);

    if (pending) {
        return  (
            <UI.Main className={cn('person-photo__loader')}>
                <Loader />
            </UI.Main>
        );
    }

    return (
        <UI.Main className={cn('person-photo')}>
            <UI.Content className={cn('person-photo__content')}>
                {/* {elSearchPartner} */}
                {elMessagesSubscription}
                {elContent}
            </UI.Content>
            <UI.Sidebar className={cn('person-photo__sidebar')}>
                {elSellBlock}
                {elPhotoInfo}
                {elAdBlock}
                {elBanner}
            </UI.Sidebar>
        </UI.Main>
    );
};

// tslint:disable-next-line:max-file-line-count
export default PersonPhoto;
