import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faInfoCircle, faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { useClassnames } from 'hook/use-classnames';

import { useAlert } from './provider';
import { IProps } from './types';
import style from './styles.pcss';

const Alert: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const alert = useAlert();
    const [type, setType] = useState<string>(alert.type || props.type || '');
    const [text, setText] = useState<string>(alert.text || props.text || '');

    const onClick = useCallback(() => {
        if (alert.hide) {
            alert.hide();
        }
    }, []);

    useEffect(() => {
        if (alert.type) {
            setType(alert.type);
        }
    }, [alert.type]);

    useEffect(() => {
        if (alert.text) {
            setText(alert.text);
        }
    }, [alert.text]);

    const elIcon = useMemo(() => {
        switch (type) {
            case 'default': return <FontAwesomeIcon icon={faInfoCircle} />;
            case 'success': return <FontAwesomeIcon icon={faCheckCircle} />;
            case 'warning': return <FontAwesomeIcon icon={faExclamationCircle} />;
            case 'danger': return <FontAwesomeIcon icon={faExclamationTriangle} />;
        }
    }, [type]);

    return (
        <div
            className={cn('alert', {
                'alert__success': type === 'success',
                'alert__warning': type === 'warning',
                'alert__danger': type === 'danger'
            })}
        >
            <div>
                <div
                    className={cn('alert__icon', {
                        'alert__icon_success': type === 'success',
                        'alert__icon_warning': type === 'warning',
                        'alert__icon_danger': type === 'danger'
                    })}
                >
                    {elIcon}
                </div>
                {alert.text || text}
                <button
                    className={cn('alert__button', {
                        'alert__button_success': type === 'success',
                        'alert__button_warning': type === 'warning',
                        'alert__button_danger': type === 'danger'
                    })}
                    onClick={onClick}
                >
                    <FontAwesomeIcon icon={faTimesCircle} />
                </button>
            </div>
        </div>
    );
};

Alert.defaultProps = {
    type: 'default',
    text: ''
};

export default Alert;
