import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useClassnames from 'hook/use-classnames';

import Button from 'component/button';
import Error from 'component/error';
import Form, { createRegistry } from 'component/form';
import { IError, IPayload } from 'component/form/types';
import FormInput from 'component/form/input';
import Checkbox from 'component/form/checkbox';
import { Data as IUser } from 'component/api/types/api/account/self-user-info/get/code-200';

import { IProps } from './types';
import style from './index.pcss';
import checkboxStyle from './checkboxStyle.pcss';

const Meeting: FC<IProps> = (props) => {
    const cn = useClassnames(style);
    const registry = useMemo(createRegistry, []);
    const { t, i18n } = useTranslation();
    const [validity, setValidity] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError> | null>(props.errors || null);
    const [error, setError] = useState<string | null>(props.error || null);

    useEffect(() => {
        setError(error);
    }, [error]);

    useEffect(() => {
        setErrors(errors);
    }, [JSON.stringify(errors)]);

    const onSubmit = useCallback((formData: IPayload) => {
        const roles: IUser['roles'] = [];

        // @ts-ignore
        ym(67967041, 'reachGoal', 'regafinish');

        if(formData.parent && formData.photograph) {
            roles.push('PARENT', 'PHOTOGRAPHER');
        } else if(formData.parent) {
            roles.push('PARENT');
        } else if(formData.photograph) {
            roles.push('PHOTOGRAPHER');
        }

        props.onSubmit && props.onSubmit(formData.last_name, formData.first_name, roles);
    }, []);

    const onChangeForm = useCallback(() => {
        setError(null);
        setErrors(null);
    }, []);

    const elError = useMemo(() => {
        if(error) {
            return (
                <Error className={cn('meeting__error')}>
                    {error}
                </Error>
            );
        }
    }, [error, i18n.language]);

    return (
        <div className={cn('meeting')}>
            <h2 className={cn('meeting__header')}>
                {t('route.registration.meeting.title')}
            </h2>
            <Form
                registry={registry.form}
                className={cn('meeting__form')}
                onSubmit={onSubmit}
                onChangeValidity={setValidity}
                errors={errors}
                onChange={onChangeForm}
            >
                <div className={cn('meeting__content')}>
                    <FormInput
                        className={cn('meeting__input')}
                        registry={registry.field}
                        pattern={/^[-a-zA-zа-яА-Я. `'-]+$/}
                        patternError={t('route.registration.meeting.input.first-name.pattern-error')}
                        direction="column"
                        name="first_name"
                        required={true}
                        tabIndex={1}
                    >
                        {t('route.registration.meeting.input.first-name.label')}
                    </FormInput>
                    <FormInput
                        className={cn('meeting__input')}
                        registry={registry.field}
                        pattern={/^[-a-zA-zа-яА-Я. `'-]+$/}
                        patternError={t('route.registration.meeting.input.last-name.pattern-error')}
                        direction="column"
                        name="last_name"
                        required={true}
                        tabIndex={2}
                    >
                        {t('route.registration.meeting.input.last-name.label')}
                    </FormInput>
                </div>
                {/*<span className={cn('meeting__interest')}>{t('route.registration.meeting.interest')}</span>*/}
                {/*<div className={cn('meeting__checkboxes')}>*/}
                {/*    <Checkbox*/}
                {/*        name="parent"*/}
                {/*        registry={registry.field}*/}
                {/*        children={t('route.registration.meeting.checkbox.parent')}*/}
                {/*        className={checkboxStyle}*/}
                {/*    />*/}
                {/*    <Checkbox*/}
                {/*        name="photograph"*/}
                {/*        registry={registry.field}*/}
                {/*        children={t('route.registration.meeting.checkbox.photograph')}*/}
                {/*        className={checkboxStyle}*/}
                {/*    />*/}
                {/*</div>*/}
                {elError}
                <div className={cn('meeting__button-wrapper')}>
                    <Button
                        type="submit"
                        className={cn('meeting__button-submit')}
                        isLoading={props.pending}
                        disabled={!validity || props.pending}
                    >
                        {t('route.registration.meeting.button.send')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default Meeting;
