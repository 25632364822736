import React from 'react';

import { defaultProps } from './base';

import { IProps } from './types';

const Share = (props: IProps) => (
    <svg {...defaultProps} {...props} viewBox="0 0 24 24">
        <path d="M0.815033 24.7542C0.913042 24.7943 1.01105 24.8345 1.14173 24.8345C1.43576 24.8345 1.72978 24.6338 1.89313 24.2725C3.95132 19.456 6.27086 15.4824 13.3928 15.7633V19.3757C13.3928 19.8975 13.6869 20.4193 14.0789 20.62C14.4383 20.8207 14.8303 20.7003 15.1243 20.339L23.8471 11.308C24.0758 11.1073 24.1738 10.7862 24.1738 10.425C24.1738 10.0638 24.0431 9.78279 23.8145 9.54196L15.0917 1.07288C14.7976 0.791915 14.3729 0.751777 13.9809 0.992604C13.6215 1.23343 13.3928 1.63481 13.3928 2.11646V5.769C1.82779 7.37452 0.782364 17.6899 0.357658 22.1452C0.292319 22.667 0.259649 23.1487 0.19431 23.4698C0.0963012 23.9916 0.357658 24.5535 0.815033 24.7542ZM13.7849 7.33438C14.2096 7.29424 14.6996 6.73231 14.6996 6.13025V2.75867L22.6384 10.5053L14.6996 18.7335V15.3619C14.6996 14.7599 14.2423 14.1979 13.7522 14.1578C6.85892 13.7966 3.88598 17.0879 1.76245 21.2221C2.28517 16.5661 3.82064 8.57865 13.7849 7.33438Z" />
    </svg>
);

export default Share;
