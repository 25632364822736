import React, { useEffect, useMemo, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useHistory } from 'react-router';

import useIntersect from 'hook/use-intersect';
import { useClassnames } from 'hook/use-classnames';
import { useCancelToken } from 'component/core/cancel-token';

import UI from 'component/ui';
import Loader from 'component/loader';
import Error from 'component/error';
import Button from 'component/button';

import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';
import { getSubscriptionList } from 'component/api/subscriptions';
import { DataSubscriptionsItem } from 'component/api/types/api/subscription/get-subscription-list/get/code-200';

import SubscriptionsItem from './subscription-item';
import style from './index.pcss';

const LIMIT = 15;

export default () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const token = useCancelToken();
    const history = useHistory();

    const [pending, setPending] = useState<boolean>(true);
    const [pendingBefore, setPendingBefore] = useState<boolean>(false);
    const [list, setList] = useState<Array<DataSubscriptionsItem>>([]);
    const [error, setError] = useState<string | null>(null);
    const [total, setTotal] = useState<number>(0);
    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);

    const _request = (merge?: boolean, isBefore?: boolean) => {
        if(error) {
            setError(null);
        }

        if(isBefore) {
            setPendingBefore(true);
        } else {
            setPending(true);
        }

        getSubscriptionList({
            params: {
                limit : LIMIT,
                offset: merge ? list.length : 0
            },
            cancelToken: token.new()
        })
            .then((resp) => {
                const newList = merge ? [...list, ...resp.subscriptions] : resp.subscriptions;

                setTotal(resp.total_count);
                setPending(false);
                setPendingBefore(false);
                setList(newList);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPending(false);
                    setPendingBefore(false);
                    setError(err.message);
                }
            });
    };

    useEffect(() => {
        _request();
    }, []);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const $bottomPreviousPosts = useIntersect((entry) => {
        if(entry.isIntersecting) {
            _request(true, true);
        }
    }, {
        rootMargin: '100px 0px'
    });

    const onClickButtonBefore = (e: MouseEvent): void => {
        e.preventDefault();

        _request(false, true);
    };

    const elButtonBeforePosts = () => {
        if(!pending && list.length) {
            if(list.length !== total) {
                return (
                    <Button
                        ref={$bottomPreviousPosts}
                        disabled={pendingBefore}
                        isLoading={pendingBefore}
                        isSecondary={true}
                        className={cn('subs__button-before')}
                        onClick={onClickButtonBefore}
                    >
                        {t('route.home.button.before')}
                    </Button>
                );
            }
        }
    };

    const elError = useMemo(() => {
        if(error) {
            return <Error>{error}</Error>;
        }
    }, [error]);

    const elContent = useMemo(() => {
        if(!list.length && !pending) {
            return <p className={cn('subs__content-empty')}>У вас еще нет активных подписок.</p>;
        }

        if(pending && !list.length) {
            return <Loader />;
        }

        if(list.length) {
            return list.map((item) => (
                <SubscriptionsItem
                    onRenewList={_request}
                    item={item}
                    key={item.id}
                />
            ));
        }
    }, [JSON.stringify(list), pending]);

    const elButton = () => {
        return (
            <div className={cn('subs__btn')}>
                <Button
                    onClick={() => history.push('/search')}
                >
                    Найти фотографии
                </Button>
            </div>
        );
    };

    if(!isAuth) {
        return <Redirect to="/login" />;
    }

    return (
        <UI.Main className={cn('subs')}>
            <UI.PageHeader text={'Мои подписки'} />
            <div className={cn('subs__content-block')}>
                <UI.Box padding={true} className={cn('subs__content')}>
                    {elContent}
                    {elError}
                    {elButtonBeforePosts()}
                </UI.Box>
            </div>
            {!list.length && elButton()}
        </UI.Main>
    );
};
