import React, { useState, useMemo } from 'react';
import style from './index.pcss';
import { IProps } from './types';

import { useClassnames } from 'hook/use-classnames';
import { useTranslation } from 'react-i18next';
import Avatar from 'component/avatar';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';
import { useHistory } from 'react-router';
import SearchPartnerModal from '../search-partner/search-partner-modal';

export const PersonPhotoItem = (props: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();

    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [showSearchPartner, setShowSearchPartner] = useState<boolean>(false);

    // const onClickSearchPartner = () => {
    //     if (!isAuth) {
    //         history.push(`/login?from=/persons/photos/${props.id}`);

    //         return;
    //     }

    //     setShowSearchPartner(true);
    // };

    // const elSearchPartner = useMemo(() => {
    //     if (showSearchPartner) {
    //         return (
    //             <SearchPartnerModal
    //                 id={props.item.id}
    //                 onCloseModal={() => setShowSearchPartner(false)}
    //             />
    //         );
    //     }
    // }, [showSearchPartner]);

    return (
        <div key={props.item.id} className={cn('list-item')}>
            {/* {elSearchPartner} */}
            <div className={cn('list-item__wrapper')}>
                <a target="_blank" href={`/persons/${props.item.id}`} title="Перейти на страницу персоны">
                    <Avatar imgSrc={props.item.photo} size={48} />
                </a>
                <div className={cn('list-item__content')}>
                    {props.item.full_name}
                    {/* <div className={cn('list-item__search-partner')}>
                        <button className={cn('list-item__search-partner-button')} onClick={onClickSearchPartner}>
                            Ищу партнера
                        </button>
                        <div className={cn('person-photo__search-partner-info')}>
                            <ReactTooltip
                                id="search-partner"
                                place="left"
                                effect="solid"
                                border={true}
                                borderColor="#dee1e4"
                                backgroundColor="#fff"
                                className={cn('person-photo__sale-tooltip')}
                            >
                                {'Если это ваша персона и вы хотите найти партнера по танцам, то кликните по ссылке и заполните простую анкету'}
                            </ReactTooltip>
                            <span>
                                <FontAwesomeIcon data-tip={true} data-type="light" data-for="search-partner" icon={faQuestionCircle} />
                            </span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
