import moment from 'moment';

import { DataPhotoListItem } from 'component/api/types/api/photo/get-own-photo-list/get/code-200';

export interface ISortedMap {
    [key: string]: Array<DataPhotoListItem>
}

export default (list: Array<DataPhotoListItem>) => {
    return list.reduce((accumulator: ISortedMap, current) => {
        const date = moment(current.upload_at).format('YYYY-MM-DD');

        accumulator[date] = [
            ...(accumulator[date] ? accumulator[date] : []),
            current
        ];

        return accumulator;
    }, {});
};
