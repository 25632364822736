import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import UI from 'component/ui';
import Loader from 'component/loader';
import Button from 'component/button';
import ErrorBlock from 'component/error';

import { getOrderInfo } from 'component/api/payment';
import { getPhotosArchive } from 'component/api/photo';
import { DataOrderItemsItem } from 'component/api/types/api/payment/order-info/get/code-200';

import style from './index.pcss';
import { clearCart } from 'route/cart/actions';

// @ts-ignore
import imgSubscription from 'route/home/img/background.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRubleSign } from '@fortawesome/free-solid-svg-icons';

const History = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [token, tokenDownloadAll] = useCancelTokens(2);

    const dispatch = useDispatch();

    const history = useHistory();
    const { id, status }: { id?: string, status?: 'success' | 'fail' } = useParams();

    const [error, setError] = useState<string | null>(null);
    const [pending, setPending] = useState<boolean>(false);
    const [pendingArchive, setPendingArchive] = useState<boolean>(false);
    const [archiveUrl, setArchiveUrl] = useState<string | null>(null);
    const [order, setOrder] = useState<Array<DataOrderItemsItem>>();
    const [paymentStatus, setPaymentStatus] = useState<string>('');

    const _request = useCallback(() => {
        setPending(true);

        getOrderInfo({
            params: {
                order_id: id
            },
            cancelToken: token.new()
        })
            .then((resp) => {
                const newOrderItems = resp.order_items.map((item) => ({
                    ...item,
                    url: ''
                }));

                setPaymentStatus(resp.payment_status);
                setOrder(newOrderItems);
                setPending(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);
                    setError(err.message || t('route.order-info.error'));
                    setPending(false);
                }
            });
    }, [id]);

    const _requestArchive = useCallback(() => {
        if(order?.length) {
            setPendingArchive(true);

            const photoList = Object.values(order) && Object.values(order).filter(({ photo }) => {
                return photo;
            });

            getPhotosArchive({
                params: {
                    photo_ids: photoList.map((orderItem) => orderItem.photo?.id)
                },
                cancelToken: tokenDownloadAll.new()
            })
                .then((resp) => {
                    setArchiveUrl(resp.url);
                    setPendingArchive(false);
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                        setPendingArchive(false);
                    }
                });
        }
    }, [JSON.stringify(order)]);

    useEffect(() => {
        _request();
    }, []);

    useEffect(() => {
        _request();
    }, [id]);

    useEffect(() => {
        if(order?.length) {
            _requestArchive();
        }
    }, [JSON.stringify(order)]);

    useEffect(() => {
        return () => {
            token.remove();
            tokenDownloadAll.remove();
        };
    }, []);

    useEffect(() => {
        if(status === 'success') {
            dispatch(clearCart());
            history.replace(`/history/${id}`);
        }
    }, [status]);

    const elBlockHeader = useMemo(() => {
        if(id) {
            return <h2 className={cn('order-info__block-header')}>{t('route.order-info.block-header', { id })}</h2>;
        }
    }, [id]);

    const elLoader = useMemo(() => {
        if(pending) {
            return <Loader className={cn('order-info__loader')} />;
        }
    }, [pending]);

    const elOrder = useMemo(() => {
        if(order?.length) {
            return (
                <div className={cn('order-info__photo-list')}>
                    {order.map((item) => {
                        if (item.item_type === 'FIND_PARTNER_MONTH') {
                            return (
                                <div className={cn('order-info__list-item')} key={item.id}>
                                    <img alt="" src={imgSubscription} className={cn('order-info__item-image')} />
                                    <div className={cn('order-info__item-content')}>
                                        <div className={cn('order-info__item-block')}>
                                            <p className={cn('order-info__item-text')}>{item.title}</p>
                                        </div>
                                        <span className={cn('order-info__item-price')}>{t('route.order-info.item.price', { count: item.price })}</span>
                                    </div>
                                </div>
                            );
                        }

                        if (item.photo) {
                            const author = t('route.order-info.item.author-empty');
                            const event = item.photo?.event?.name || t('route.order-info.item.event-empty');
                            const price = Math.ceil((item.price * (1 - (item.sale / 100))) * 100) / 100;

                            return (
                                <div className={cn('order-info__list-item')} key={item.id}>
                                    <img alt={String(item.photo?.id)} src={item.photo?.url} className={cn('order-info__item-image')} />
                                    <div className={cn('order-info__item-content')}>
                                        <div className={cn('order-info__item-block')}>
                                            <p className={cn('order-info__item-text')}>{event}</p>
                                            <p className={cn('order-info__item-text-sub')}>{t('route.order-info.item.author', { name: author })}</p>
                                            <a
                                                className={cn('order-info__item-text-sub', 'order-info__item-text-sub_remove')}
                                                href={item.photo?.url}
                                                download={true}
                                            >
                                                {t('route.order-info.item.download')}
                                            </a>
                                        </div>
                                        <span className={cn('order-info__item-price')}>{t('route.order-info.item.price', { count: price })}</span>
                                    </div>
                                </div>
                            );
                        }

                        if (item.photo_ad) {
                            const photo = item.photo_ad.photo;
                            const event = photo?.event?.name || t('route.order-info.item.event-empty');

                            return (
                                <div className={cn('order-info__list-item')} key={item.id}>
                                    <Link to={`/persons/photos/${photo?.id}`}>
                                        <img alt={String(photo?.id)} src={photo?.thumbnail_url} className={cn('order-info__item-image')} />
                                    </Link>
                                    <div className={cn('order-info__item-content')}>
                                        <div className={cn('order-info__item-block')}>
                                            <p className={cn('order-info__item-text')}>{event}</p>
                                            {/*<p className={cn('order-info__item-text-sub')}>{t('route.order-info.item.author', { name: author })}</p>*/}
                                            <p className={cn('order-info__item-text-sub')}>Реклама на {item.photo_ad.period} мес.</p>
                                        </div>
                                        <span className={cn('order-info__item-price')}>{t('route.order-info.item.price', { count: item.price })}</span>
                                    </div>
                                </div>
                            );
                        }

                        if (item.item_type === 'SUBSCRIPTION') {
                            return (
                                <div className={cn('order-info__list-item')} key={item.id}>
                                    <div className={cn('order-info__list-item__item-icon')}>
                                        <FontAwesomeIcon icon={faRubleSign} />
                                    </div>
                                    <div className={cn('order-info__item-content')}>
                                        <div className={cn('order-info__item-block')}>
                                            <p className={cn('order-info__item-text')}>Подписка на предоплаченный альбом</p>
                                        </div>
                                        <span className={cn('order-info__item-price')}>{t('route.order-info.item.price', { count: item.price })}</span>
                                    </div>
                                </div>
                            );
                        }
                    })}
                    {elError}
                </div>
            );
        }
    }, [JSON.stringify(order)]);

    const elStatus = useMemo(() => {
        let text = '';

        switch(paymentStatus) {
            case 'PAYED': {
                text = t('route.order-info.status.payed');
                break;
            }

            case 'CANCELLED': {
                text = t('route.order-info.status.cancelled');
                break;
            }

            case 'ERROR': {
                text = t('route.order-info.status.error');
                break;
            }

            case 'INITED': {
                text = t('route.order-info.status.inited');
                break;
            }

            case 'INVOICED': {
                text = t('route.order-info.status.invoiced');
                break;
            }

            case 'PLACED': {
                text = t('route.order-info.status.placed');
                break;
            }
        }

        return <span className={cn('order-info__status')}>{text}</span>;
    }, [paymentStatus]);

    const elContent = useMemo(() => {
        return (
            <div className={cn('order-info__content-box')}>
                <div className={cn('order-info__header-block')}>
                    {elBlockHeader}
                    {elStatus}
                </div>
                {elOrder}
            </div>
        );
    }, [JSON.stringify(order), paymentStatus]);

    const elError = useMemo(() => {
        if(error) {
            return <ErrorBlock className={cn('order-info__error')}>{error}</ErrorBlock>;
        }
    }, [error]);

    return (
        <UI.Main className={cn('order-info')}>
            <h1 className={cn('order-info__header')}>{t('route.order-info.header')}</h1>
            <div className={cn('order-info__grid')}>
                <UI.Box padding={true} className={cn('order-info__content')}>
                    {elContent}
                    {elError}
                </UI.Box>
                <UI.Box padding={true} className={cn('order-info__sidebar')}>
                    <Button
                        className={cn('order-info__button')}
                        disabled={pendingArchive || !archiveUrl || paymentStatus !== 'PAYED'}
                        isLoading={pendingArchive}
                        href={archiveUrl || undefined}
                        download={archiveUrl || undefined}
                    >
                        {t('route.order-info.download')}
                    </Button>
                    <Button
                        to="/history"
                        isSecondary={true}
                        className={cn('order-info__button', 'order-info__button_secondary')}
                    >
                        {t('route.order-info.history')}
                    </Button>
                </UI.Box>
            </div>
        </UI.Main>
    );
};

export default History;
