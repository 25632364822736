import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';

import { useClassnames } from 'hook/use-classnames';

import { IProps } from './types';
import style from './index.pcss';

const CarouselItem: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    return (
        <div
            className={cn('item', {
                'item-selected': props.isSelected
            })}
            onClick={props.onClick}
        >
            <ReactTooltip />
            <img data-tip={props.item.name} src={props.item.photo_url} />
        </div>
    );
};

CarouselItem.defaultProps = {
    isSelected: false
};

export default CarouselItem;
