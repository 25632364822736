import React, { FC, Fragment, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClassnames } from 'hook/use-classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import { useCancelToken } from 'component/core/cancel-token';
import { bulkEditPhoto } from 'component/api/photo';
import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';
import LazyLoad from 'component/lazy-load';
import Attachments from 'component/attachments';
import Carousel from 'component/carousel';
import cropImage from 'component/helper/crop-image';
import Modal from 'component/modal';
import Form, { useRegistry } from 'component/form';
import Checkbox from 'component/form/checkbox';
import InputForm from 'component/form/input';
import InputYears from 'component/form/input-years';
import InputEvents from 'component/form/input-events/index-tsx-async';
import { IValue } from 'component/form/input-years/types';
import Button from 'component/button';
import { useAlert } from 'component/alert/provider';

import placeholder from './img/placeholder.png';
import { IProps } from './types';
import style from './styles.pcss';

const Album: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const { show } = useAlert();
    const userPersonalPhotoId = useSelector<IStore, number | undefined>((store) => store[keyUser].personal_photo?.id);

    const [selectGallery, setSelectGallery] = useState<boolean>(false);

    // Modal
    const token = useCancelToken();
    const registry = useRegistry();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [year, setYear] = useState<number>(0);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const onClick = (e?: MouseEvent): void => {
        e?.preventDefault();

        setSelectGallery(true);
    };

    const onClickClose = (e?: MouseEvent): void => {
        e?.preventDefault();

        setSelectGallery(false);
    };

    const elPortal = (): ReactNode => {
        if (selectGallery && props.photos?.length) {
            return (
                <Carousel
                    onClose={onClickClose}
                    isModal={true}
                    disableButtons={true}
                >
                    {props.photos}
                </Carousel>
            );
        }
    };

    const elAttachments = useMemo(() => {
        if(Array.isArray(props.photos) && props.photos?.length) {
            const attachments = props.photos.map((item) => ({
                url: item.url
            }));

            const params = {[props?.isInvited ? 'invitedAlbumId' : 'albumId']: props?.id};
            const link = props?.isInvited ? `/invited-album/${props?.id}` : `/album/${props?.id}`;

            const attachmentsImage = userPersonalPhotoId === props.photos[0].id
                ? (
                    <img
                        src={cropImage(props.photos[0].url, '520x')}
                        alt=""
                        onClick={onClick}
                    />
                )
                : (
                    <Attachments
                        data-tip={props.description}
                        className={cn('album__attachments')}
                        attachmentsCount={props.photos_count || attachments.length}
                        limit={3}
                        link={link}
                        children={props.photos}
                        isLink={true}
                        tooltip={props.description}
                        {...params}
                    />
                );

            return (
                <Fragment>
                    <ReactTooltip />
                    <LazyLoad className={cn('album__attachments')}>
                        {attachmentsImage}
                    </LazyLoad>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <ReactTooltip />
                    <LazyLoad className={cn('album__attachments')}>
                        <img
                            data-tip={props.description}
                            src={placeholder}
                            alt={'placeholder'}
                        />
                    </LazyLoad>
                </ Fragment>
            );
        }
    }, [JSON.stringify(props.photos)]);

    const elDate = useMemo(() => {
        if(props.date) {
            return (
                <div className={cn('album__content-text')}>
                    <span>{t('route.dashboard.album.date')}</span>
                    <span>{moment(props.date).format('ll')}</span>
                </div>

            );
        }
    }, [props.date]);

    const elRecommendations = () => {
        return (
            <div className={cn('album__recommendations')}>
                <div className={cn('album__recommendations-items')}>
                    <div className={cn('album__recommendations-item')}>
                        <span className={cn('album__recommendations-text')}>
                            Основное фото - это фото, на котором изображены только Вы.
                        </span>
                    </div>
                    <div className={cn('album__recommendations-item')}>
                        <span className={cn('album__recommendations-text')}>
                            Выберите его из списка загруженных фото и нажмите соответствующую кнопку.
                        </span>
                    </div>
                    <div className={cn('album__recommendations-item')}>
                        <span className={cn('album__recommendations-text')}>
                            После этого у вас появится возможность подтвердить свою персону.
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const elLink = useMemo(() => {
        if (props.isPersonal) {
            return (
                <div className={cn('album__header')}>
                    {props.name}
                    <ReactTooltip
                        id="album-photo-recommendation"
                        place="right"
                        effect="solid"
                        clickable={true}
                        border={true}
                        borderColor="#dee1e4"
                        backgroundColor="#fff"
                        className={cn('album__recommendations-tooltip')}
                    >
                        {elRecommendations()}
                    </ReactTooltip>
                    <span
                        className={cn('album__question')}
                        data-type="light"
                        data-tip={true}
                        data-for="album-photo-recommendation"
                    >
                        ?
                    </span>
                </div>
            );
        }

        const link = (!props.isInvited) ? `/album/${props.id}` : `/invited-album/${props.id}`;

        return (
            <div className={cn('album__header')}>
                <Link to={link}>{props.name}</Link>
                <div className={cn('album__header-btn')}>
                    <FontAwesomeIcon icon={faEdit} onClick={() => setShowModal(true)} />
                </div>
            </div>
        );
    }, [props.name, props.id, props.isInvited, props.photos]);

    // Modal
    const onChangeYear = (value: IValue): void => {
        if (value) {
            setYear(Number(value?.value));
        } else {
            setYear(0);
        }
    };

    const onSubmit = useCallback((payload) => {
        const photo_data = {
            ...(payload.event?.value && {event_id: payload.event.value}),
            ...(payload.price && {price: payload.price}),
            ...(payload.remove && {price: 0})
        };

        bulkEditPhoto({
            cancelToken: token.new(),
            data       : {
                album_id: props.id,
                photo_data
            }
        })
            .then(() => {
                setShowModal(false);
                show('Ваши изменения успешно применены.');
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);
                    show('Не удалось применить Ваши изменения.', 'warning');
                }
            });
    }, []);

    const elModal = useMemo(() => {
        if (showModal) {
            const isDisabledEvents = !Boolean(year);

            return (
                <Modal onClickClose={() => setShowModal(false)} >
                    <Form registry={registry.form} onSubmit={onSubmit} className={cn('album__form')}>
                        <h2>{props.name}</h2>
                        <InputYears
                            registry={registry.field}
                            name="year"
                            clearable={true}
                            children={t('components.sidebar-action.forms.items.year')}
                            direction="column"
                            onChange={onChangeYear}
                        />
                        <InputEvents
                            registry={registry.field}
                            name="event"
                            required={true}
                            clearable={true}
                            excludeEmpty={false}
                            year={year}
                            children={t('components.sidebar-action.forms.items.event')}
                            direction="column"
                            disabled={isDisabledEvents}
                        />
                        <InputForm
                            registry={registry.field}
                            name="price"
                            children={t('components.sidebar-action.forms.items.cost')}
                            // className={inputStyle}
                            onlyNumbers={true}
                            direction="column"
                            // required={true}
                        />
                        <p className={cn('album__form-commission')}>
                            {t('components.sidebar-action.messages.commission-info')}
                        </p>
                        <Checkbox
                            // className={cn('filter-form__checkbox')}
                            registry={registry.field}
                            name="remove"
                            children={'Снять с продажи'}
                            // disabled={true}
                        />
                        <div className={cn('album__form-btn')}>
                            <Button type="submit">
                                Применить
                            </Button>
                        </div>
                    </Form>
                </Modal>
            );
        }
    }, [showModal, year]);

    return (
        <div className={cn('album')}>
            {elModal}
            {elPortal()}
            {elAttachments}
            <div className={cn('album__content')}>
                {elLink}
                {elDate}
            </div>
        </div>
    );
};

export default Album;
