import React, { PureComponent, ReactNode } from 'react';
import * as classnames from 'classnames/bind';
import qaAttributes from 'component/helper/qa-attributes';

import { IProps } from './types/box';
import style from './box.pcss';

class Box extends PureComponent<IProps> {

    cn = classnames.bind(style);

    render(): ReactNode {
        return (
            <div
                id={this.props.id}
                className={this.cn('box', {
                    'box_padding': this.props.padding
                }, this.props.className)}
                ref={this.props.refEl}
                {...qaAttributes(this.props['data-qa'] ? `ui-box:${this.props['data-qa']}` : 'ui-box')}
            >
                {this.props.children}
            </div>
        );
    }

}

export default Box;
