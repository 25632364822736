import React, { useMemo } from 'react';

import useClassnames from 'hook/use-classnames';
import Footer from 'component/footer';
import Header from 'component/header';
import qaAttributes from 'component/helper/qa-attributes';

import './img/favicon.ico';
import './img/favicon-16x16.png';
import './img/favicon-32x32.png';
import { IProps } from './types';
import style from './index.pcss';
import Alert from 'component/alert';
import { useAlert } from 'component/alert/provider';

export default (props: IProps) => {
    const cn = useMemo(() => useClassnames(style), []);
    const alert = useAlert();

    const elAlert = useMemo(() => {
        if (alert.visible) {
            return <Alert />;
        }
    }, [
        alert.visible
    ]);

    return (
        <div className={cn('layout')}>
            <div className={cn('layout__alert')}>
                {elAlert}
            </div>
            <Header />
            <div className={cn('layout__inner')} {...qaAttributes('content')}>
                {props.children}
            </div>
            <Footer />
        </div>
    );
};
