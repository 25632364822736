import React, { FC, useState, ReactNode, useEffect, useMemo } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import cancelToken from 'component/core/cancel-token';
import i18n from 'component/core/i18n';
import { useClassnames } from 'hook/use-classnames';

import UI from 'component/ui';
import Button from 'component/button';
import IconMailSent from 'component/icon/mail-sent';
import Form, { createRegistry } from 'component/form';
import { IPayload, IError } from 'component/form/types';
import { IStore } from 'src/types/reducers';
import FormInput from 'component/form/input';

import { resetPassword } from 'component/api/user';
import { key as keyUser } from 'component/user/reducer';

import style from './index.pcss';

const ResetPassword: FC = () => {
    const cn = useClassnames(style);
    const registry = useMemo(createRegistry, []);
    const token = useMemo(cancelToken.create, []);

    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [pending, setPending] = useState<boolean>(false);
    const [requestMail, setRequestMail] = useState<string | null>(null);
    const [validity, setValidity] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError> | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const onSubmit = (formData: IPayload) => {
        setPending(true);

        resetPassword({
            cancelToken: token.new(),
            data       : formData
        })
            .then(() => {
                setPending(false);
                setRequestMail(formData.email);
            })
            .catch((errorData) => {
                if(!axios.isCancel(errorData)) {
                    console.error(errorData);

                    setPending(false);
                    setErrors(errorData.errors);
                    setError(errorData.message);
                }
            });
    };

    const onChangeForm = () => {
        setError(null);
    };

    const elContent = (): ReactNode => {
        if(requestMail) {
            return (
                <div className={cn('reset-password__instructions')}>
                    <IconMailSent className={cn('reset-password__instructions-icon')} />
                    <h2 className={cn('reset-password__instructions-title')}>{i18n.t('route.reset-password.instructions.title')}</h2>
                    <p className={cn('reset-password__instructions-text')}>
                        <Trans
                            i18n={i18n}
                            i18nKey="route.reset-password.instructions.text"
                            values={{ email: requestMail }}
                        >
                            Futher instructions on how to recasdadsover your password have been sent to the email you provided us during sign up
                            <strong>({requestMail})</strong>.
                        </Trans>
                    </p>
                    {/* {i18n.t('route.reset-password.instructions')} */}
                </div>
            );
        }

        return (
            <div className={cn('reset-password__content')}>
                <h2 className={cn('reset-password__header')}>
                    {i18n.t('route.reset-password.header')}
                </h2>
                <p className={cn('reset-password__text')}>{i18n.t('route.reset-password.text')}</p>
                <Form
                    registry={registry.form}
                    className={cn('reset-password__form')}
                    onSubmit={onSubmit}
                    onChangeValidity={setValidity}
                    errors={errors}
                    onChange={onChangeForm}
                >
                    <FormInput
                        registry={registry.field}
                        name="email"
                        type="email"
                        className={cn('reset-password__input')}
                        required={true}
                        placeholder={i18n.t('route.reset-password.input.email.placeholder')}
                    />
                    <Button
                        type="submit"
                        className={cn('reset-password__button-submit')}
                        isLoading={pending}
                        disabled={!validity || pending}
                    >
                        {i18n.t('route.reset-password.button.send')}
                    </Button>
                </Form>
                <div className={cn('reset-password__links')}>
                    <Link
                        to="/login"
                        className={cn('reset-password__link')}
                    >
                        {i18n.t('route.reset-password.link.login')}
                    </Link>
                    <span className={cn('reset-password__span')}>{i18n.t('route.reset-password.or')}</span>
                    <Link
                        to="/registration"
                        className={cn('reset-password__link')}
                    >
                        {i18n.t('route.reset-password.link.join')}
                    </Link>
                </div>
            </div>
        );
    };

    if(isAuth) {
        return <Redirect to="/" />;
    }

    return (
        <UI.Main className={cn('reset-password__ui-main')}>
            <Helmet
                title={i18n.t('helmet.title.reset-password')}
                meta={[{
                    name   : 'document-state',
                    content: 'static'
                }]}
            />

            <UI.Box padding={true} className={cn('reset-password__ui-box')}>
                {elContent()}
            </UI.Box>
        </UI.Main>
    );
};

export default ResetPassword;
