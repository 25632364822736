import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import cancelToken from 'component/core/cancel-token';
import { useClassnames } from 'hook/use-classnames';
import { useSelector } from 'react-redux';
import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';
import UI from 'component/ui';
import Loader from 'component/loader';
import { confirmInviteAlbum } from 'component/api/album';
import IconError from 'component/icon/error';
import Button from 'component/button';

import { DataAlbum } from 'component/api/types/api/photo/album/confirm-invite/post/code-200';
import { ErrorsItem } from 'component/api/types/api/photo/album/confirm-invite/post/code-400';
import style from './index.pcss';

const Invite = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();
    const tokenInvite = useMemo(cancelToken.create, []);

    const { token }: { token: string } = useParams();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [pendingToken, setPendingToken] = useState<boolean>(false);
    const [status, setStatus] = useState<'ACCEPTED' | 'REQUESTED' | null>(null);
    const [errors, setErrors] = useState<Array<ErrorsItem> | null>(null);
    const [album, setAlbum] = useState<DataAlbum | null>(null);

    useEffect(() => {
        if (!isAuth) {
            history.push('/login?from=/invite');

            return;
        }

        _requestAccessInvite();

        return () => {
            tokenInvite.remove();
        };
    }, []);

    const _requestAccessInvite = () => {
        setErrors(null);
        setStatus(null);
        setPendingToken(true);

        confirmInviteAlbum({
            cancelToken: tokenInvite.new(),
            data: {
                invite_token: token
            }
        })
            .then((resp) => {
                setAlbum(resp.album || null);
                setStatus(resp.status || null);
                setPendingToken(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingToken(false);
                }
                setErrors(err.errors || 'Что-то пошло не так:(');
            });
    };

    const onClickButton = (e: MouseEvent): void => {
        e.preventDefault();
        history.push('/invited-albums');

        return;
    };

    const elContent = useMemo(() => {
        if(pendingToken) {
            return <Loader />;
        }

        if (status) {
            const message = status === 'ACCEPTED'
                ? t('route.invite.access-text', {name: album?.name})
                : t('route.invite.request-text', {name: album?.name});

            return (
                <UI.Box padding={true}>
                    <div>{message}</div>
                    <div className={cn('invite__wrapper-button')}>
                        <Button
                            className={cn('invite__button')}
                            onClick={onClickButton}
                        >
                            {t('route.invite.button-albums')}
                        </Button>
                    </div>
                </UI.Box>
            );
        }
    }, [pendingToken, status]);

    const elErrors = useMemo(() => {
        if (errors) {
            return errors.map((error, idx) => (
                <div key={idx} className={cn('invite__error')}>
                    <IconError className={cn('invite__error-icon')} />
                    {error.message}
                </div>
            ));
        }
    }, [errors]);

    return (
        <UI.Main className={cn('invite')}>
            <h1 className={cn('invite__header')}>{t('route.invite.header')}</h1>
            {elErrors}
            {elContent}
        </UI.Main>
    );
};

export default Invite;
