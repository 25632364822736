import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment';

import store from 'component/core/store';
import CoreProvide from 'component/core/provider';
import { AlertProvider } from 'component/alert/provider';
import { userSelfInfo } from 'component/api/user';
import 'component/core/i18n';
import Routes from 'route/index';
import { set as setUser } from 'component/user/actions';

moment.locale('ru');

const startApp = async () => {
    const requestAuth = userSelfInfo();

    try {
        const payload = await requestAuth;

        store.dispatch(setUser(payload));
    } catch(error) {
        console.warn(error);
    }

    render((
        <Provider store={store}>
            <CoreProvide>
                <AlertProvider>
                    <Routes />
                </AlertProvider>
            </CoreProvide>
        </Provider>
    ), document.getElementById('app'), () => {
        console.info('App version: %s', __VERSION__);
    });
};

if(module.hot) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    module.hot.accept('route/index', startApp);
}

if(!__DEVELOPMENT__) {
    startApp().catch(console.error);
} else {
    // Synthetic waiting loading css (style-loader chunk)
    window.onload = startApp;
}
