import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import {
    PersonItem,
    PersonSubscription,
    PersonAPIType,
    SearchInfo,
    UserProfileRetrieve,
    Person,
    PersonUpdateData
} from './types';
import { PaginationResponse } from '../base';

export const getPersonsList = (
    page?: number,
    page_size?: number,
    filter?: SearchInfo
): Promise<AxiosResponse<PaginationResponse<PersonItem>>> => {
    return httpClient.get<PaginationResponse<PersonItem>>('/api/v2/persons/', {
        params: {
            page,
            page_size,
            ...(filter?.person?.search && { search: filter.person.search }),
            ...(filter?.event_id && { event_id: filter.event_id }),
            ...(filter?.photo_id && { photo_id: filter.photo_id }),
            ...(filter?.person?.location_id && { location_id: filter.person.location_id }),
            ...(filter?.user_id && { user_id: filter.user_id })
        }
    });
};

export const getPerson = (
    id: number
): Promise<AxiosResponse<Person>> => {
    return httpClient.get<Person>(`/api/v2/persons/${id}/`);
};

export const getPersonSubscription = (
    id: number
): Promise<AxiosResponse<PersonSubscription>> => {
    return httpClient.get<PersonSubscription>(`/api/v2/persons/${id}/subscription/`);
};

export const getUserProfileRetrieve = (
    id: number
): Promise<AxiosResponse<UserProfileRetrieve>> => {
    return httpClient.get<UserProfileRetrieve>(`/api/v2/persons/${id}/user-profile/`);
};

const updatePerson = (id: number, payload: PersonUpdateData): Promise<AxiosResponse> => {
    return httpClient.patch<AxiosResponse>(`/api/v2/persons/${id}/`, {
        ...(payload.lastName && { last_name: payload.lastName}),
        ...(payload.firstName && { first_name: payload.firstName}),
        ...(payload.trainerName && { trainer_name: payload.trainerName}),
        ...(payload.location && { location: payload.location})
    });
};

export default {
    getPersonsList,
    getPerson,
    getPersonSubscription,
    getUserProfileRetrieve,
    updatePerson
} as PersonAPIType;
