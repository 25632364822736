import React, { Fragment, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import axios, { AxiosResponse } from 'axios';
import { parse, stringify } from 'query-string';
import { useSelector } from 'react-redux';
import moment from 'moment';

import useIntersect from 'hook/use-intersect';
import { useClassnames } from 'hook/use-classnames';
import IconCross from 'component/icon/cross';
import UI from 'component/ui';
import Loader from 'component/loader';
import { useCancelToken } from 'component/core/cancel-token';
import Button from 'component/button';
import Result from 'component/search-result';
import history from 'component/core/history';
import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';
import { getPersonList, getPersonListByTmplFile } from 'component/api/main';
import Form, { useRegistry } from 'component/form';
import Input from 'component/form/input';
import InputLocation from 'component/form/input-location';
import InputEvents from 'component/form/input-events/index-tsx-async';
import InputPhoto from 'component/form/input-photo';
import { normalizeObject } from 'component/helper/normalize-object';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { DataEventsPhotosItem } from 'component/api/types/api/main/smart-search/events/get/code-200';
import { DataPersonsItem } from 'component/api/types/api/main/get-person-list/get/code-200';
import {
    DataFilesItem,
    DataFilesTmpFacesItem,
    DataFilesTmpFacesPersonsItem
} from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { getFile } from 'component/api/tmp-file';
import { smartSearchPhotos } from 'component/api/photo';
import { DataPhotoListItem } from 'component/api/types/api/photo/smart-search/photos/get/code-200';
import { Link } from 'react-router-dom';
import { IValue } from 'component/form/input-years/types';
import InputYears from 'component/form/input-years';

import style from './index.pcss';
import api from 'src/api';
import { Event } from 'src/api/events/types';
import { PaginationResponse } from 'src/api/base';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

const getDate = (): { currentDate: string, prevDate: string } => {
    const date = new Date();
    const currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    date.setDate(date.getDate() - 7);
    const prevDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    return { currentDate, prevDate };
};

const Search = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { form, field } = useRegistry();
    const token = useCancelToken();
    const tokenFile = useCancelToken();
    const tokenPersons = useCancelToken();

    const $canvas = useRef<HTMLCanvasElement>(null);
    const $image = useRef<HTMLImageElement>(null);

    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);

    const [validity, setValidity] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [pendingPersons, setPendingPersons] = useState<boolean>(false);
    const [pendingFile, setPendingFile] = useState<boolean>(true);
    const [isQueryParamsEmpty, setIsQueryParamsEmpty] = useState<boolean>(false);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    const [persons, setPersons] = useState<Array<DataPersonsItem>>([]);
    const [personsList, setPersonsList] = useState<Array<number>>([]);
    const [requested, setRequested] = useState<boolean>(false);
    const [image, setImage] = useState<DataFilesItem | null>(null);
    const [list, setList] = useState<Array<Event>>([]);

    const [total, setTotal] = useState<number>(0);
    const [pendingBefore, setPendingBefore] = useState<boolean>(false);

    // Filter form
    const [year, setYear] = useState<number>(0);
    const [locationId, setLocationId] = useState<number>(0);
    const [isDisable, setIsDisable] = useState<boolean>(false);
    const [showFilterForm, setShowFilterForm] = useState<boolean>(true);

    // Last photos
    const photosToken = useCancelToken();
    const [totalPhotos, setTotalPhotos] = useState<number>(0);
    const [lastPhotos, setLastPhotos] = useState<Array<DataPhotoListItem>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isNextPage, setIsNextPage] = useState<boolean>(true);

    const EVENTS_LIMIT = 12;

    useEffect(() => {
        if (persons.length) {
            _requestPhotos();
        }

        return () => {
            photosToken.remove();
        };
    }, [persons]);

    useEffect(() => {
        return () => {
            token.remove();
        };
    }, []);

    const _requestPersons = (): void => {
        if (queryParams.person_name) {
            const params = {
                ...(queryParams.location_id && { location_id: queryParams.location_id }),
                ...(queryParams.person_name && { name: queryParams.person_name }),
                ...(queryParams.event_id && { event_id: queryParams.event_id }),
                ...(queryParams.person_ids && { person_ids: queryParams.person_ids })
            };

            getPersonList({ params })
                .then((resp) => {
                    setPersons(resp.persons);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    const _requestPhotos = (): void => {
        const person_ids = persons.map((person) => person.id);

        if (person_ids.length) {
            const { currentDate, prevDate } = getDate();

            smartSearchPhotos({
                params: {
                    person_ids,
                    date_from: prevDate,
                    date_to: currentDate,
                    limit   : 5,
                    offset  : 0
                },
                cancelToken: photosToken.new()
            }).then((resp) => {
                setLastPhotos(resp.photo_list);
                setTotalPhotos(resp.total_count);
            }).catch((err) => {
                console.warn(err);
            });
        }
    };

    const _requestFile = (tmp_file_id?: string): void => {
        if(tmp_file_id) {
            setPendingFile(true);

            getFile({
                params: {
                    tmp_file_id,
                    basket: 'SEARCH'
                },
                cancelToken: tokenFile.new()
            })
                .then((resp) => {
                    setImage(resp);
                    setPendingFile(false);
                })
                .catch((err) => {
                    console.warn(err);
                    setPendingFile(false);
                });
        }
    };

    const _request = (merge?: boolean, isBefore?: boolean): void => {
        if (!pendingBefore) {
            if(isBefore) {
                setPendingBefore(true);
            } else {
                setPending(true);
            }

            const payload = form.getPayload();
            let params = queryParams;

            setIsQueryParamsEmpty(isEmpty(params));

            if(isEmpty(params) && !payload.photo) {
                params = {
                    ...queryParams,
                    event_date_to: moment()
                };
            }

            if (isNextPage) {
                api.event.list(currentPage, EVENTS_LIMIT, 0, {
                        location_id: locationId,
                        year,
                        search: queryParams.person_name,
                        event_id: queryParams.event_id,
                        person_id: queryParams.person_ids
                })
                    .then((resp: AxiosResponse<PaginationResponse<Event>>) => {
                        const newList = merge ? [...list, ...resp.data.results] : resp.data.results;
                        setList(newList);
                        setTotal(resp.data.count);
                        setPending(false);
                        setPendingBefore(false);
                        setRequested(true);
                        if (resp.data.next) {
                            setCurrentPage((prev) => prev + 1);
                        } else {
                            setIsNextPage(false);
                        }
                    })
                    .catch((err: unknown) => {
                        setPending(false);
                        setPendingBefore(false);
                        setRequested(true);

                        console.warn(err);
                    });
            }
        }
    };

    const $bottomPreviousPosts = useIntersect((entry) => {
        if(entry.isIntersecting) {
            _request(true, true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const onChangeForm = debounce(useCallback(() => {
        setCurrentPage(1);
        const payload = form.getPayload();
        const faces = payload.photo?.tmp_faces;
        let personsNew: Array<DataFilesTmpFacesPersonsItem> = [];

        if(faces?.length) {
            personsNew = faces.reduce((acc: Array<DataFilesTmpFacesPersonsItem>, curr: DataFilesTmpFacesItem) => {
                if(curr.persons?.length) {
                    curr.persons.forEach((person) => {
                        const isExist = acc.find((item) => item.id === person.id);

                        if(person.id && !isExist) {
                            acc.push(person);
                        }
                    });
                }

                return acc;
            }, []);
        }

        if (personsNew.length) {
            setPersons(personsNew);
        }

        const data = {
            ...(personsNew.length && { person_ids: personsNew.map((item) => item.id) }),
            ...(payload.photo && { file_id: payload.photo.id }),
            ...(payload.name && { person_name: payload.name }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.club?.value && { club_id: payload.club.value }),
            ...(payload.club?.label && { club_name: payload.club.label }),
            ...(payload.event?.value && { event_id: payload.event.value }),
            ...(payload.year?.value && { event_date_from: `${payload.year.value}-01-01` }),
            ...(payload.year?.value && { event_date_to: `${payload.year.value}-12-31` })
            // ...(payload.age_range?.age_from && { age_from: payload.age_range.age_from }),
            // ...(payload.age_range?.age_to && { age_to: payload.age_range.age_to })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });

        if (payload.event && payload.event.value) {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    }, [validity, JSON.stringify(image), JSON.stringify(form.getPayload()), JSON.stringify, JSON.stringify(personsList)]), 300);

    const onFileLoaded = (file: DataFilesItem): void => {
        setCurrentPage(1);
        setImage(file);

        if (!file?.id) {
            return;
        }

        if (window.innerWidth <= 1023) {
            setShowFilterForm(false);
        }

        setPendingPersons(true);

        getPersonListByTmplFile({
            cancelToken: tokenPersons.new(),
            params     : {
                tmp_file_id: file?.id
            }
        })
            .then((resp) => {
                if (resp?.persons.length) {
                    const newList = resp?.persons.map((item) => item.id);
                    setPersonsList(newList || []);
                    const filePersons = {
                        ...file,
                        tmp_faces: [
                            {
                                box: [],
                                persons: resp?.persons || []
                            }
                        ]
                    };
                    setImage(filePersons);
                }

                setPendingPersons(false);
            })
            .catch((error) => {
                if(!axios.isCancel(error)) {
                    console.error((error));
                    setPendingPersons(false);
                }
            });
    };

    // const onClickPerson = useCallback((personId) => () => {
    //     if(chosenPersonId === personId) {
    //         setChosenPersonId(null);
    //
    //         return void(0);
    //     }
    //
    //     setChosenPersonId(personId);
    // }, [chosenPersonId]);

    const onClickRemove = useCallback((e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        form.clearForm();
        setImage(null);
    }, []);

    const elImageResult = () => {
        if(image?.url) {
            const ctx = $canvas.current?.getContext('2d');
            const rects = image.tmp_faces?.map((item) => item.box);

            // TODO тестовая версия
            rects?.forEach((rect) => {
                if(ctx && $image.current) {
                    const imageWidthRatio = $image.current.naturalWidth / $image.current.clientWidth;
                    const imageHeightRatio = $image.current.naturalHeight / $image.current.clientHeight;

                    ctx.beginPath();
                    ctx.strokeStyle = 'black';
                    ctx.moveTo(rect[0] / imageHeightRatio , rect[1] / imageHeightRatio);
                    ctx.lineTo(rect[2] / imageHeightRatio , rect[1] / imageHeightRatio); // top left to top right
                    ctx.lineTo(rect[2] / imageHeightRatio , rect[3] / imageHeightRatio); // top right to bottom right
                    ctx.lineTo(rect[0] / imageHeightRatio , rect[3] / imageHeightRatio); // bottom right to bottom left
                    ctx.lineTo(rect[0] / imageHeightRatio , rect[1] / imageHeightRatio); // bottom left to top left
                    ctx.stroke();
                    ctx.closePath();
                }
            });

            return (
                <div className={cn('search__image-box')}>
                    {/* <canvas ref={$canvas} className={cn('search__canvas')} /> */}
                    <img ref={$image} alt={image.url} className={cn('search__photo-image')} src={image.url} />
                </div>
            );
        }
    };

    const onReset = useCallback(() => {
        form.clearForm();
        window.scrollTo(0, 0);

        _request(false, false);
    }, []);

    const elUploadContent = useMemo(() => {
        return (
            <Fragment>
                {elImageResult()}
                <InputPhoto
                    defaultValue={image || undefined}
                    onFileLoaded={onFileLoaded}
                    registry={field}
                    name="photo"
                    skipRecognition={true}
                    hideBtn={true}
                />
                {image?.url && (
                    <div className={cn('search__photo-menu')}>
                        <div onClick={onClickRemove} className={cn('search__photo-menu-item')}>
                            <IconCross className={cn('search__photo-menu-icon')} />
                            Сбросить поиск
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }, [isAuth, JSON.stringify(image), $canvas.current, JSON.stringify(persons)]);

    const elPersons = useMemo(() => {
        if (pendingPersons) {
            return (
                <UI.Box className={cn('search__persons')} padding={true}>
                    <UI.BoxHeader>Поиск персон</UI.BoxHeader>
                    <div className={cn('search__persons-content')}>
                        <Loader />
                    </div>
                </UI.Box>
            );
        }

        if(persons?.length) {
            return (
                <Fragment>
                    <UI.Box className={cn('search__persons')} padding={true}>
                        <UI.BoxHeader>Найдены персоны</UI.BoxHeader>
                        <div className={cn('search__persons-content')}>
                            {
                                persons.map((person) => (
                                    <div key={person.id} className={cn('search__persons-item')}>
                                        <Link to={`/persons/${person.id}`}>
                                            <div
                                                // onClick={onClickPerson(person.id)}
                                                className={cn('search__persons-item_img')}
                                            >
                                                <img src={person.photo_url} alt="" />
                                            </div>
                                            <div className={cn('search__persons-item_name')}>
                                                {person.name}
                                            </div>
                                        </Link>
                                    </div>

                                ))
                            }
                        </div>
                    </UI.Box>
                    <UI.Box padding={true}>
                        <p><b>Подпишитесь на свою персону и получайте бесплатные уведомления о новых фотографиях. Для подписки перейдите на страницу персоны.</b></p>
                    </UI.Box>
                </Fragment>
            );
        }

    }, [JSON.stringify(form.getPayload()), JSON.stringify(persons), pendingPersons]);

    const elButtonBeforePosts = () => {
        if(!pending && list.length) {
            if(list.length !== total) {
                return <Loader ref={$bottomPreviousPosts} />;
            }
        }
    };

    const elLastPhoto = useMemo(() => {
        if (persons.length && lastPhotos.length) {
            const { currentDate, prevDate } = getDate();

            return (
                <Result
                    title={'Фото за последние 7 дней'}
                    link={'/persons/photos'}
                    attachments={lastPhotos.map((item): DataEventsPhotosItem => ({
                        url: item.url,
                        id: item.id,
                        price: item.price,
                        purchased_status: item.purchased_status,
                        upload_at: item.upload_at,
                        original_width: item.original_width,
                        original_height: item.original_height,
                        original_size: item.original_size,
                        event: item.event,
                        tags: item.tags,
                        updated_at: item.updated_at,
                        show_in_profile: item.show_in_profile,
                        vendor_code: item.vendor_code
                    }))}
                    photos_count={totalPhotos}
                    persons={persons.map((person) => person.id)}
                    query={`date_from=${prevDate}&date_to=${currentDate}`}
                />
            );
        }
    }, [persons, lastPhotos, totalPhotos]);

    const elContent = useMemo(() => {
        if(pending) {
            return <Loader />;
        }

        const allPersonsIds = persons?.map((person) => person.id);
        let persons_ids!: Array<number>;

        if(!pending && !pendingPersons && !pendingFile && !list.length) {
            return (
                <UI.Box padding={true}>
                    <UI.BoxHeader>Ничего не найдено</UI.BoxHeader>
                    <p>Попробуйте изменить условия поиска.</p>
                </UI.Box>
            );
        }

        if(allPersonsIds?.length) {
            persons_ids = allPersonsIds;
        }

        if(list?.length) {
            const query = getNormalizedQuery();

            return (
                <div className={cn('search__results')}>
                    {
                        list.map((event) => (
                            <Result
                                key={event.id}
                                link={`/events/${event.id}`}
                                photos_count={event.photo_count}
                                place={event.location_name}
                                persons={persons_ids}
                                file_id={query.file_id}
                                query={stringify(query)}
                                date={event.date}
                                title={event.name}
                                photo_attachments={event.photos}
                            />
                        ))
                    }
                    {elButtonBeforePosts()}
                </div>
            );
        }
    }, [list, pending, pendingPersons, pendingFile, requested, isQueryParamsEmpty]);

    // Filter form
    const onChangeYear = (value: IValue): void => {
        if (value) {
            setYear(Number(value?.value));
        } else {
            setYear(0);
        }
    };

    const onChangeLocation = (value: IValue): void => {
        if (value) {
            setLocationId(Number(value?.value));
        } else {
            setLocationId(0);
        }
    };

    const elLocation = useMemo(() => {
        let defaultLocation = null;

        if(queryParams.location_id && queryParams.location_name) {
            defaultLocation = {
                value: queryParams.location_id,
                label: queryParams.location_name
            };
        }

        return (
            <InputLocation
                registry={field}
                clearable={true}
                defaultValue={defaultLocation}
                name="location"
                children={t('global.form.items.theatre')}
                direction="column"
                className={cn('search__form-input')}
                disabled={isDisable}
                onChange={onChangeLocation}
            />
        );
    }, [isDisable, showFilterForm]);

    const elEventInput = useMemo(() => {
        let defaultYear = null;

        if (queryParams.event_date_from) {
            const data = queryParams.event_date_from.split('-');

            defaultYear = {
                value: data[0],
                label: data[0],
                payload: data[0]
            };
        }

        return (
            <Fragment>
                <InputYears
                    registry={field}
                    name="year"
                    clearable={true}
                    defaultValue={defaultYear}
                    children={t('components.sidebar-action.forms.items.year')}
                    className={cn('search__form-input')}
                    direction="column"
                    onChange={onChangeYear}
                    disabled={isDisable}
                />
                <InputEvents
                    registry={field}
                    name="event"
                    year={year}
                    location={locationId}
                    default_id={queryParams.event_id}
                    clearable={true}
                    children={t('global.form.items.event')}
                    direction="column"
                    className={cn('search__form-input')}
                />
            </Fragment>
        );
    }, [year, isDisable, locationId, showFilterForm]);

    const onChangeWindowSize = debounce(
        useCallback(() => {
            if (window.innerWidth > 1023) {
                setShowFilterForm(true);

                return true;
            }

            return false;
        }, []),
        500
    );

    const onClickFilterForm = useCallback(() => {
        if (!showFilterForm) {
            setShowFilterForm(true);

            return;
        }

        if (window.innerWidth <= 1023) {
            setShowFilterForm(false);
        }
    }, [showFilterForm]);

    useEffect(() => {
        window.addEventListener('resize', onChangeWindowSize);

        return () => {
            window.removeEventListener('resize', onChangeWindowSize);
        };
    }, []);

    const elFilterForm = useMemo(() => {
        return (
            <Form
                registry={form}
                onChange={onChangeForm}
                onChangeValidity={setValidity}
                className={cn('search__form')}
            >
                <UI.Box padding={true} className={cn('search__box')}>
                    <div onClick={onClickFilterForm}>
                        <UI.BoxHeader>{t('route.search.sidebar.filter-header')}</UI.BoxHeader>
                    </div>
                    {showFilterForm && (
                    <>
                        <Input
                            registry={field}
                            name="name"
                            defaultValue={queryParams.person_name}
                            type="text"
                            direction="column"
                            children={t('global.form.items.person')}
                            className={cn('search__form-input')}
                        />
                        {elLocation}
                        {elEventInput}
                        <div className={cn('search__form-btn')}>
                            <Button
                                onClick={onReset}
                                type="reset"
                                disabled={pending}
                            >
                                Сбросить
                            </Button>
                        </div>
                    </>
                    )}
                </UI.Box>
            </Form>
        );
    }, [year, isDisable, locationId, showFilterForm]);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        if(form.checkValidity()) {
            _request(false, false);
        }
    }, [JSON.stringify(queryParams)]);

    useEffect(() => {
        if(form.checkValidity()) {
            _requestPersons();
        }
    }, [JSON.stringify(queryParams)]);

    useEffect(() => {
        const query = getNormalizedQuery();

        if(query.file_id) {
            _requestFile(query.file_id);
        } else {
            setPendingFile(false);
        }
    }, []);

    useEffect(() => {
        const query = getNormalizedQuery();

        if (personsList.length) {
            setCurrentPage(1);
            setIsNextPage(true);
            _request(false);
        }
    }, [JSON.stringify(personsList), queryParams.person_ids]);

    if(pendingFile) {
        return (
            <UI.Main className={cn('search__loader')}>
                <Loader />
            </UI.Main>
        );
    }

    return (
        <UI.Main className={cn('search')}>
            <div className={cn('search__sidebar')}>
                <Form registry={form} onChange={onChangeForm} className={cn('search__form')}>
                    <UI.Box padding={true} className={cn('search__box')}>
                        <UI.BoxHeader>Поиск по фото</UI.BoxHeader>
                        {elUploadContent}
                    </UI.Box>
                </Form>
                {elFilterForm}
            </div>
            <div className={cn('search__content')}>
                {elPersons}
                {elLastPhoto}
                {elContent}
            </div>
        </UI.Main>
    );
};

// tslint:disable-next-line max-file-line-count
export default Search;
