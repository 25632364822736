import { createAction } from '@reduxjs/toolkit';

import { Data } from 'component/api/types/api/account/self-user-info/get/code-200';

export const set = createAction<Partial<Data>>(
    '@@user/SET'
);

export const reset = createAction(
    '@@user/RESET'
);

export default {
    set,
    reset
};
