import { createReducer } from '@reduxjs/toolkit';

import { addCart, clearCart, removeCartItem } from './actions';
import { IStore } from './types/reducer';

export const key = 'cart' as const;

const initialState = {};

export default createReducer<IStore>(initialState, (builder) => {
    builder
        .addCase(
            addCart,
            (
                store,
                action
            ) => {
                if (action.payload.count === 0) {
                    localStorage.removeItem('cart');

                    return {};
                }

                const cart: IStore = {};

                action.payload.items.map((item) => {
                    cart[item.vendor_code] = item;
                });

                localStorage.setItem('cart', JSON.stringify(cart));

                return cart;
            }
        )
        .addCase(
            removeCartItem,
            (
                store,
                action
            ) => {
                const cart: IStore = { ...store };

                // tslint:disable-next-line:no-dynamic-delete
                delete cart[action.payload];

                localStorage.setItem('cart', JSON.stringify(cart));

                return cart;
            }
        )
        .addCase(
            clearCart,
            () => {
                localStorage.removeItem('cart');

                return {};
            }
        );
});
