import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import axios from 'axios';
import { parse, stringify } from 'query-string';
import debounce from 'lodash.debounce';

import { useClassnames } from 'hook/use-classnames';
import { useCancelToken, useCancelTokens } from 'component/core/cancel-token';
import UI from 'component/ui';
import Subscribe from 'component/subscribe';
import SearchResult from 'component/search-result';
import Person from 'component/icon/person';
import history from 'component/core/history';
import Loader from 'component/loader';
import Input from 'component/form/input';
import InputEvents from 'component/form/input-events';
import InputClubs from 'component/form/input-clubs';
import InputLocation from 'component/form/input-location';
import InputInterval from 'component/form/input-interval';
import Button from 'component/button';
import Form, { useRegistry } from 'component/form';
import { getUserInfo } from 'component/api/account';
import { INormalizeObject } from 'component/helper/types/normalize-object';

import { Data as ISubscription } from 'component/api/types/api/subscription/subscription/create/post/code-200';
import { DataEventsPhotosItem } from 'component/api/types/api/main/smart-search/events/get/code-200';
import { Data as UserData } from 'component/api/types/api/account/user-info/get/code-200';

import { normalizeObject } from 'component/helper/normalize-object';

import style from './index.pcss';
import { DataPhotoListItem } from 'component/api/types/api/photo/smart-search/photos/get/code-200';
import { smartSearchPhotos } from 'component/api/photo';
import useIntersect from 'hook/use-intersect';
import api from 'src/api';
import { Event } from 'src/api/events/types';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

const getDate = (): { currentDate: string, prevDate: string } => {
    const date = new Date();
    const currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    date.setDate(date.getDate() - 7);
    const prevDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    return { currentDate, prevDate };
};

const Photographer = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [token, listToken] = useCancelTokens(2);
    const { form, field } = useRegistry();

    const { id }: { id: string } = useParams();

    const init = useRef(false);

    const [validity, setValidity] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [listPending, setListPending] = useState<boolean>(false);
    const [listPendingBefore, setListPendingBefore] = useState<boolean>(false);
    const [person, setPerson] = useState<UserData | null>(null);
    const [requested, setRequested] = useState<boolean>(false);
    const [list, setList] = useState<Array<Event>>([]);
    const [eventCount, setEventCount] = useState<number>(0);
    const [subscription, setSubscription] = useState<ISubscription | null>(null);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    // Last photos
    const photosToken = useCancelToken();
    const [totalPhotos, setTotalPhotos] = useState<number>(0);
    const [lastPhotos, setLastPhotos] = useState<Array<DataPhotoListItem>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isNextPage, setIsNextPage] = useState<boolean>(true);

    const _request = (): void => {
        setPending(true);

        getUserInfo({
            params: {
                user_id: id
            },
            cancelToken: token.new()
        })
            .then((resp) => {
                setPerson(resp);
                setPending(false);
                setRequested(true);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setPending(false);
                    setRequested(true);
                }
            });
    };

    const _requestPhotos = (): void => {
        const { currentDate, prevDate } = getDate();

        smartSearchPhotos({
            params: {
                photographer_id: id,
                date_from: prevDate,
                date_to: currentDate,
                limit   : 5,
                offset  : 0
            },
            cancelToken: photosToken.new()
        }).then((resp) => {
            setLastPhotos(resp.photo_list);
            setTotalPhotos(resp.total_count);
        }).catch((err) => {
            console.warn(err);
        });
    };

    const _requestEvents = (merge?: boolean, isBefore?: boolean): void => {
        if (!listPendingBefore) {
            if (isBefore) {
                setListPendingBefore(true);
            } else {
                setListPending(true);
            }

            if (isNextPage) {
                api.event.list(currentPage, 10, 0, {
                    photographer_id: Number(id)
                })
                    .then((resp) => {
                        setList(
                            merge
                                ? [...list, ...resp.data.results]
                                : resp.data.results
                        );
                        setEventCount(resp.data.count);
                        // setSubscription(resp.subscription || null);
                        setListPending(false);
                        setListPendingBefore(false);
                        if (resp.data.next !== null) {
                            setCurrentPage((prev) => prev + 1);
                        } else {
                            setIsNextPage(false);
                        }
                    })
                    .catch((err) => {
                        if(!axios.isCancel(err)) {
                            setListPending(false);
                            setListPendingBefore(false);
                        }
                    });
            }
        }
    };

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        _request();
        _requestEvents(false, false);
        _requestPhotos();

        return () => {
            token.remove();
            listToken.remove();
            photosToken.remove();
        };
    }, [id]);

    // useEffect(() => {
    //     if(form.checkValidity()) {
    //         _requestEvents(false, false);
    //     }
    // }, [JSON.stringify(queryParams)]);

    const onChangeForm = debounce(useCallback(() => {
        if(form.checkValidity()) {
            if(init.current) {
                _requestEvents();
            } else {
                init.current = true;
            }
        }

        const payload = form.getPayload();
        const data = {
            ...(payload.name && { person_name: payload.name }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.club?.value && { club_id: payload.club.value }),
            ...(payload.event?.value && { event_id: payload.event.value }),
            ...(payload.date_range?.date_from && { event_date_from: payload.date_range.date_from }),
            ...(payload.date_range?.date_to && { event_date_to: payload.date_range.date_to })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [validity, JSON.stringify(form.getPayload()), JSON.stringify(person)]), 300);

    const onReset = useCallback(() => {
        form.clearForm();
        window.scrollTo(0, 0);

        _requestEvents();
    }, []);

    const  elSubscription = useMemo(() => {
        if(requested && !pending && person?.id) {
            return <Subscribe type="PHOTOGRAPHER" subscription={subscription} photographerId={person?.id} persons={[person?.id]} className={cn('photographer__subscription')} />;
        }
    }, [JSON.stringify(person), requested, person, JSON.stringify(subscription)]);

    const elImageBlock = useMemo(() => {
        if(pending) {
            return <Loader />;
        }

        // if(person?.photo_url) {
        //     return (
        //         <div className={cn('photographer__image-block')}>
        //             <img alt={person.first_name} src={person.photo_url} className={cn('photographer__image')} />
        //         </div>
        //     );
        // }

        return (
            <div className={cn('photographer__image-empty')}>
                <Person className={cn('photographer__empty-icon')} />
            </div>
        );
    }, [JSON.stringify(person), pending]);

    const $LoadMoreEvent = useIntersect((entry) => {
        if(entry.isIntersecting) {
            _requestEvents(true, true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const elLoadMoreEvent = () => {
        if (!listPending && list.length) {
            if (eventCount > list.length) {
                return <Loader ref={$LoadMoreEvent} />;
            }
        }
    };

    const elList = useMemo(() => {
        if(listPending) {
            return (
                <UI.Box padding={true} className={cn('photographer__empty')}>
                    <Loader />
                </UI.Box>
            );
        }

        if(list?.length && !listPending) {
            return (
                <div className={cn('photographer__results')}>
                    {list.map((item, index) => (
                        <SearchResult
                            photo_attachments={item.photos}
                            key={index}
                            title={item.name}
                            // photos_count={item.photos_count}
                            // persons_count={item.persons_count}
                            place={item.location_name}
                            date={item.date}
                            link={`/events/${item.id}`}
                            query={`photographer_id=${person?.id}`}
                        />
                    ))}
                    {isNextPage && elLoadMoreEvent()}
                </div>
            );
        }

        return (
            <UI.Box padding={true} className={cn('photographer__empty')}>
                {t('route.photographer.results.empty')}
            </UI.Box>
        );
    }, [JSON.stringify(list), listPending, isNextPage]);

    const elPersonName = () => {
        const text = `${person?.first_name} ${person?.last_name}`.trim() || t('route.photographer.info.no-name');

        return <h4 className={cn('photographer__name')}>{text}</h4>;
    };

    const elLocation = () => {
        let defaultLocation = null;

        if(queryParams.location_id && queryParams.location_name) {
            defaultLocation = {
                value: queryParams.location_id,
                label: queryParams.location_name
            };
        }

        return (
            <InputLocation
                registry={field}
                clearable={true}
                defaultValue={defaultLocation}
                name="location"
                children="Театр"
                direction="column"
                className={cn('photographer__input-block')}
            />
        );
    };

    const elClub = () => {
        let defaultClub = null;

        if(queryParams.club_id && queryParams.club_name) {
            defaultClub = {
                value: queryParams.club_id,
                label: queryParams.club_name
            };
        }

        return (
            <InputClubs
                registry={field}
                name="club"
                defaultValue={defaultClub}
                clearable={true}
                children={t('global.form.items.club')}
                direction="column"
                className={cn('photographer__input-block')}
            />
        );
    };

    const elLastPhoto = useMemo(() => {
        if (lastPhotos.length) {
            const { currentDate, prevDate } = getDate();

            return (
                <SearchResult
                    title={'Фото за последние 7 дней'}
                    link={'/persons/photos'}
                    attachments={lastPhotos.map((item): DataEventsPhotosItem => ({
                        url: item.url,
                        id: item.id,
                        price: item.price,
                        purchased_status: item.purchased_status,
                        upload_at: item.upload_at,
                        original_width: item.original_width,
                        original_height: item.original_height,
                        original_size: item.original_size,
                        event: item.event,
                        tags: item.tags,
                        updated_at: item.updated_at,
                        show_in_profile: item.show_in_profile,
                        vendor_code: item.vendor_code
                    }))}
                    photos_count={totalPhotos}
                    query={`date_from=${prevDate}&date_to=${currentDate}&photographer_id=${id}`}
                />
            );
        }
    }, [lastPhotos, totalPhotos]);

    return (
        <UI.Main className={cn('photographer')}>
            <div className={cn('photographer__grid')}>
                <div className={cn('photographer__sidebar')}>
                    <UI.Box padding={true} className={cn('photographer__box')}>
                        {elImageBlock}
                        <div className={cn('photographer__data')}>
                            <div className={cn('photographer__name-age')}>
                                {elPersonName()}
                                <span className={cn('photographer__age')}>{t('route.photographer.info.role')}</span>
                            </div>
                        </div>
                    </UI.Box>
                    <Form
                        registry={form}
                        onChangeValidity={setValidity}
                        onChange={onChangeForm}
                    >
                        <UI.Box padding={true} className={cn('photographer__box')}>
                            <UI.BoxHeader>{t('route.photographer.sidebar.header')}</UI.BoxHeader>
                            <Input
                                registry={field}
                                name="name"
                                defaultValue={queryParams.person_name}
                                type="text"
                                direction="column"
                                children={t('global.form.items.person')}
                                className={cn('photographer__input-block')}
                            />
                            <InputEvents
                                registry={field}
                                name="event"
                                default_id={queryParams.event_id}
                                clearable={true}
                                children={t('global.form.items.event')}
                                direction="column"
                                className={cn('photographer__input-block')}
                            />
                            {elClub()}
                            {elLocation()}
                            <InputInterval
                                registry={field}
                                name="date_range"
                                children={t('global.form.items.interval')}
                                direction="column"
                                className={cn('photographer__input-block')}
                            />
                            <Button
                                onClick={onReset}
                                type="reset"
                                className={cn('photographer__button-submit')}
                                disabled={pending}
                            >
                                {t('route.photographer.button-reset')}
                            </Button>
                        </UI.Box>
                    </Form>
                </div>
                <div className={cn('photographer__content')}>
                    {elLastPhoto}
                    {elSubscription}
                    {elList}
                </div>
            </div>
        </UI.Main>
    );
};

export default Photographer;
