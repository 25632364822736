import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { parse } from 'query-string';
import ReCAPTCHA from 'react-google-recaptcha';

import { useAlert } from 'component/alert/provider';
import { set, reset } from 'component/user/actions';

import { useClassnames } from 'hook/use-classnames';
import cancelToken from 'component/core/cancel-token';

import UI from 'component/ui';
import Button from 'component/button';
import MailSent from 'component/mail-sent';
import Error from 'component/error';

import Form, { useRegistry } from 'component/form';
import { IPayload, IError } from 'component/form/types';
import FormInput from 'component/form/input';

import { IStore } from 'src/types/reducers';
// import { key as keyUser } from 'component/user/reducer';
import { key as keyDeviceInfo } from 'component/device-info/reducer';
import { key as keyUser } from 'component/user/reducer';
import { registration, yandexAuth, userSelfInfo } from 'component/api/user';
import { ErrorCode } from 'component/api/types/api/account/registration/post/code-400';

import style from './style.pcss';
import config from 'config';

import useScript from 'hook/use-script';

// @ts-ignore
import policy from './docs/policy.pdf';
import api from 'src/api';

const Registration = () => {
    const cn = useClassnames(style);
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { show } = useAlert();
    const registry = useRegistry();
    const qs = useMemo(() => parse(history.location.search), [history.location.search]);
    const tokenRegistration = useMemo(cancelToken.create, []);
    const cancelTokenLogin = useMemo(cancelToken.create, []);
    const cancelTokenUserSelfInfo = useMemo(cancelToken.create, []);

    const defaultEmail = useMemo<string | null>(() => (typeof qs.email === 'string' ? qs.email : null), [qs.email]);

    const isMobile = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].mobile);
    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);

    const [siteKey, setSiteKey] = useState<string>('');
    const [isNotBot, setIsNotBot] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [mailSent, setMailSent] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<ErrorCode | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [errors, setErrors] = useState<Array<IError> | null>(null);

    // const SITE_KEY: string = process.env.SITE_KEY ? process.env.SITE_KEY : '';

    useEffect(() => {
        if (process.env.SITE_KEY) {
            setSiteKey(process.env.SITE_KEY);
        }
    }, []);

    useEffect(() => {
        return () => {
            tokenRegistration.remove();
        };
    }, []);

    useEffect(() => {
        const promise = useScript('https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js');

        promise.then(
        () => {
            authYa();
        },
        // tslint:disable-next-line: no-shadowed-variable
        (error: { message: string; }) => {
            alert(`Ошибка: ${error.message}`);
        }
        );
    }, []);

    const getUserInfo = async (access_token: string) => {
        if(!pending) {
            setPending(true);

            api.auth.authYandexCreate(access_token)
                .then((resp) => {
                    localStorage.setItem('jwt_token', resp.data.access_token);
                    localStorage.setItem('jwt_token_refresh', resp.data.refresh_token);
                })
                .then(() => {
                    return userSelfInfo({
                        cancelToken: cancelTokenUserSelfInfo.new()
                    });
                })
                .then((payload) => {
                    dispatch(set(payload));
                })
                .catch((payload) => {
                    if(!axios.isCancel(payload)) {
                        setError(payload.message);
                        setErrorCode(payload.error_code);
                        setPending(false);

                        show(payload.message, 'danger');

                        if(payload.errors) {
                            setErrors(payload.errors);
                        }

                        dispatch(reset());
                    }
                });
    }
    };

    const authYa = useCallback(() => {
        // @ts-ignore
        YaAuthSuggest.init({
            client_id: process.env.YANDEX_API_CLIENT_ID,
            response_type: 'token',
            redirect_uri: `${location.origin}/auth-ya/suggest/token`
            },
            `${location.origin}`, {
                view: 'button',
                parentId: 'auth-container',
                buttonView: 'main',
                buttonTheme: 'light',
                buttonSize: 'm',
                buttonBorderRadius: 4
             }
         )
         .then((result: {handler: Function}) => {
            return result.handler();
         })
         .then((data: {'access_token': string; 'token_type': string; 'expires_in': string; 'extra_data': {}}) => {
            getUserInfo(data.access_token);
         })
         // tslint:disable-next-line: no-shadowed-variable
         .catch((error: unknown) => {
            alert(`Что-то пошло не так: ${JSON.stringify(error)}`);
         });
    }, [pending]);

    const onChangeValidState = useCallback((payload: boolean) => {
        if(payload !== isFormValid) {
            setIsFormValid(payload);
        }
    }, [isFormValid]);

    const onSubmit = useCallback((formData: IPayload) => {
        if(isFormValid) {
            setPending(true);

            if(errorCode) {
                setErrorCode(null);
            }

            if(error) {
                setError(null);
            }

            if(errors) {
                setErrors(null);
            }

            registration({
                cancelToken: tokenRegistration.new(),
                data       : formData
            })
                .then(() => {
                    // @ts-ignore
                    ym(67967041, 'reachGoal', 'rega');
                    setMailSent(true);
                })
                .catch((payload) => {
                    if(!axios.isCancel(payload)) {
                        setPending(false);
                        setError(payload.message);
                        setErrorCode(payload.error_code);
                        setErrors(payload.errors);
                    }
                });
        }
    }, [isFormValid, error, errors, errorCode]);

    const onChange = useCallback(() => {
        setIsNotBot(true);
    }, []);

    const elError = useMemo(() => {
        if(error) {
            return (
                <Error className={cn('registration__error')}>
                    {error}
                </Error>
            );
        }
    }, [error, registry.form.getPayload().email, errorCode, i18n.language]);

    const elContent = () => {
        if(mailSent) {
            return <MailSent resendMethod="email" email={registry.form.getPayload().email} />;
        }

        return (
            <div className={cn('registration__content')}>
                <h2 className={cn('registration__header')}>
                    {t('route.registration.header')}
                    <Link
                        title={t('route.registration.link.login')}
                        to="/login"
                        className={cn('registration__link-login')}
                    >
                        {t('route.registration.link.login')}
                    </Link>
                </h2>
                <Form
                    registry={registry.form}
                    className={cn('registration__form')}
                    onSubmit={onSubmit}
                    onChangeValidity={onChangeValidState}
                    errors={errors}
                >
                    <FormInput
                        className={cn('registration__input')}
                        registry={registry.field}
                        direction="column"
                        name="email"
                        type="email"
                        required={true}
                        tabIndex={1}
                        defaultValue={defaultEmail}
                    >
                        {t('route.registration.input.email.label')}
                    </FormInput>
                    <FormInput
                        className={cn('registration__input')}
                        registry={registry.field}
                        direction="column"
                        name="phone_number"
                        required={false}
                        tabIndex={1}
                        placeholder="(XXX) XXX-XX-XX"
                        defaultValue={defaultEmail}
                    >
                        {'Телефон (необязательно)*'}
                    </FormInput>
                    <span style={{display: 'block', color: '#ff5487', fontSize: '14px', marginTop: '10px'}}>* Гарантированный способ подтверждения регистрации</span>
                    <div className={cn('registration__accept-policy')}>
                        <Trans i18n={i18n} i18nKey="route.registration.accept-policy">
                            By signing up you're agreeing to our
                            <Link
                                className={cn('registration__link-label')}
                                to="/terms-and-conditions"
                            >
                                Terms of Service
                            </Link>
                            and
                            <a
                                href={policy}
                                target="_blank"
                                className={cn('registration__link-label')}
                            >
                                Privacy Policy
                            </a>
                            .
                        </Trans>
                    </div>
                    {elError}
                    <div className={cn('registration__recaptcha')}>
                    <ReCAPTCHA
                        sitekey={siteKey}
                        onChange={onChange}
                    />
                    </div>
                    <Button
                        type="submit"
                        className={cn('registration__button-submit')}
                        isLoading={pending}
                        disabled={!isFormValid || pending || !isNotBot}
                    >
                        {t('route.registration.button.sign-up')}
                    </Button>
                </Form>
                <div id="auth-container" className={cn('registration__auth-container')} />
            </div>
        );
    };

    if(isAuth) {
        return <Redirect to="/subscriptions" />;
    }

    return (
        <UI.Main className={cn('registration__ui-main')}>
            <Helmet
                title={t('helmet.title.registration')}
                meta={[{
                    name   : 'document-state',
                    content: 'static'
                }]}
            />

            <UI.Box padding={true} className={cn('registration__ui-box')}>
                {elContent()}
            </UI.Box>
        </UI.Main>
    );
};

export default Registration;
