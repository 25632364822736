import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { Redirect } from 'react-router-dom';

import cancelToken from 'component/core/cancel-token';
import { useClassnames } from 'hook/use-classnames';
import { useSelector } from 'react-redux';
import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';
import UI from 'component/ui';
import Loader from 'component/loader';
import { getAvailableAlbumWithPhotosList } from 'component/api/album';
import { getPhotoList } from 'component/api/photo';
import Button from 'component/button';
import IconError from 'component/icon/error';
import { useRegistry } from 'component/form';
import sortPhotoArray, { ISortedMap } from 'component/helper/sort-photos-list';
import useIntersect from 'hook/use-intersect';
import SearchForm from 'component/form/search-form';
import PhotoList from 'component/photo-list';

import { IValue } from 'component/form/input-clubs/types';
import { DataAlbumsItem as DataAlbums } from 'component/api/types/api/photo/album/get-available-album-with-photos-list/get/code-200';
import { DataPhotoListItem } from 'component/api/types/api/photo/get-photo-list/get/code-200';
import style from './index.pcss';

const PHOTOS_LIMIT = 15;

const InvitedAlbum = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();
    const tokenAlbums = useMemo(cancelToken.create, []);
    const tokenPhotoList = useMemo(cancelToken.create, []);

    const { id }: { id?: string } = useParams();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [error, setError] = useState<string | null>(null);

    const { field, form } = useRegistry();
    const [validity, setValidity] = useState<boolean>(false);
    const [album, setAlbum] = useState<DataAlbums | null>(null);
    const [pendingAlbum, setPendingAlbum] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [pendingBefore, setPendingBefore] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [list, setList] = useState<Array<DataPhotoListItem>>([]);
    const [photosMap, setPhotosMap] = useState<ISortedMap | null>(null);

    if (!isAuth) {
        return <Redirect to="/login" />;
    }

    useEffect(() => {
        id ? _requestAlbum(Number(id)) : history.push('/404');

        return () => {
            tokenAlbums.remove();
            tokenPhotoList.remove();
        };
    }, []);

    const _requestAlbum = (album_id: number) => {
        setError(null);
        setPendingAlbum(true);

        getAvailableAlbumWithPhotosList({
            params: {
                album_ids: [album_id]
            },
            cancelToken: tokenAlbums.new()
        })
            .then((resp) => {
                if (resp.albums.length) {
                    setAlbum(resp.albums[0]);
                    _requestPhotoList();
                }
                setPendingAlbum(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingAlbum(false);
                }
            });
    };

    const _requestPhotoList = (merge?: boolean, isBefore?: boolean) => {
        if(!pending && !pendingBefore) {
            if(isBefore) {
                setPendingBefore(true);
            } else {
                setPending(true);
            }

            const formData = form.getPayload();

            getPhotoList({
                cancelToken: tokenPhotoList.new(),
                params     : {
                    limit : PHOTOS_LIMIT,
                    offset: merge ? list?.length : 0,
                    album_ids: [id],
                    ...(formData.name && { person_name: formData.name }),
                    ...(formData.location?.value && { location_id: formData.location?.value }),
                    ...(formData.event?.value && { event_id: formData.event.value }),
                    ...(formData.price_range?.age_from && { price_from: formData.price_range?.age_from }),
                    ...(formData.price_range?.age_to && { price_to: formData.price_range?.age_to }),
                    ...(formData.date_range?.date_from && { date_from: formData.date_range?.date_from }),
                    ...(formData.date_range?.date_to && { date_end: formData.date_range?.date_to }),
                    ...(formData.clubs?.length && { club_ids: formData.clubs.map((club: IValue) => club.value) })
                }
            })
                .then((payload) => {
                    const newList = merge ? [...list, ...payload.photo_list] : payload.photo_list;

                    setTotal(payload.total_count);
                    setList(newList);
                    setPhotosMap(sortPhotoArray(newList));

                    setPending(false);
                    setPendingBefore(false);
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                        setPending(false);
                        setPendingBefore(false);
                    }
                });
        }
    };

    const $bottomPreviousPosts = useIntersect((entry) => {
        if(entry.isIntersecting) {
            _requestPhotoList(true, true);
        }
    }, {
        rootMargin: '100px 0px'
    });

    const elButtonBeforePosts = () => {
        if(!pending && list?.length) {
            if(list?.length === total) {
                return (
                    <span className={cn('invite__empty')}>
                        {t('global.massage.empty.photo_list')}
                    </span>
                );
            }

            return (
                <Button
                    ref={$bottomPreviousPosts}
                    disabled={pendingBefore}
                    isLoading={pendingBefore}
                    isSecondary={true}
                    className={cn('invite__button-before')}
                >
                    {t('global.button.load-more')}
                </Button>
            );
        }
    };

    const onChangeForm = debounce(useCallback(() => {
        if(album && form.checkValidity()) {
            _requestPhotoList();
        }
    }, [validity, album]), 300);

    const elList = useMemo(() => {
        if(photosMap) {
            const keys = Object.keys(photosMap);

            return keys.map((key, index) => {
                const link = `/invited-album/${id}`;

                return (
                    <div key={index}>
                        <PhotoList
                            dateLink={true}
                            list={photosMap[key]}
                            elControls={false}
                            elPortal={false}
                            link={link}
                        />
                    </div>
                );
            });
        }
    }, [JSON.stringify(photosMap)]);

    const elContent = useMemo(() => {
        if (pendingAlbum || pending) {
            return <Loader />;
        }

        if (!album) {
            return (
                <span className={cn('invite__empty')}>
                    {t('global.massage.empty.album')}
                </span>
            );
        }

        if (!total) {
            return (
                <span className={cn('invite__empty')}>
                    {t('global.massage.empty.search-photo')}
                </span>
            );
        }

        if(photosMap) {
            return elList;
        }
    }, [album, pendingAlbum, pending, JSON.stringify(photosMap)]);

    const elError = useMemo(() => {
        if (error) {
            return (
                <div className={cn('invite__error')}>
                    <IconError className={cn('invite__error-icon')} />
                    {error}
                </div>
            );
        }
    }, [error]);

    const elPageHeader = useMemo(() => {
        if(album?.name) {
            return <h1 className={cn('invite__header')}>{album.name}</h1>;
        }
    }, [album]);

    return (
        <UI.Main className={cn('invite')}>
            {elPageHeader}
            <div className={cn('invite__grid')}>
                <div className={cn('invite__content')}>
                    <section className={cn('invite__bottom')}>
                        <UI.Main className={cn('invite__bottom-content')}>
                            <UI.Content>
                                <UI.Box padding={true} className={cn('invite__content-box')}>
                                    {elError}
                                    {elContent}
                                    {elButtonBeforePosts()}
                                </UI.Box>
                            </UI.Content>
                        </UI.Main>
                    </section>
                </div>
                <UI.Sidebar className={cn('invite__sidebar')}>
                    <SearchForm
                        registry={form}
                        field={field}
                        onChange={onChangeForm}
                        onChangeValidity={setValidity}
                    />
                </UI.Sidebar>
            </div>
        </UI.Main>
    );
};

export default InvitedAlbum;
