import React, { Fragment, FC, useState, useEffect, ReactNode, MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import { useClassnames } from 'hook/use-classnames';
import Carousel from 'component/carousel';
import cropImage from 'component/helper/crop-image';
import qaAttributes from 'component/helper/qa-attributes';

import { IProps } from './types';
import style from './index.pcss';
import ReactTooltip from 'react-tooltip';

const Attachments: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const [selectGallery, setSelectGallery] = useState<number | null>(null);

    const onKeyDown = (e: KeyboardEvent) => {
        if(e.key === 'Escape') {
            setSelectGallery(null);
        }
    };

    useEffect(() => {
        if(selectGallery !== null) {
            document.addEventListener('keydown', onKeyDown);
        } else {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, [selectGallery]);

    if(!Array.isArray(props.children) || !props.children.length) {
        return null;
    }

    const onClick = (index: number) => (e?: MouseEvent): void => {
        if (props.isGallery) {
            e?.preventDefault();
            setSelectGallery(index);
        }
    };

    const onClickClose = (e?: MouseEvent): void => {
        e?.preventDefault();

        setSelectGallery(null);
    };

    // const elPortal = (): ReactNode => {
    //     if(selectGallery !== null && props.children) {
    //         const isMorePhotos = props.attachmentsCount > props.children.length;
    //         let disable = false;
    //
    //         if (props.albumId) {
    //             disable = true;
    //         }
    //
    //         return (
    //             <Carousel
    //                 onClose={onClickClose}
    //                 selected={selectGallery}
    //                 isModal={true}
    //                 isMorePhotos={isMorePhotos}
    //                 disableButtons={props.isGallery || (!props.isGallery && disable)}
    //             >
    //                 {props.children}
    //             </Carousel>
    //         );
    //     }
    // };

    return (
        <Fragment>
            <ReactTooltip />
            {/*{props.isGallery && elPortal()}*/}
            <div
                className={cn('attachments', {
                    'attachments_limit': props.limit > 3
                })}
                data-count={props.children.length}
                {...(props.tooltip && { 'data-tip': props.tooltip })}
                {...qaAttributes(props['data-qa'] ? `attachments:${props['data-qa']}` : 'attachments')}
            >
                {props.children.map((item, index) => {
                    const limit = props.limit || 3;
                    const params = {};

                    if(index > limit) {
                        return null;
                    }

                    const content = (
                        <img
                            src={cropImage(item.url, '520x')}
                            className={cn('attachments__item')}
                            {...qaAttributes('attachments:image')}
                            onClick={onClick(index)}
                            alt=""
                        />
                    );

                    // if (index === limit && props.attachmentsCount > index) {
                    //     params = {
                    //         className     : cn('attachments__count'),
                    //         ['data-count']: `+${props.attachmentsCount - index}`,
                    //         ...qaAttributes('attachments:count'),
                    //         onClick: props.isGallery && onClick(index)
                    //     };
                    // }

                    if (props.link) {
                        return (
                            <Link key={index} to={props.link} {...params}>
                                {content}
                            </Link>
                        );
                    }

                    if(props.isLink) {
                        if (props.invitedAlbumId) {
                            return (
                                <Link key={index} to={`/invited-album/${props.invitedAlbumId}`} {...params}>
                                    {content}
                                </Link>
                            );
                        }

                        if (props.albumId) {
                            return (
                                <Link key={index} to={`/album/${props.albumId}`} {...params}>
                                    {content}
                                </Link>
                            );
                        }

                        let link = '/search-photo?';
                        link += props.eventId ? `&event_id=${props.eventId}` : '&only_no_event_photos=true';

                        if (props.personsIds?.length) {
                            link += props.personsIds.reduce((acc: string, id: number) => (`${acc}&persons_ids=${id}`), '');
                        }

                        if (props.query) {
                            link += `&${props.query}`;
                        }

                        return (
                            <Link key={index} to={link} {...params}>
                                {content}
                            </Link>
                        );
                    }

                    return (
                        <div key={index} className={cn('attachments__image-wrapper')}>
                            {content}
                        </div>
                    );
                })}
            </div>
        </Fragment>
    );
};

export default Attachments;
