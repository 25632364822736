import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import throttle from 'lodash.throttle';

import { set as setDeviceInfo, setWidth as setWidthDeviceInfo } from 'component/device-info/actions';

import { IProps } from './types/provider';

const Provider = (props: IProps) => {
    // Device info
    const dispatch = useDispatch();

    const onResize = useCallback(throttle(() => {
        dispatch(setWidthDeviceInfo(window.innerWidth));
    }, 300), []);

    dispatch(setDeviceInfo({
        width     : window.screen.width,
        pixelRatio: window.devicePixelRatio
    }));

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return props.children || null;
};

export default Provider;
