import React, { useCallback, useEffect, useState, useMemo, useRef, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBook, faImages, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import { useCancelTokens } from 'component/core/cancel-token';
import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Loader from 'component/loader';
import { useRegistry } from 'component/form';
import { IValue } from 'component/form/input-clubs/types';
import Button from 'component/button';
import { bulkEditPhoto, getOwnPhotoList } from 'component/api/photo';
import { key as keyUser } from 'component/user/reducer';
import { key as keyDeviceInfo } from 'component/device-info/reducer';
import { createAlbum, getAlbumWithPhotoList } from 'component/api/album';
import Album from 'component/album';
import Modal from 'component/modal';
import SideAction from 'component/wireframes/side-action';
import AlbumModal from 'component/modal/album';
import ActionButton from 'component/button/circular/action-button';
import ButtonCircular from 'component/button/circular';
import { useAlert } from 'component/alert/provider';
import { IStore } from 'src/types/reducers';
import { ISelectedMap, IExtDataAlbumsItem } from './types';
import {
    DataFilesItem, DataFilesTmpFacesItem,
    DataFilesTmpFacesPersonsItem
} from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { IPayload } from 'component/form/types';

import style from './index.pcss';
import { sendModerationPhotos } from 'component/api/moderation';
import sortPhotoArray, { ISortedMap } from 'component/helper/sort-photos-list';
import { DataPhotoListItem } from 'component/api/types/api/photo/get-own-photo-list/get/code-200';
import PhotoList from 'component/photo-list';
import PhotoSaleModal from 'component/modal/sale';
import TarifForm from 'route/album/tarif-form';

const PHOTOS_LIMIT = 30;
const ALBUMS_LIMIT = 4;

const Dashboard = () => {
    const cn = useClassnames(style);
    const [tokenWithFace, tokenWithoutFace, tokenEdit, tokenEditUser, tokenUserSelfInfo] = useCancelTokens(5);
    const [tokenAlbums, tokenCreateAlbum] = useCancelTokens(2);
    const { t } = useTranslation();
    const { field, form } = useRegistry();
    const { show } = useAlert();

    const [pendingWithFace, setPendingWithFace] = useState<boolean>(true);
    const [pendingWithoutFace, setPendingWithoutFace] = useState<boolean>(true);
    const [pendingBeforeWithFace, setPendingBeforeWithFace] = useState<boolean>(false);
    const [pendingBeforeWithoutFace, setPendingBeforeWithoutFace] = useState<boolean>(false);
    const [photoWithFaceMap, setPhotoWithFaceMap] = useState<ISortedMap | null>(null);
    const [photoWithoutFaceMap, setPhotoWithoutFaceMap] = useState<ISortedMap | null>(null);
    const [photoWithFace, setPhotoWithFace] = useState<Array<DataPhotoListItem>>([]);
    const [photoWithoutFace, setPhotoWithoutFace] = useState<Array<DataPhotoListItem>>([]);
    const [totalWithFace, setTotalWithFace] = useState<number>(0);
    const [totalWithoutFace, setTotalWithoutFace] = useState<number>(0);

    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const isMobile = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].tablet);

    const [validity, setValidity] = useState<boolean>(false);
    const [idsList, setIdsList] = useState<Array<number>>([]);
    const [idsWithFaces, setIdsWithFaces] = useState<ISelectedMap>({});
    const [idsWithoutFaces, setIdsWithoutFaces] = useState<ISelectedMap>({});
    const [totalAlbums, setTotalAlbums] = useState<number>(0);
    const refIdsWithFaces = useRef<ISelectedMap>(idsWithFaces);
    const refIdsWithoutFaces = useRef<ISelectedMap>(idsWithoutFaces);
    const [pendingAlbum, setPendingAlbum] = useState<boolean>(false);
    const [listAlbum, setListAlbum] = useState<Array<IExtDataAlbumsItem>>([]);
    const [resetIdsList, setResetIdsList] = useState<boolean>(false);
    const refReset = useRef<boolean>(resetIdsList);
    const [isModerationBtn, setIsModerationBtn] = useState<boolean>(false);

    const registry = useRegistry();
    const [cancelToken] = useCancelTokens(1);
    const [modalForm, setModalForm] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [tarifModal, setTarifModal] = useState<boolean>(false);
    const [albumID, setAlbumID] = useState<number>(0);
    const tarifRegistry = useRegistry();

    const [image, setImage] = useState<DataFilesItem | null>(null);

    const _requestAlbums = (limit: number, merge?: boolean) => {
        setPendingAlbum(true);

        getAlbumWithPhotoList({
            params: {
                limit,
                offset: merge ? listAlbum?.length - 1 : 0
            },
            cancelToken: tokenAlbums.new()
        })
            .then((resp) => {
                const [, ...rest] = listAlbum;
                const newListAlbum = merge ? [...rest, ...resp.albums] : resp.albums;

                setListAlbum(newListAlbum);
                setTotalAlbums(resp.total_count);
                setPendingAlbum(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingAlbum(false);
                }
            });
    };

    useEffect(() => {
        return () => {
            tokenWithFace.remove();
            tokenWithoutFace.remove();
            tokenEdit.remove();
            tokenEditUser.remove();
            tokenUserSelfInfo.remove();
            tokenAlbums.remove();
            tokenCreateAlbum.remove();
        };
    }, []);

    const onClickMoreAlbums = (e: MouseEvent): void => {
        e.preventDefault();

        _requestAlbums(ALBUMS_LIMIT, true);
    };

    const updPhotoInfo = useCallback((photo_data) => {
        bulkEditPhoto({
            cancelToken: tokenEdit.new(),
            data       : {
                photo_ids: idsList,
                photo_data
            }
        })
            .then(() => {

                if ('album_id' in photo_data || 'is_deleted' in photo_data) {
                    setResetIdsList(true);
                    setIdsList([]);
                }

                _requestPhotoWithFace();
                _requestPhotoWithoutFace();

                show('Ваши изменения успешно применены.');
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    show('Не удалось применить Ваши изменения.', 'warning');
                }
            });
    }, [JSON.stringify(idsList)]);

    const _requestPhotoWithFace = (merge?: boolean, isBefore?: boolean) => {
        if (isBefore) {
            setPendingBeforeWithFace(true);
        } else {
            setPendingWithFace(true);
        }

        const formParams = getFormParams(form.getPayload());
        const params = {
            album_ids: [0],
            faces_detected: true,
            ...formParams
        };

        getOwnPhotoList({
            cancelToken: tokenWithFace.new(),
            params: {
                limit : PHOTOS_LIMIT,
                offset: merge ? photoWithFace?.length : 0,
                ...params
            }
        }).then((resp) => {
            const newPhotos = merge ? [...photoWithFace, ...resp.photo_list] : resp.photo_list;

            if (newPhotos.length) {
                setPhotoWithFaceMap(sortPhotoArray(newPhotos));
            } else {
                setPhotoWithFaceMap(null);
            }

            setPhotoWithFace(newPhotos);
            setTotalWithFace(resp.total_count);

            setPendingWithFace(false);
            setPendingBeforeWithFace(false);
        }).catch((err) => {
            setPendingWithFace(false);
            setPendingBeforeWithFace(false);
        });
    };

    const _requestPhotoWithoutFace = (merge?: boolean, isBefore?: boolean) => {
        if (isBefore) {
            setPendingBeforeWithoutFace(true);
        } else {
            setPendingWithoutFace(true);
        }

        const formParams = getFormParams(form.getPayload());
        const params = {
            album_ids: [0],
            faces_detected: false,
            ...formParams
        };

        getOwnPhotoList({
            cancelToken: tokenWithoutFace.new(),
            params: {
                limit : PHOTOS_LIMIT,
                offset: merge ? photoWithoutFace?.length : 0,
                ...params
            }
        }).then((resp) => {
            const newPhotos = merge ? [...photoWithoutFace, ...resp.photo_list] : resp.photo_list;

            if (newPhotos.length) {
                setPhotoWithoutFaceMap(sortPhotoArray(newPhotos));
            } else {
                setPhotoWithoutFaceMap(null);
            }

            setPhotoWithoutFace(newPhotos);
            setTotalWithoutFace(resp.total_count);
            setPendingWithoutFace(false);
            setPendingBeforeWithoutFace(false);
        }).catch((err) => {
            setPendingWithoutFace(false);
            setPendingBeforeWithoutFace(false);
        });
    };

    const _moderationPhotos = () => {
        const photosWithoutFaces =  Object.keys(idsWithoutFaces).reduce((accum: Array<number>, current: string) => {
            return accum.concat(idsWithoutFaces[Number(current)]);
        }, []);

        if (Boolean(photosWithoutFaces.length)) {
            sendModerationPhotos({
                data: {
                    photo_ids: photosWithoutFaces
                }
            })
                .then(() => {
                    show('Выбранные фотографии отправлены на модерацию.');
                    _requestPhotoWithoutFace();
                })
                .catch((err) => {
                    console.error(err);
                    show('Не удалось отправить заявку на модерацию.', 'warning');
                });
        }
    };

    const onClickModerationPhotos = useCallback(() => {
        _moderationPhotos();
    }, [idsWithoutFaces]);

    const onChangeForm = debounce(useCallback(() => {
        if(form.checkValidity()) {
            _requestPhotoWithFace();
            _requestPhotoWithoutFace();
        }
    }, [validity, image]), 300);

    const onFileLoaded = (file: DataFilesItem): void => {
        setImage(file);
    };

    const getFormParams = (data: IPayload) => {
        const faces = data.photo?.tmp_faces;
        let personsNew: Array<DataFilesTmpFacesPersonsItem> = [];

        if(faces?.length) {
            personsNew = faces.reduce((acc: Array<DataFilesTmpFacesPersonsItem>, curr: DataFilesTmpFacesItem) => {
                if(curr.persons?.length) {
                    curr.persons.forEach((person) => {
                        const isExist = acc.find((item) => item.id === person.id);

                        if(person.id && !isExist) {
                            acc.push(person);
                        }
                    });
                }

                return acc;
            }, []);
        }

        return {
            ...(personsNew.length && { person_ids: personsNew.map((item) => item.id) }),
            ...(data.name && { person_name: data.name }),
            ...(data.location?.value && { location_id: data.location?.value }),
            ...(data.event?.value && { event_id: data.event.value }),
            ...(data.price_range?.value_from && { price_from: data.price_range?.value_from }),
            ...(data.price_range?.value_to && { price_to: data.price_range?.value_to }),
            ...(data.event_date_range?.date_from && { date_from: data.event_date_range?.date_from }),
            ...(data.event_date_range?.date_to && { date_end: data.event_date_range?.date_to }),
            ...(data.clubs?.length && { club_ids: data.clubs.map((club: IValue) => club.value) }),
            ...(data.color && { color_ids: data.color }),
            ...(data.sport_number && { number: data.sport_number }),
            ...(data.time && { time: data.time }),
            ...(data.timeRange?.valueFrom && { time_from: data.timeRange.valueFrom }),
            ...(data.timeRange?.valueTo && { time_to: data.timeRange.valueTo })
        };
    };

    const onChangeSelectedWithFaces = useCallback((index: number) => (ids: Array<number>) => {
        const newList = { ...refIdsWithFaces.current };
        newList[index] = ids;

        setIdsWithFaces(newList);
    }, []);

    const onChangeSelectedWithoutFaces = useCallback((index: number) => (ids: Array<number>) => {
        const newList = { ...refIdsWithoutFaces.current };
        newList[index] = ids;

        setIdsWithoutFaces(newList);
    }, []);

    const elPhotosWithFaces = useMemo(() => {
        if (pendingWithFace) {
            return <Loader />;
        }

        if (photoWithFaceMap) {
            const keys = Object.keys(photoWithFaceMap);

            return keys.map((key, index) => {
                return (
                    <div key={index}>
                        <PhotoList
                            disableButtons={true}
                            dateLink={true}
                            list={photoWithFaceMap[key]}
                            onChangeSelectedList={onChangeSelectedWithFaces(index)}
                            resetSelected={resetIdsList}
                        />
                    </div>
                );
            });
        }

        return <div className={cn('photos__empty')}>Фото не найдены</div>;
    }, [JSON.stringify(photoWithFaceMap), resetIdsList, pendingWithFace]);

    const elPhotosWithoutFaces = useMemo(() => {
        if (pendingWithoutFace) {
            return <Loader />;
        }

        if (photoWithoutFaceMap) {
            const keys = Object.keys(photoWithoutFaceMap);

            return keys.map((key, index) => {
                return (
                    <div key={index}>
                        <PhotoList
                            disableButtons={true}
                            dateLink={true}
                            list={photoWithoutFaceMap[key]}
                            onChangeSelectedList={onChangeSelectedWithoutFaces(index)}
                            resetSelected={resetIdsList}
                        />
                    </div>
                );
            });
        }

        return <div className={cn('photos__empty')}>Фото не найдены</div>;
    }, [JSON.stringify(photoWithoutFaceMap), resetIdsList, pendingWithoutFace]);

    useEffect(() => {
        refIdsWithoutFaces.current = idsWithoutFaces;
        refIdsWithFaces.current = idsWithFaces;

        const photosWithFaces =  Object.keys(refIdsWithFaces.current).reduce((accum: Array<number>, current: string) => {
            return accum.concat(refIdsWithFaces.current[Number(current)]);
        }, []);
        const photosWithoutFaces =  Object.keys(refIdsWithoutFaces.current).reduce((accum: Array<number>, current: string) => {
            return accum.concat(refIdsWithoutFaces.current[Number(current)]);
        }, []);

        setIsModerationBtn(Boolean(photosWithoutFaces.length));
        setIdsList([...photosWithFaces, ...photosWithoutFaces]);
    }, [JSON.stringify(idsWithFaces), JSON.stringify(idsWithoutFaces)]);

    useEffect(() => {
        refReset.current = resetIdsList;
    }, [resetIdsList]);

    useEffect(() => {
        _requestAlbums(3);
    }, []);

    if(!userId) {
        return <Redirect to="/login" />;
    }

    const onSubmit = useCallback(() => {
        const fields = registry.form.getFields();

        if (fields) {
            const fieldName = fields.get('album_name');
            const fieldDescription = fields.get('album_description');
            const fieldPrivate = fields.get('album_private');
            const fieldPrepaid = fields.get('album_prepaid');
            const downloadCount = fields.get('download_count');

            if (fieldName && fieldDescription) {

                if (fieldName.value === t('global.reserved-names.my-photo')) {
                    setError(t('global.massage.reserved-name'));
                } else {
                    createAlbum({
                        cancelToken: cancelToken.new(),
                        data: {
                            name: String(fieldName.value),
                            download_count: Number(downloadCount?.value),
                            description: String(fieldDescription.value) ? fieldDescription.value : null,
                            is_private: fieldPrivate?.value,
                            is_prepaid: fieldPrepaid?.value
                        }
                    })
                        .then((resp) => {
                            setModalForm(false);
                            _requestAlbums(ALBUMS_LIMIT);
                            show(t('route.dashboard.photos.modal.message-ok'));

                            if (fieldPrepaid?.value) {
                                setAlbumID(resp.id);
                                setTarifModal(true);
                            }
                        })
                        .catch((err) => {
                            if(!axios.isCancel(err)) {
                                setError('Ошибка при выполении запроса');
                                show(err.message, 'warning');
                            }
                        });
                }
            }
        }
    }, [modalForm]);

    const onClickModal = useCallback(() => {
        setModalForm(true);
    }, [modalForm]);

    const onClickCloseModal = (): void => {
        setModalForm(false);
    };

    const elModal = useMemo(() => {
        if (modalForm) {
            return (
                <AlbumModal
                    title={'Создать альбом'}
                    registry={registry}
                    onClickClose={onClickCloseModal}
                    onSubmitClick={onSubmit}
                />
            );
        }
    }, [error, modalForm]);

    const elTarifModal = useMemo(() => {
        if (tarifModal) {
            const closeModal = () => setTarifModal(false);

            return (
                <Modal onClickClose={closeModal}>
                    <TarifForm
                        registry={tarifRegistry}
                        albumId={albumID}
                        onSubmit={closeModal}
                    />
                </Modal>
            );
        }
    }, [tarifModal, albumID]);

    const elAlbumContent = useMemo(() => {
        if(listAlbum.length) {
            return (
                <div className={cn('photos__items')}>
                    {listAlbum.map((item, index) => (
                        <Album
                            key={index}
                            name={item.name}
                            id={item.id}
                            user_rights={item.user_rights}
                            description={item.description}
                            photos={item.photos}
                            photos_count={item.photos_count}
                            isPersonal={item.isPersonal}
                            date={item.date}
                            created_at={item.created_at}
                            is_private={item.is_private}
                            className={cn('photos__post')}
                        />
                    ))}
                </div>
            );
        }
    }, [JSON.stringify(listAlbum), pendingAlbum]);

    const elButtonBeforeAlbums = () => {
        if(pendingAlbum) {
            return <Loader />;
        }

        if (!pendingAlbum && listAlbum?.length) {
            if(!(listAlbum?.length - 1 === totalAlbums)) {
                return (
                    <Button
                        disabled={pendingAlbum}
                        isLoading={pendingAlbum}
                        isSecondary={true}
                        className={cn('home__button-before')}
                        onClick={onClickMoreAlbums}
                    >
                        Загрузить ещё
                    </Button>
                );
            }
        }
    };

    const elSidebarButton = () => {
        return (
            <div>
                <UI.Box padding={true} className={cn('photos__sidebar_box')}>
                    <Button
                        to="/upload"
                        className={cn('photos__sidebar_button')}
                    >
                        <div className={cn('photos__sidebar_button-icon')}>
                            <FontAwesomeIcon icon={faPlusSquare} />
                        </div>
                        <div className={cn('photos__sidebar_button-text')}>
                            {t('global.button.photo-upload')}
                        </div>
                    </Button>
                    <Button
                        isSecondary={true}
                        className={cn('photos__sidebar_button')}
                        onClick={onClickModal}
                    >
                        <div className={cn('photos__sidebar_button-icon')}>
                            <FontAwesomeIcon icon={faPlusSquare} />
                        </div>
                        <div className={cn('photos__sidebar_button-text')}>
                            {t('route.dashboard.photos.button-album-create')}
                        </div>
                    </Button>
                    <Button
                        className={cn('photos__sidebar_button')}
                        onClick={() => setPhotoSaleModal(true)}
                    >
                        Скидки
                    </Button>
                </UI.Box>
            </div>
        );
    };

    const elAddBtn = () => {
        if (isMobile || isTablet) {
            return (
                <ActionButton
                    className={cn('photos__add-btn')}
                    icon={faPlus}
                >
                    <ButtonCircular
                        to="/upload"
                        className={cn('add-btn__item')}
                        text={'Загрузить фото'}
                        isSmall={true}
                    >
                        <FontAwesomeIcon icon={faImages} />
                    </ButtonCircular>
                    <ButtonCircular
                        className={cn('add-btn__item')}
                        text={'Создать альбом'}
                        isSmall={true}
                        onClick={onClickModal}
                    >
                        <FontAwesomeIcon icon={faBook} />
                    </ButtonCircular>
                </ActionButton>
            );
        }
    };

    const onClickMorePhotoWithFace = (): void => {
        _requestPhotoWithFace(true, true);
    };

    const elMorePhotoWithFace = () => {
        if(!pendingWithFace && photoWithFace?.length) {
            if (!(photoWithFace?.length === totalWithFace)) {
                return (
                    <div className={cn('photos__btn-more')}>
                        <Button
                            disabled={pendingBeforeWithFace}
                            isLoading={pendingBeforeWithFace}
                            isSecondary={true}
                            onClick={onClickMorePhotoWithFace}
                        >
                            Загрузить ещё
                        </Button>
                    </div>
                );
            }
        }
    };

    const onClickMorePhotoWithoutFace = (): void => {
        _requestPhotoWithoutFace(true, true);
    };

    const elMorePhotoWithoutFace = () => {
        if(!pendingWithoutFace && photoWithoutFace?.length) {
            if (!(photoWithoutFace?.length === totalWithoutFace)) {
                return (
                    <div className={cn('photos__btn-more')}>
                        <Button
                            disabled={pendingBeforeWithoutFace}
                            isLoading={pendingBeforeWithoutFace}
                            isSecondary={true}
                            onClick={onClickMorePhotoWithoutFace}
                        >
                            Загрузить ещё
                        </Button>
                    </div>
                );
            }
        }
    };

    // Photo Sale Modal
    const [photoSaleModal, setPhotoSaleModal] = useState<boolean>(false);

    const elPhotoSaleModal = useMemo(() => {
        if (photoSaleModal) {
            return (
                <PhotoSaleModal
                    registry={registry}
                    onClickClose={() => setPhotoSaleModal(false)}
                />
            );
        }
    }, [photoSaleModal]);

    return (
        <SideAction
            title={t('route.dashboard.photos.header')}
            content={(
                <div>
                    {elAddBtn()}
                    <section className={cn('photos__top')}>
                        <UI.Main className={cn('photos__top-content')}>
                            {elAlbumContent}
                            {elButtonBeforeAlbums()}
                        </UI.Main>
                    </section>
                    <section className={cn('photos__bottom')}>
                        <UI.BoxHeader>Фотографии без лиц (требуют модерации) {totalWithoutFace !== 0 && <span style={{marginLeft: '10px'}}>{totalWithoutFace} фото</span>}</UI.BoxHeader>
                        <UI.Main className={cn('photos__bottom-content')}>
                            <UI.Box padding={true} className={cn('photos__content-box')}>
                                {isModerationBtn && (
                                    <div className={cn('photos__moderation-btn')}>
                                        <Button onClick={onClickModerationPhotos}>Отправить на модерацию</Button>
                                    </div>
                                )}
                                {elPhotosWithoutFaces}
                                {elMorePhotoWithoutFace()}
                            </UI.Box>
                        </UI.Main>
                    </section>
                    <section className={cn('photos__bottom')}>
                        <UI.BoxHeader>Фотографии для продажи {totalWithFace !== 0 && <span style={{marginLeft: '10px'}}>{totalWithFace} фото</span>}</UI.BoxHeader>
                        <UI.Main className={cn('photos__bottom-content')}>
                            <UI.Box padding={true} className={cn('photos__content-box')}>
                                {elPhotosWithFaces}
                                {elMorePhotoWithFace()}
                            </UI.Box>
                        </UI.Main>
                    </section>
                </div>
            )}
            sidebar={elSidebarButton()}
            idsList={idsList}
            form={form}
            field={field}
            update={updPhotoInfo}
            onChangeForm={onChangeForm}
            onChangeValidity={setValidity}
            onFileLoaded={onFileLoaded}
        >
            {elModal}
            {elPhotoSaleModal}
            {elTarifModal}
        </SideAction>
    );
};

// tslint:disable-next-line:max-file-line-count
export default Dashboard;
