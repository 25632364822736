import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import axios from 'axios';
import { useHistory } from 'react-router';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import cropImage from 'component/helper/crop-image';
import Loader from 'component/loader';
import { useCancelToken } from 'component/core/cancel-token';
import { IStore } from 'src/types/reducers';
import { key as cartKey } from './reducer';
import { key as keyUser } from 'component/user/reducer';
import { IStore as IStoreCart } from './types/reducer';

import { DataItemsMetadata } from 'component/api/types/api/payment/cart/get_user_cart/get/code-200';
import { addCart } from 'route/cart/actions';
import { deleteCartItem } from 'component/api/cart';

// @ts-ignore
import offer from './docs/offer.pdf';
import style from './index.pcss';
import Button from 'component/button';
import { createOrder } from 'component/api/payment';
import { faRubleSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Cart = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const token = useCancelToken();
    const createToken = useCancelToken();
    const history = useHistory();

    const [pending, setPending] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const dispatch = useDispatch();
    const items = useSelector<IStore, IStoreCart>((store) => store[cartKey]);
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);
    const userId = useSelector<IStore, number | undefined>((storeApp) => storeApp[keyUser].id);

    // Cart
    const cartToken = useCancelToken();

    useEffect(() => {
        return () => {
            cartToken.remove();
            createToken.remove();
        };
    }, []);

    // const [order, setOrder] = useState<PlaceOrderData | null>(null);
    //
    // useEffect(() => {
    //     createOrder();
    //
    //     return () => {
    //         token.remove();
    //         tokenCreate.remove();
    //     };
    // }, [JSON.stringify(items)]);
    //
    // const onClickRemove = useCallback((id: number) => (): void => {
    //     setError(null);
    //
    //     if (items && !pending) {
    //         dispatch(removeItem(items[id]));
    //         createOrder();
    //     }
    // }, [JSON.stringify(items)]);
    //
    // const prepareOrderItems = () => {
    //     const photoList = Object.values(items) && Object.values(items).filter(({ id, photographer }) => {
    //         if (!!photographer) {
    //             const isOwner = photographer?.id === userId;
    //
    //             return !isOwner;
    //         }
    //
    //         return false;
    //     });
    //     const adList = Object.values(items) && Object.values(items).filter(({ banner }) => {
    //         return !!banner;
    //     });
    //     const subscribeList = Object.values(items) && Object.values(items).filter(({ id }) => {
    //         return id === -1;
    //     });
    //
    //     const orderPhotos = photoList.map(({ id }) => ({
    //         photo_id: id,
    //         item_type: 'PHOTO'
    //     }));
    //     const orderAds = adList.map(({ id }) => ({
    //         photo_ad_id: id,
    //         item_type: 'AD'
    //     }));
    //     const orderSubscribe = subscribeList.map(() => ({
    //         item_type: 'FIND_PARTNER_MONTH'
    //     }));
    //
    //     return [...orderAds, ...orderPhotos, ...orderSubscribe];
    // };
    //
    // const createOrder = () => {
    //     const orderItems = prepareOrderItems();
    //
    //     if (orderItems.length) {
    //         placeOrder({
    //             data: {
    //                 order_items: orderItems
    //             },
    //             cancelToken: tokenCreate.new()
    //         })
    //             .then((resp) => {
    //                 setOrder(resp);
    //             })
    //             .catch((err) => {
    //                 if (!axios.isCancel(err)) {
    //                     console.error(err);
    //                     setPending(false);
    //                     setError(err.message);
    //                 }
    //             });
    //     } else {
    //         setOrder(null);
    //     }
    // };
    //
    // const onClickPlaceOrder = useCallback((): void => {
    //     if (order) {
    //         setPending(true);
    //         initPayment({
    //             data: {
    //                 order_id: order.id
    //             }
    //         })
    //             .then((response) => {
    //                 const params = {
    //                     MNT_SUCCESS_URL: `${location.origin}/history/${order.id}/success`,
    //                     MNT_FAIL_URL: `${location.origin}/cart`
    //                 };
    //
    //                 window.location.href = `${response.url}&${qs.stringify(params)}`;
    //             });
    //     }
    // }, [order]);
    //
    // const elError = useMemo(() => {
    //     if (error) {
    //         return (
    //             <div className={cn('cart__error')}>{error}</div>
    //         );
    //     }
    // }, [error]);
    //
    // const elContent = useMemo(() => {
    //     if (order) {
    //         return (
    //             <div className={cn('cart__list')}>
    //                 {
    //                     order.order_items.map((item, index) => {
    //                         if (item.item_type === 'FIND_PARTNER_MONTH') {
    //                             return (
    //                                 <div className={cn('cart__list-item')} key={index}>
    //                                     <img alt="" src={imgSubscription} className={cn('cart__item-image')} />
    //                                     <div className={cn('cart__item-content')}>
    //                                         <div className={cn('cart__item-block')}>
    //                                             <p className={cn('cart__item-text')}>Подписка на месяц</p>
    //                                             <p
    //                                                 className={cn(
    //                                                     'cart__item-text-sub',
    //                                                     'cart__item-text-sub_remove'
    //                                                 )}
    //                                                 onClick={onClickRemove(item.id)}
    //                                             >
    //                                                 {t('route.cart.item.remove')}
    //                                             </p>
    //                                         </div>
    //                                         <span className={cn('cart__item-price')}>
    //                                             {t('route.cart.item.price', { count: item.price })}
    //                                         </span>
    //                                     </div>
    //                                 </div>
    //                             );
    //                         }
    //
    //                         if (item.item_type === 'PHOTO' && item.photo) {
    //                             const photo = item.photo;
    //                             const isOwner = isAuth && photo.photographer?.id === userId;
    //                             const author = photo.photographer;
    //                             const event = photo.event?.name || t('route.cart.item.event-empty');
    //                             const eventId = photo.event?.id || 0;
    //                             let newPrice = 0;
    //
    //                             if (item.sale) {
    //                                 newPrice = Math.ceil((item.price * (1 - (item.sale / 100))) * 100) / 100;
    //                             }
    //
    //                             return (
    //                                 <div className={cn('cart__list-item')} key={index}>
    //
    //                                 </div>
    //                             );
    //                         }
    //
    //                         if (item.item_type === 'AD' && item.photo && item.photo_ad) {
    //                             const photo = item.photo;
    //                             const event = photo.event?.name || t('route.cart.item.event-empty');
    //                             const banner = item.photo_ad.banner;
    //
    //                             return (
    //                                 <div className={cn('cart__list-item')} key={item.id}>
    //                                     <img
    //                                         alt={banner?.text}
    //                                         src={cropImage(photo.url, '310x200')}
    //                                         className={cn('cart__item-image')}
    //                                     />
    //                                     <div className={cn('cart__item-content')}>
    //                                         <div className={cn('cart__item-block')}>
    //                                             <p className={cn('cart__item-text')}>{event}</p>
    //                                             <p className={cn('cart__item-text-sub')}>
    //                                                 Реклама на {item.photo_ad.period}
    //                                             </p>
    //                                             <p
    //                                                 className={cn(
    //                                                     'cart__item-text-sub',
    //                                                     'cart__item-text-sub_remove'
    //                                                 )}
    //                                                 onClick={onClickRemove(item.id)}
    //                                             >
    //                                                 {t('route.cart.item.remove')}
    //                                             </p>
    //                                         </div>
    //                                         <span className={cn('cart__item-price')}>
    //                                             {t(
    //                                                 'route.cart.item.price',
    //                                                 {count: (item.photo_ad.tariff * item.photo_ad.period)}
    //                                             )}
    //                                         </span>
    //                                     </div>
    //                                 </div>
    //                             );
    //                         }
    //                     })
    //                 }
    //             </div>
    //         );
    //     }
    //
    //     return (
    //         <div className={cn('cart__empty')}>{t('route.cart.content.empty')}</div>
    //     );
    // }, [order, isAuth, userId]);
    //
    // const elSidebar = useMemo(() => {
    //     const cartItems = {
    //         photo: {
    //             count: 0,
    //             price: 0
    //         },
    //         ad: {
    //             count: 0,
    //             price: 0
    //         },
    //         subscribe: {
    //             count: 0,
    //             price: 0
    //         }
    //     };
    //     let priceWithoutSale = 0;
    //
    //     order?.order_items.map((item) => {
    //         priceWithoutSale += item.price;
    //
    //         switch (item.item_type) {
    //             case 'PHOTO':
    //                 let item_price = item.price;
    //
    //                 if (item.sale) {
    //                     item_price = Math.ceil((item_price * (1 - (item.sale / 100))) * 100) / 100;
    //                 }
    //
    //                 cartItems.photo.price = cartItems.photo.price + item_price;
    //                 cartItems.photo.count = cartItems.photo.count + 1;
    //                 break;
    //             case 'AD':
    //                 cartItems.ad.price = cartItems.ad.price + item.price;
    //                 cartItems.ad.count = cartItems.ad.count + 1;
    //                 break;
    //             case 'FIND_PARTNER_MONTH':
    //                 cartItems.subscribe.price = cartItems.subscribe.price + item.price;
    //                 cartItems.subscribe.count = cartItems.subscribe.count + 1;
    //                 break;
    //         }
    //     });
    //
    //     const price = Math.ceil((cartItems.photo.price + cartItems.subscribe.price + cartItems.ad.price) * 100) / 100;
    //
    //     return (
    //         <div className={cn('cart__sidebar')}>
    //             <h2 className={cn('cart__block-header')}>{t('route.cart.sidebar.header')}</h2>
    //             <div className={cn('cart__sidebar-items')}>
    //                 {
    //                     Boolean(cartItems.photo.count) && (
    //                         <div className={cn('cart__sidebar-item', 'cart__sidebar-item_border')}>
    //                             <span className={cn('cart__sidebar-text')}>{t('route.cart.sidebar.photo')}</span>
    //                             <div className={cn('cart__sidebar-text')}>{cartItems.photo.count}</div>
    //                         </div>
    //                     )
    //                 }
    //                 {
    //                     Boolean(cartItems.ad.count) && (
    //                         <div className={cn('cart__sidebar-item', 'cart__sidebar-item_border')}>
    //                             <span className={cn('cart__sidebar-text')}>Реклама</span>
    //                             <div className={cn('cart__sidebar-text')}>{cartItems.ad.count}</div>
    //                         </div>
    //                     )
    //                 }
    //                 {
    //                     Boolean(cartItems.subscribe.count) && (
    //                         <div className={cn('cart__sidebar-item', 'cart__sidebar-item_border')}>
    //                             <span className={cn('cart__sidebar-text')}>Подписки</span>
    //                             <div className={cn('cart__sidebar-text')}>{cartItems.subscribe.count}</div>
    //                         </div>
    //                     )
    //                 }
    //                 <div className={cn('cart__sidebar-item')}>
    //                     <span className={cn('cart__sidebar-text')}>{t('route.cart.sidebar.sum')}</span>
    //                     {(price === priceWithoutSale) && (
    //                         <div className={cn('cart__sidebar-text')}>{
    //                             t('route.cart.sidebar.sum-count', { count: price })}
    //                         </div>
    //                     )}
    //                     {(price !== priceWithoutSale) && (
    //                         <div>
    //                             <div className={cn('cart__sidebar-price-old')}>{priceWithoutSale}</div>
    //                             <div className={cn('cart__sidebar-price-new')}>{price}</div>
    //                         </div>
    //                     )}
    //                 </div>
    //                 <Button
    //                     type="button"
    //                     className={cn('cart__button')}
    //                     onClick={onClickPlaceOrder}
    //                     disabled={Boolean(pending || !order?.order_items.length)}
    //                     isLoading={pending}
    //                 >
    //                     {t('route.cart.sidebar.button')}
    //                 </Button>
    //                 <p className={cn('cart__description')}>
    //                     {t('route.cart.sidebar.description-1')}
    //                     <a
    //                         href={offer}
    //                         target="_blank"
    //                         className={cn('cart__link')}
    //                     >
    //                         {t('route.cart.sidebar.offer')}
    //                     </a>
    //                     {t('route.cart.sidebar.description-2')}
    //                 </p>
    //             </div>
    //         </div>
    //     );
    // }, [order, pending]);

    const _requestDeleteCartItem = (vendorCode: string) => {
        deleteCartItem({
            data: {
                vendor_code: vendorCode
            },
            cancelToken: cartToken.new()
        })
            .then((resp) => {
                dispatch(addCart(resp));
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);
                }
            });
    };

    const onClickRemove = useCallback((vendorCode: string) => (): void => {
        _requestDeleteCartItem(vendorCode);
    }, []);

    const onClickCreateOrder = useCallback((): void => {
        if (Object.values(items).length) {
            setPending(true);
            createOrder({
                cancelToken: createToken.new()
            })
                .then((response) => {
                    const params = {
                        MNT_SUCCESS_URL: `${location.origin}/history/${response.order_id}/success`,
                        MNT_FAIL_URL: `${location.origin}/cart`
                    };

                    window.location.href = `${response.url}&${qs.stringify(params)}`;
                });
        }
    }, [JSON.stringify(items)]);

    const elPhotoItem = (data: DataItemsMetadata, item_price: number, price: number, discount: number): ReactNode => {
        const isOwner = isAuth && data.photographer?.id === userId;
        const author = data.photographer;
        const photo_url = data.thumbnail_url ? data.thumbnail_url : data.photo_url;

        return (
            <Fragment>
                <a
                    target="_blank"
                    href={`/persons/photos/${data.id}?event_id=${data.event?.id}`}
                    title="Перейти на страницу фото"
                    className={cn('cart__item-link')}
                >
                    {
                        photo_url && (
                            <img
                                alt={String(data.id)}
                                src={cropImage(photo_url, '310x200')}
                                className={cn(
                                    'cart__item-image', { 'cart__image-owner': isOwner }
                                )}
                            />
                        )
                    }
                </a>
                <div className={cn('cart__item-content')}>
                    <div className={cn('cart__item-block')}>
                        {
                            isOwner && (
                                <p className={cn('cart__item-text-owner')}>
                                    Нельзя купить свое фото!
                                </p>
                            )
                        }
                        <p className={cn('cart__item-text')}>{data.event?.name}</p>
                        {
                            author && (
                                <p className={cn('cart__item-text-sub')}>
                                    {t(
                                        'route.cart.item.author',
                                        { name: `${author.last_name} ${author.first_name}`}
                                    )}
                                </p>
                            )
                        }
                        <p
                            className={cn(
                                'cart__item-text-sub',
                                'cart__item-text-sub_remove'
                            )}
                            onClick={onClickRemove(`PP_${data.id}`)}
                        >
                            {t('route.cart.item.remove')}
                        </p>
                    </div>
                    {!Boolean(price === item_price) && (
                        <div className={cn('cart__item-price')}>
                            <span className={cn('cart__item-price-new')}>{price}</span>
                            <span className={cn('cart__item-price-old')}>{item_price}</span>
                            <span>Скидка: {discount}%</span>
                        </div>
                    )}
                    {Boolean(price === item_price) && (
                        <div>
                            <span>
                                {t('route.cart.item.price', { count: price })}
                            </span>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    };

    const elPhotoAdItem = (data: DataItemsMetadata, item_price: number): ReactNode => {
        const photo_url = data.thumbnail_url ? data.thumbnail_url : data.photo_url;

        return (
            <Fragment>
                <a
                    target="_blank"
                    href={`/persons/photos/${data.photo_id}`}
                    title="Перейти на страницу фото"
                    className={cn('cart__item-link')}
                >
                    {
                        photo_url && (
                            <img
                                alt={String(data.id)}
                                src={cropImage(photo_url, '310x200')}
                                className={cn('cart__item-image')}
                            />
                        )
                    }
                </a>
                <div className={cn('cart__item-content')}>
                    <div className={cn('cart__item-block')}>
                        <p className={cn('cart__item-text')}>Баннер для фото</p>
                        <p className={cn('cart__item-text-sub')}>
                            {`Период: ${data.period} мес.`}
                        </p>
                        <p
                            className={cn(
                                'cart__item-text-sub',
                                'cart__item-text-sub_remove'
                            )}
                            onClick={onClickRemove(`AD_${data.id}`)}
                        >
                            {t('route.cart.item.remove')}
                        </p>
                    </div>
                    <div>
                            <span>
                                {t('route.cart.item.price', { count: item_price })}
                            </span>
                    </div>
                </div>
            </Fragment>
        );
    };

    const elSubscriptionItem = (data: DataItemsMetadata, item_price: number): ReactNode => {
        return (
            <Fragment>
                <div className={cn('cart__item-icon')}>
                    <FontAwesomeIcon icon={faRubleSign} />
                </div>
                <div className={cn('cart__item-content')}>
                    <div className={cn('cart__item-block')}>
                        <p className={cn('cart__item-text')}>Подписка "Предоплаченный альбом"</p>
                        <p className={cn('cart__item-text-sub')}>
                            {`Период: ${data.period} дней.`}
                        </p>
                        <p
                            className={cn(
                                'cart__item-text-sub',
                                'cart__item-text-sub_remove'
                            )}
                            onClick={onClickRemove(`APS_${data.id}`)}
                        >
                            {t('route.cart.item.remove')}
                        </p>
                    </div>
                    <div>
                            <span>
                                {t('route.cart.item.price', { count: item_price })}
                            </span>
                    </div>
                </div>
            </Fragment>
        );
    };

    const elContent = useMemo(() => {
        if (items && Object.values(items).length) {
            return (
                <div className={cn('cart__list')}>
                    {
                        Object.values(items).map((cartItem, index) => {
                            const [prefix, id] = cartItem.vendor_code.split('_');

                            if (prefix === 'PP' && cartItem.metadata) {
                                return (
                                    <div className={cn('cart__list-item')} key={index}>
                                        {elPhotoItem(cartItem.metadata, cartItem.item_price, cartItem.price, cartItem.discount)}
                                    </div>
                                );
                            }

                            if (prefix === 'AD' && cartItem.metadata) {
                                return (
                                    <div className={cn('cart__list-item')} key={index}>
                                        {elPhotoAdItem(cartItem.metadata, cartItem.item_price)}
                                    </div>
                                );
                            }

                            if (prefix === 'APS' && cartItem.metadata) {
                                return (
                                    <div className={cn('cart__list-item')} key={index}>
                                        {elSubscriptionItem(cartItem.metadata, cartItem.item_price)}
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            );
        }

        return (
            <div className={cn('cart__empty')}>{t('route.cart.content.empty')}</div>
        );
    }, [JSON.stringify(items)]);

    const elSidebar = useMemo(() => {
        const cartItems = {
            photo: {
                count: 0,
                price: 0
            },
            ad: {
                count: 0,
                price: 0
            },
            subscribe: {
                count: 0,
                price: 0
            }
        };
        let priceWithoutDiscount = 0;
        let priceWithDiscount = 0;

        Object.values(items).map((item) => {
            const [prefix, id] = item.vendor_code.split('_');

            switch (prefix) {
                case 'PP':
                    priceWithDiscount += item.price;
                    priceWithoutDiscount += item.item_price;
                    cartItems.photo.price = cartItems.photo.price + item.price;
                    cartItems.photo.count = cartItems.photo.count + 1;
                    break;
                case 'AD':
                    priceWithDiscount += item.price;
                    priceWithoutDiscount += item.item_price;
                    cartItems.ad.price = cartItems.ad.price + item.price;
                    cartItems.ad.count = cartItems.ad.count + 1;
                    break;
                case 'APS':
                    priceWithDiscount += item.price;
                    priceWithoutDiscount += item.item_price;
                    cartItems.subscribe.price = cartItems.subscribe.price + item.price;
                    cartItems.subscribe.count = cartItems.subscribe.count + 1;
                    break;
            }
        });

        priceWithoutDiscount = Math.ceil((priceWithoutDiscount) * 100) / 100;
        priceWithDiscount = Math.ceil((priceWithDiscount) * 100) / 100;

        return (
            <div className={cn('cart__sidebar')}>
                <h2 className={cn('cart__block-header')}>{t('route.cart.sidebar.header')}</h2>
                <div className={cn('cart__sidebar-items')}>
                    <div className={cn('cart__sidebar-item', 'cart__sidebar-item_border')}>
                        <span className={cn('cart__sidebar-text')}>{t('route.cart.sidebar.photo')}</span>
                        <div className={cn('cart__sidebar-text')}>{cartItems.photo.count}</div>
                    </div>
                    {/*<div className={cn('cart__sidebar-item')}>*/}
                    {/*    <span className={cn('cart__sidebar-text')}>{t('route.cart.sidebar.sum')}</span>*/}
                    {/*    <div className={cn('cart__sidebar-text')}>*/}
                    {/*        {t('route.cart.sidebar.sum-count', { count: cartItems.photo.price })}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {
                        Boolean(cartItems.ad.count) && (
                            <div className={cn('cart__sidebar-item', 'cart__sidebar-item_border')}>
                                <span className={cn('cart__sidebar-text')}>Реклама</span>
                                <div className={cn('cart__sidebar-text')}>{cartItems.ad.count}</div>
                            </div>
                        )
                    }
                    {
                        Boolean(cartItems.subscribe.count) && (
                            <div className={cn('cart__sidebar-item', 'cart__sidebar-item_border')}>
                                <span className={cn('cart__sidebar-text')}>Подписки</span>
                                <div className={cn('cart__sidebar-text')}>{cartItems.subscribe.count}</div>
                            </div>
                        )
                    }
                    <div className={cn('cart__sidebar-item')}>
                        <span className={cn('cart__sidebar-text')}>{t('route.cart.sidebar.sum')}</span>
                        {(priceWithDiscount === priceWithoutDiscount) && (
                            <div className={cn('cart__sidebar-text')}>{
                                t('route.cart.sidebar.sum-count', { count: priceWithDiscount })}
                            </div>
                        )}
                        {(priceWithDiscount !== priceWithoutDiscount) && (
                            <div>
                                <div className={cn('cart__sidebar-price-old')}>{priceWithoutDiscount}</div>
                                <div className={cn('cart__sidebar-price-new')}>{priceWithDiscount}</div>
                            </div>
                        )}
                    </div>
                    <Button
                        type="button"
                        className={cn('cart__button')}
                        onClick={onClickCreateOrder}
                        disabled={Boolean(pending || !Object.values(items).length)}
                        isLoading={pending}
                    >
                        {t('route.cart.sidebar.button')}
                    </Button>
                    <p className={cn('cart__description')}>
                        {t('route.cart.sidebar.description-1')}
                        <a
                            href={offer}
                            target="_blank"
                            className={cn('cart__link')}
                        >
                            {t('route.cart.sidebar.offer')}
                        </a>
                        {t('route.cart.sidebar.description-2')}
                    </p>
                </div>
            </div>
        );
    }, [JSON.stringify(items)]);

    const elLoader = useMemo(() => {
        if (pending) {
            return <Loader className={cn('cart__loading')} />;
        }
    }, [pending]);

    const elMessage = useMemo(() => {
        if (items) {
            return (
                <div className={cn('cart__message')}>
                    <p>
                        Заказы в корзине хранятся в течение <b>14 дней</b> с момента добавления первой фотографии.
                        По истечению данного срока корзина автоматически очищается.
                        Пожалуйста, завершите свой заказ в течение данного срока.
                    </p>
                </div>
            );
        }
    }, [JSON.stringify(items)]);

    if (!isAuth) {
        history.push('/login?from=/cart');
    }

    return (
        <UI.Main className={cn('cart')}>
            <h1 className={cn('cart__header')}>{t('route.cart.header')}</h1>
            {elMessage}
            <div className={cn('cart__grid')}>
                <div className={cn('cart__content')}>
                    <h2 className={cn('cart__block-header')}>{t('route.cart.content.header')}</h2>
                    {elContent}
                    {elLoader}
                </div>
                {elSidebar}
            </div>
        </UI.Main>
    );
};

// tslint:disable-next-line:max-file-line-count
export default Cart;
