import React, { useEffect, useMemo, useState, useCallback } from 'react';
// import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import IconCross from 'component/icon/cross';

import Error from 'component/error';
import MiniInfo from 'component/mini-info';
import ModalMerge from './modal-merge';
import { personMarkAreDifferent } from 'component/api/main';

import { IProps, TSimilarPerson } from './types';
import style from './index.pcss';

export default (props: IProps) => {
    const cn = useClassnames(style);
    // const { t } = useTranslation();
    const [tokenDelete] = useCancelTokens(1);

    const [similarPersonList, setSimilarPersonList] = useState<Array<TSimilarPerson>>(props.similar_persons || []);
    const [selectedPerson, setSelectedPerson] = useState<TSimilarPerson | null>(null);
    const [modalMerge, setModalMerge] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const _requestDeletePerson = (person_id: number) => {
        setError(null);

        personMarkAreDifferent({
            cancelToken: tokenDelete.new(),
            data: {
                persons_ids: [props.person.id, person_id]
            }
        })
            .then(() => {
                const newSimilarPersonList = similarPersonList.filter((item) => item.id !== person_id);
                setSimilarPersonList(newSimilarPersonList);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setError(err.message);
                }
            });
    };

    useEffect(() => {
        return () => {
            tokenDelete.remove();
        };
    }, []);

    const onClickClose = useCallback(() => {
        setModalMerge(false);
        setSelectedPerson(null);
    }, []);

    const elModalMerge = useMemo(() => {
        if (modalMerge && selectedPerson && props.person) {
            return (
                <ModalMerge
                    person={props.person}
                    selectedPerson={selectedPerson}
                    onClickClose={onClickClose}
                />
            );
        }
    }, [modalMerge, selectedPerson]);

    const onSelectPerson = (person: TSimilarPerson) => () => {
        setSelectedPerson(person);
        setModalMerge(true);
    };

    const onDeletePerson = (person_id: number) => () => {
        _requestDeletePerson(person_id);
    };

    const elPersons = useMemo(() => {
        return (
            <div className={cn('similar-person__persons')}>
                <div className={cn('similar-person__persons-title')}>похожие персоны</div>
                <div className={cn('similar-person__persons-list')}>
                    {similarPersonList.map((item, i) => {
                        return (
                            <div key={i} className={cn('similar-person__persons-item')}>
                                <MiniInfo
                                    key={item.id}
                                    // className={cn('person-edit__list-item')}
                                    avatar={{
                                        imgSrc: item.photo_url
                                    }}
                                    onClick={onSelectPerson(item)}
                                />
                                <IconCross
                                    className={cn('similar-person__persons-button')}
                                    title="Нажмите если это не ваша персона"
                                    onClick={onDeletePerson(item.id)}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [similarPersonList]);

    const elError = useMemo(() => {
        if (error) {
            return <Error>{error}</Error>;
        }
    }, [error]);

    if (similarPersonList.length) {
        return (
            <div>
                {elPersons}
                {elError}
                {elModalMerge}
            </div>
        );
    }

    return null;
};
