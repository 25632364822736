import React, { useCallback, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import cancelToken, { IReturnInterface, useCancelTokens } from 'component/core/cancel-token';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';

import { useClassnames } from 'hook/use-classnames';
import Modal from 'component/modal';
import Button from 'component/button';
import Loader from 'component/loader';
import ArrowDirDown from 'component/icon/arrow-dir-down';
import Directory from 'component/icon/directory';
import ImagesIcon from 'component/icon/images';

import { userSelfInfo } from 'component/api/user';
import { set } from 'component/user/actions';
import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';
import {
    yandexOauthInit,
    getYandexDiskList,
    getYandexDownloadEstimate,
    createYandexDownloadJob,
    yandexRevokeToken,
} from 'component/api/download-clouds';
import { yandexPhotoCreate } from 'src/api/cloud';
import { IPropsExternal, IDataItemExternal } from './types';
import { Data as ListData } from 'component/api/types/api/download-clouds/get-list/get/code-200';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import style from './index.pcss';
import Presets from './presets';
import { useRegistry } from '..';
import ModalMessageSetPhone from 'component/modal/message-set-phone';

const searchTree = (nodes: Array<IDataItemExternal>, searchPath: string): IDataItemExternal | null => {
    for (const element of nodes) {
        if(element.value === searchPath) {
            return element;
        } else if (element.children?.length) {
            let i;
            let result = null;
            for(i = 0; result === null && i < element.children.length; i++) {
                // @ts-ignore
                result = searchTree(element.children[i], searchPath);
            }

            return result;
        }

        return null;
    }

    return null;
};

const ModalExternal = (props: IPropsExternal) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const registry = useRegistry();
    const [tokenAuthInit, tokenAuthExit, tokenList, tokenEstimate, tokenCreateDownload, tokenUserSelfInfo] = useCancelTokens(6);
    const tokens = useRef<Array<IReturnInterface>>([]);

    const yandexAuthPresent = useSelector<IStore, boolean>((store) => store[keyUser].yandex_auth_present || false);
    const userPhone = useSelector<IStore, string | null>((store) => store[keyUser].fps_phone_number || null);
    const moderationPhone = useSelector<IStore, string | null>((store) => store[keyUser]?.moderation_user?.fps_phone_number || null);

    const [checked, setChecked] = useState<Array<string>>([]);
    const [expanded, setExpanded] = useState<Array<string>>([]);
    const [list, setList] = useState<Array<IDataItemExternal>>([]);
    const [pending, setPending] = useState<boolean>(false);
    const [pendingEstimate, setPendingEstimate] = useState<boolean>(false);
    const [estimate, setEstimate] = useState<boolean>(false);
    const [create, setCreate] = useState<boolean>(false);
    const [totalFiles, setTotalFiles] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [modalPrice, setModalPrice] = useState<boolean>(false);

    const _requestInit = (): void => {
        setPending(true);

        const apiInit = yandexOauthInit;

        apiInit({
            cancelToken: tokenAuthInit.new()
        })
            .then((resp) => {
                window.location.href = resp.redirect_url;
            })
            .catch((err) => {
                console.warn(err);
                setPending(false);
            });
    };

    const _requestExit = (): void => {
        setPending(true);
        setError(null);

        const apiExit = yandexRevokeToken;

        apiExit({
            cancelToken: tokenAuthExit.new()
        })
            .then((resp) => {
                return userSelfInfo({
                    cancelToken: tokenUserSelfInfo.new()
                });
            })
            .then((payload) => {
                dispatch(set(payload));

                if (props.onCloseModal) {
                    props.onCloseModal();
                }
            })
            .catch((err) => {
                console.warn(err);
                setError('Ошибка выхода из диска. Повторите попытку позже.');
                setPending(false);
            });
    };

    const _requestList = (path: string): void => {
        setPending(true);
        setError(null);

        const apiList = getYandexDiskList;

        apiList({
            params: {
                item_type: 'FOLDER',
                path
            },
            cancelToken: tokenList.new()
        })
            .then((resp) => {
                const newList = resp.items.map((item) => ({
                    value: item.path || '',
                    label: item.name || ''
                }));

                setPending(false);
                checkNesting(newList);
            })
            .catch((err) => {
                console.warn(err);
                setError('Не удалось загрузить список каталогов');
                setPending(false);
            });
    };

    const checkNesting = (data: Array<IDataItemExternal>, node: string | null = null) => {
        setPending(true);

        const promises: Array<Promise<ListData>> = [];
        const apiList = getYandexDiskList;

        data.forEach((item, index) => {
            tokens.current[index] = cancelToken.create();

            promises.push(new Promise((resolve, reject) => {
                apiList({
                    params: {
                        item_type: 'FOLDER',
                        path: item.value,
                        parent_id: item.value
                    },
                    cancelToken: tokens.current[index].new()
                })
                    .then((resp) => {
                        const newList = resp.items.map((itemList) => ({
                            value: itemList.path || '',
                            label: itemList.name || ''
                        }));

                        if (newList.length) {
                            item.children = newList;
                        }

                        resolve(resp);

                        tokens.current[index].remove();

                        // tslint:disable-next-line no-dynamic-delete
                        delete tokens.current[index];
                    })
                    .catch((err) => {
                        console.warn(err);
                        reject(index);
                    });

            }));
        });

        Promise.all(promises.map((promise) => {
            return promise
                .then((response) => ({
                    status: 'fulfilled',
                    response
                }))
                .catch((response) => ({
                    status: 'rejected',
                    response
                }));
        })).then((responses) => {
            if (node) {
                const newList = [...list];
                const res = searchTree(newList, node);
                if (res) {
                    res.children = data;
                    setList(newList);
                }
            } else {
                setList(data);
            }

            setPending(false);
        });
    };

    useEffect(() => {
        if (props.service === 'yandex') {
            !yandexAuthPresent ? _requestInit() : _requestList('/');
        }

        return () => {
            tokenAuthInit.remove();
            tokenList.remove();
        };
    }, []);

    // const _requestCreateDownload = (): void => {
    //     setPending(true);
    //     setError(null);

    //     const apiCreateDownload = createYandexDownloadJob;

    //     apiCreateDownload({
    //         data: {
    //             paths: checked.map((item) => item),
    //             resource_ids: checked.map((item) => item)
    //         },
    //         cancelToken: tokenCreateDownload.new()
    //     })
    //         .then((resp) => {
    //             setCreate(true);
    //             setPending(false);
    //         })
    //         .catch((err) => {
    //             console.warn(err);
    //             setError('Ошибка обработки фотографий. Повторите попытку позже.');
    //             setPending(false);
    //         });
    // };

    const _requestEstimate = (): void => {
        setPendingEstimate(true);
        setError(null);

        const apiEstimate = getYandexDownloadEstimate;

        apiEstimate({
            params: {
                paths: checked.map((item) => item),
                resource_ids: checked.map((item) => item)
            },
            cancelToken: tokenEstimate.new()
        })
            .then((resp) => {
                setTotalFiles(resp.total_files);
                if (resp.total_files > 0) {
                    setEstimate(true);
                }
                setPendingEstimate(false);
            })
            .catch((err) => {
                console.warn(err);
                setError('Ошибка обработки фотографий. Повторите попытку позже.');
                setPendingEstimate(false);
            });
    };

    const onExit = useCallback(() => {
        _requestExit();
    }, []);

    // const onClickDownload = useCallback(() => {
    //     _requestCreateDownload();
    // }, [checked]);

    const onCheck = useCallback((arr) => {
        setChecked(arr);
    }, []);

    const onExpanded = useCallback((arrExpanded: Array<string>, targetNode: IDataItemExternal) => {
        if (targetNode?.children?.length) {
            checkNesting(targetNode.children, targetNode.value);
        }
        setExpanded(arrExpanded);
    }, []);

    const onClickContinue = useCallback(() => {
        setTotalFiles(null);
        _requestEstimate();
    }, [checked]);

    const elCount = useMemo(() => {
        return (
            <div className={cn('input-external__count')}>
                Выбрано каталогов: {checked.length}
            </div>
        );
    }, [checked]);

    const submitFiles = (is_params: boolean) => (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        const presets = getUploadPresets();
        const apiYandexPhotoCreate = yandexPhotoCreate;
        let photo_data;
        let paramsValid = true;

        if (is_params) {
            if (presets.year && !presets.year.value?.value) {
                presets.year.setError('Укажите год');
                paramsValid = false;
            }

            if (presets.event && !presets.event.value?.value) {
                presets.event.setError('Укажите событие');
                paramsValid = false;
            }

            if (presets.price && (!presets.price?.value || Number(presets.price?.value) < 20)) {
                presets.price.setError('Минимальная стоимость 20 руб.');
                paramsValid = false;
            }

            if (presets.album && !presets.album?.value?.value) {
                presets.album.setError('Укажите альбом');
                paramsValid = false;
            }

            if (!paramsValid) {
                return void(0);
            }

            if (!userPhone || moderationPhone) {
                    setModalPrice(true);

                    return void(0);
            }
        }

        if (is_params) {
            Number(presets?.album?.value?.value) === 0 ?
            photo_data = {
                price: Number(presets?.price?.value),
                event_id: Number(presets?.event?.value?.value)
            } :
            photo_data = {
                price: Number(presets?.price?.value),
                event_id: Number(presets?.event?.value?.value),
                album_id: Number(presets?.album?.value?.value)
            };
        }

        setPending(true);
        setError(null);

        apiYandexPhotoCreate(
            checked.map((item) => item),
            photo_data
            )
            .then((resp) => {
                setCreate(true);
                setPending(false);
            })
            .catch((err) => {
                console.warn(err);
                setError('Ошибка обработки фотографий. Повторите попытку позже.');
                setPending(false);
            });
    };

    const onClickCloseMessage = (): void => {
        setModalPrice(false);
    };

    const elModalMessageSetPhone = useMemo(() => {
        if (modalPrice) {
            return <ModalMessageSetPhone onClickClose={onClickCloseMessage} />;
        }
    }, [modalPrice]);

    const elTree = useMemo(() => {
        if (pending) {
            return (
                <Loader
                    theme="pink"
                    text="Загрузка каталогов..."
                />
            );
        }

        if (pendingEstimate) {
            return (
                <Loader
                    theme="pink"
                    text="Подсчет фотографий..."
                />
            );
        }

        if (create) {
            return (
                <div className={cn('input-external__wrapper-images')}>
                    <div>Фотографии загружаются...</div>
                    <div>Через некоторое время Вы можете просмотреть их в разделе "Мои фотографии"</div>
                </div>
            );
        }

        if (totalFiles && totalFiles > 0) {
            return (
                <div className={cn('input-external__wrapper-images')}>
                    <ImagesIcon className={cn('input__icon', 'input__icon_images')} />
                    Найдено Фотографий: {totalFiles}
                </div>
            );
        }

        return (
            // @ts-ignore
            <CheckboxTree
                icons={{
                    check: <div className={cn('input-external__checkbox-inner', 'input-external__checkbox-inner_checked')} />,
                    uncheck: <div className={cn('input-external__checkbox-inner')} />,
                    halfCheck: <div className={cn('input-external__checkbox-inner', 'input-external__checkbox-inner_half')} />,
                    expandClose: <ArrowDirDown className={cn('input-external__icon-rotate')} />,
                    expandOpen: <ArrowDirDown />,
                    parentClose: <Directory className={cn('input-external__icons')} />,
                    parentOpen: <Directory className={cn('input-external__icons')} />,
                    leaf: <Directory className={cn('input-external__icons')} />
                }}
                nodes={list}
                checked={checked}
                expanded={expanded}
                onCheck={onCheck}
                // @ts-ignore
                onExpand={onExpanded}
            />
        );
    }, [
        JSON.stringify(list),
        JSON.stringify(checked),
        JSON.stringify(expanded),
        pending, pendingEstimate,
        estimate, create, totalFiles
    ]);

    const elParams = useMemo(() => {
        if (estimate) {
            return(
                <Presets registry={registry} />
            );
        }
    }, [estimate]);

    const elError = useMemo(() => {
        const message = error
            ? error
            : totalFiles === 0 ? 'В выбранных каталогах нет фотографий для загрузки!' : null;

        if (message) {
            return (
                <div className={cn('input-external__error')}>
                    {message}
                </div>
            );
        }
    }, [totalFiles, error]);

    const elButtonNext = useMemo(() => {
        if (create) {
            return(
                <Button
                    type="button"
                    isSmall={true}
                    disabled={pending || pendingEstimate}
                    onClick={props.onCloseModal}
                >
                    Закрыть
                </Button>
            );
        }

        if (estimate) {
            return(
                <div className={cn('input-external__buttons-params')}>
                    <Button
                        type="button"
                        isSmall={true}
                        disabled={pending || pendingEstimate || !totalFiles || !!error}
                        onClick={submitFiles(false)}
                    >
                        Начать загрузку без параметров
                    </Button>
                    <Button
                        type="button"
                        isSmall={true}
                        disabled={pending || pendingEstimate || !totalFiles || !!error}
                        onClick={submitFiles(true)}
                    >
                        Начать загрузку с параметрами
                    </Button>
                </div>
            );
        }

        return(
            <Button
                type="button"
                isSmall={true}
                disabled={pending || pendingEstimate || !!error}
                onClick={onClickContinue}
            >
                Продолжить
            </Button>
        );
    }, [
        JSON.stringify(list),
        JSON.stringify(checked),
        JSON.stringify(expanded),
        pending, pendingEstimate,
        estimate, create, totalFiles,
        error
    ]);

    const getUploadPresets = () => {
        const fields = registry.form.getFields();

        return {
            year: fields && fields.get('upload_year') || null,
            event: fields && fields.get('upload_tournament') || null,
            price: fields && fields.get('upload_price') || null,
            album: fields && fields.get('upload_album') || null
        };
    };

    return (
        <Modal onClickClose={props.onCloseModal}>
            <h3 className={cn('input-external__header')}>Выберите каталог для импорта</h3>
            {elError}
            <div
                className={cn('input-external__wrapper', {
                    'input-external__wrapper-center': pending || pendingEstimate || create || estimate
                })}
            >
                {elTree}
            </div>
            {elModalMessageSetPhone}
            {elCount}
            {elParams}
            <div className={cn('input-external__footer')}>
                <Button
                    type="button"
                    className={cn('input-external__button-exit')}
                    isSmall={true}
                    isSecondary={true}
                    disabled={pending || pendingEstimate}
                    onClick={onExit}
                >
                    Выйти из диска
                </Button>
                <div className={cn('input-external__buttons')}>
                    <Button
                        type="button"
                        className={cn('input-external__button-cancel')}
                        isSmall={true}
                        isSecondary={true}
                        disabled={pending || pendingEstimate}
                        onClick={props.onCloseModal}
                    >
                        Отмена
                    </Button>
                    {elButtonNext}
                </div>
            </div>
        </Modal>
    );
};

// tslint:disable-next-line:max-file-line-count
export default ModalExternal;
