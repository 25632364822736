import { AxiosRequestConfig } from 'axios';

import { Data as AuthData } from 'component/api/types/api/download-clouds/oauth-init/get/code-200';
import { Data as ListData } from 'component/api/types/api/download-clouds/get-list/get/code-200';
import { Data as EstimateData } from 'component/api/types/api/download-clouds/estimate/get/code-200';
import { Data as CreateDownloadData } from 'component/api/types/api/download-clouds/download/create/get/code-200';

import { request } from './';

export const yandexOauthInit = (config?: AxiosRequestConfig) => {
    return request<AuthData>({
        url   : '/api/download-clouds/yandex/oauth/init/',
        ...config
    });
};

export const getYandexDiskList = (config?: AxiosRequestConfig) => {
    return request<ListData>({
        url   : '/api/download-clouds/yandex/disk/list/',
        ...config
    });
};

export const getYandexDownloadEstimate = (config?: AxiosRequestConfig) => {
    return request<EstimateData>({
        url   : '/api/download-clouds/download-job/yandex/estimate/',
        method: 'get',
        ...config
    });
};

export const createYandexDownloadJob = (config?: AxiosRequestConfig) => {
    return request<CreateDownloadData>({
        url   : '/api/download-clouds/download-job/yandex/create/',
        method: 'post',
        ...config
    });
};

export const yandexRevokeToken = (config?: AxiosRequestConfig) => {
    return request<AuthData>({
        url   : '/api/download-clouds/yandex/oauth/revoke-token/',
        ...config
    });
};
