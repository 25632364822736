import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClassnames } from 'hook/use-classnames';

import UI from 'component/ui';
import InputEvents from 'component/form/input-events/index-tsx-async';
import InputAlbums from 'component/form/input-albums';
import InputYears from 'component/form/input-years';
import InputForm from 'component/form/input';

import { IValue } from 'component/form/input-years/types';
import { IPresetProps } from './types';
import style from './index.pcss';
import { Link } from 'react-router-dom';

const Presets = (props: IPresetProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();

    const [year, setYear] = useState<number>(0);

    const onChangeYear = (value: IValue): void => {
        if (value) {
            setYear(Number(value?.value));
        }
    };

    const priceCheckValidity = () => {
        const fields = props.registry.form.getFields();

        if(fields) {
            const price = fields.get('upload_price');

            if (price) {
                if (Number(price.value) < 20) {
                    return {
                        isValid: false,
                        error: 'Минимальная стоимость 20 руб.'
                    };
                }

                return {
                    isValid: true,
                    error: ''
                };
            }
        }
    };

    const elTournament = useMemo(() => {
        return (
            <InputEvents
                registry={props.registry.field}
                name="upload_tournament"
                clearable={true}
                excludeEmpty={false}
                year={year}
                children="Событие"
                direction="column"
                disabled={!Boolean(year)}
            />
        );
    }, [year]);

    return (
        <div className={cn('input__sidebar')}>
            <UI.BoxHeader>Параметры загрузки фото</UI.BoxHeader>
            <InputYears
                registry={props.registry.field}
                name="upload_year"
                clearable={true}
                children="Год"
                direction="column"
                onChange={onChangeYear}
            />
            {elTournament}
            <InputForm
                className={cn('input__sidebar-price')}
                registry={props.registry.field}
                name="upload_price"
                children="Стоимость"
                onlyNumbers={true}
                checkValidity={priceCheckValidity}
            />
            <InputAlbums
                registry={props.registry.field}
                name="upload_album"
                children="Альбом"
                direction="column"
            />
            <span>Вы можете создать альбом в разделе <Link to={'/dashboard/photos'}>"Мои фотографии"</Link></span>
        </div>
    );
};

export default Presets;
