import { AxiosRequestConfig } from 'axios';
import { request } from 'component/api/index';
import { Data as UserData } from 'component/api/types/api/account/user-info/get/code-200';

export const getUserInfo = (config?: AxiosRequestConfig) => {
    return request<UserData>({
        url: '/api/account/user-info/',
        ...config
    });
};

export const getSelfUserInfo = (config?: AxiosRequestConfig) => {
    return request<UserData>({
        url: '/api/account/self-user-info/',
        ...config
    });
};
