import { AxiosResponse } from 'axios';
import { AuthAPIType, AuthYandexCreate, TokenCreate } from './types';
import httpClient from 'src/utils/http-сlient';

export const authTokenCreate = (
    email: string,
    password: string
): Promise<AxiosResponse<TokenCreate>> => {
    return httpClient.post<TokenCreate>('/api/v2/auth/token/', {
        email,
        password
    });
};

export const authTokenRefreshCreate = (
    refresh: string
): Promise<AxiosResponse<TokenCreate>> => {
    return httpClient.post<TokenCreate>('/api/v2/auth/token/refresh/', {
        refresh
    });
};

export const authYandexCreate = (
    access_token: string
): Promise<AxiosResponse<AuthYandexCreate>> => {
    return httpClient.post<AuthYandexCreate>('/api/v2/auth/yandex/', {
        access_token
    });
};

export default {
    authTokenCreate,
    authTokenRefreshCreate,
    authYandexCreate
} as AuthAPIType;
