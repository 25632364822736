import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useClassnames from 'hook/use-classnames';

import { IProps } from './types';
import style from './styles.pcss';
import Form from 'component/form';
import InputAdThemeSelect from 'component/form/input-ad-theme';
import InputSelect from 'component/form/input-select';
import Button from 'component/button';
import InputBannerSelect from 'component/form/input-banners';
import { ValueType } from 'react-select/src/types';
import { IValue } from 'component/form/input-banners/types';
import { createPhotoBanner } from 'component/api/banners';
import { useCancelToken } from 'component/core/cancel-token';
import axios from 'axios';
import { IPayload } from 'component/form/types';
// import { addItem } from 'route/cart/actions';
import { useDispatch } from 'react-redux';
import { createPhotoAdCartItem } from 'component/api/cart';
import { addCart } from 'route/cart/actions';

const PRICE_PER_MONTH = 10;

const PhotoAdForm: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);
    const { t } = useTranslation();
    const token = useCancelToken();
    const dispatch = useDispatch();
    const [themeId, setThemeId] = useState<number>(0);
    const [pending, setPending] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);

    const PERIODS = [
        { label: t('components.form.banner.form.photo_ad.month', {count: 1}), value: '1' },
        { label: t('components.form.banner.form.photo_ad.months-1', {count: 2}), value: '2' },
        { label: t('components.form.banner.form.photo_ad.months-1', {count: 3}), value: '3' },
        { label: t('components.form.banner.form.photo_ad.months-1', {count: 4}), value: '4' },
        { label: t('components.form.banner.form.photo_ad.month-2', {count: 5}), value: '5' },
        { label: t('components.form.banner.form.photo_ad.month-2', {count: 6}), value: '6' },
        { label: t('components.form.banner.form.photo_ad.month-2', {count: 7}), value: '7' },
        { label: t('components.form.banner.form.photo_ad.month-2', {count: 8}), value: '8' },
        { label: t('components.form.banner.form.photo_ad.month-2', {count: 9}), value: '9' },
        { label: t('components.form.banner.form.photo_ad.month-2', {count: 10}), value: '10' },
        { label: t('components.form.banner.form.photo_ad.month-2', {count: 11}), value: '11' },
        { label: t('components.form.banner.form.photo_ad.month-2', {count: 12}), value: '12' }
    ];

    const _request = useCallback((payload: IPayload) => {
        setPending(true);

        const data = {
            ...(props.photoId && {'photo_id': props.photoId}),
            ...(payload.banner && {'banner_id': payload.banner.value}),
            ...(payload.period && {'period': payload.period.value})
        };

        createPhotoAdCartItem({
            cancelToken: token.new(),
            data
        }).then((resp) => {
            dispatch(addCart(resp));
            setPending(false);

            if (props.onSubmit) {
                props.onSubmit(payload);
            }
        }).catch((err) => {
            if(!axios.isCancel(err)) {
                console.error(err);
                setPending(false);
            }
        });
    }, []);

    const onChangeTheme = (value: ValueType<IValue>): void => {
        if (value && 'value' in value) {
            setThemeId(Number(value.value));
        }
    };

    const elBannerSelect = useMemo(() => {
        const isDisable = !Boolean(themeId);

        return (
            <InputBannerSelect
                registry={props.registry.field}
                themeId={themeId}
                name="banner"
                required={true}
                children={t('components.form.banner.form.inputs.banner')}
                direction={'column'}
                disabled={isDisable}
            />
        );
    }, [themeId, props.registry]);

    const onSubmit = (payload: IPayload): void => {
        _request(payload);
    };

    const onChangeValidity = useCallback((status: boolean) => {
        setIsValid(status);
    }, []);

    return (
        <Form registry={props.registry.form} className={cn('form')} onSubmit={onSubmit} onChangeValidity={onChangeValidity}>
            <InputAdThemeSelect
                registry={props.registry.field}
                name="theme"
                required={true}
                children={t('components.form.banner.form.inputs.theme')}
                direction={'column'}
                onChange={onChangeTheme}
            />
            {elBannerSelect}
            <InputSelect
                registry={props.registry.field}
                name="period"
                required={true}
                children={t('components.form.banner.form.inputs.period')}
                direction={'column'}
                options={PERIODS}
            />
            <div className={cn('form__info')}>
                <p>
                    <b>{t('components.form.banner.form.photo_ad.message-1')}</b>
                    <span>
                        {PRICE_PER_MONTH}
                    </span>
                    {t('components.form.banner.form.photo_ad.message-2')}
                </p>
                <div className={cn('form__info-link')}>
                    <Link to={'/dashboard/banners'}>{t('components.form.banner.form.photo_ad.link')}</Link>
                </div>
            </div>
            <div className={cn('form__btn')}>
                <Button type="submit" isLoading={pending} disabled={!isValid}>
                    {t('components.form.banner.form.buttons.add-to-cart')}
                </Button>
            </div>
        </Form>
    );
};

export default PhotoAdForm;
