import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserPlus,
    faPenSquare,
    faWindowClose,
    faEdit,
    faTrashAlt,
    faPlusSquare
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { bulkEditPhoto, getAlbumPhotoList } from 'component/api/photo';
import { IValue } from 'component/form/input-clubs/types';
import { useCancelTokens } from 'component/core/cancel-token';
import { ISelectedMap } from 'route/dashboard/photos/types';
import { Redirect } from 'react-router-dom';
import { IStore } from 'src/types/reducers';
import { key as keyDeviceInfo } from 'component/device-info/reducer';
import { key as keyUser } from 'component/user/reducer';
import UI from 'component/ui';
import Button from 'component/button';
import { useRegistry } from 'component/form';
import { useClassnames } from 'hook/use-classnames';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { deleteAlbum, editAlbum, getAlbumById } from 'component/api/album';
import { DataAlbumsItem } from 'component/api/types/api/photo/album/get-album-list/get/code-200';
import Modal from 'component/modal';
import AlbumModal from 'component/modal/album';
import AlbumAccess from 'component/modal/album-access';
import SideAction from 'component/wireframes/side-action';
import ButtonCircular from 'component/button/circular';
import ActionButton from 'component/button/circular/action-button';
import { updateUserInfo, userSelfInfo } from 'component/api/user';
import { set } from 'component/user/actions';
import { useAlert } from 'component/alert/provider';
import {
    DataFilesTmpFacesItem,
    DataFilesTmpFacesPersonsItem
} from 'component/api/types/api/tmp-file/get-files-list/get/code-200';

import style from './index.pcss';
import { IPayload } from 'component/form/types';
import { sendModerationPhotos } from 'component/api/moderation';
import PhotoList from 'component/photo-list';
import sortPhotoArray, { ISortedMap } from 'component/helper/sort-photos-list';
import { DataPhotoListItem } from 'component/api/types/api/photo/get-own-photo-list/get/code-200';
import Loader from 'component/loader';
import PhotoSaleModal from 'component/modal/sale';
import TarifForm from 'route/album/tarif-form';
import { createAlbumPaidSubscriptionCartItem } from 'component/api/cart';
import { addCart } from 'route/cart/actions';

const PHOTOS_LIMIT = 30;

const Album = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { field, form } = useRegistry();
    const { id }: { id: string } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { show } = useAlert();

    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const isMobile = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].tablet);

    const [pendingWithFace, setPendingWithFace] = useState<boolean>(true);
    const [pendingWithoutFace, setPendingWithoutFace] = useState<boolean>(true);
    const [pendingBeforeWithFace, setPendingBeforeWithFace] = useState<boolean>(false);
    const [pendingBeforeWithoutFace, setPendingBeforeWithoutFace] = useState<boolean>(false);
    const [photoWithFaceMap, setPhotoWithFaceMap] = useState<ISortedMap | null>(null);
    const [photoWithoutFaceMap, setPhotoWithoutFaceMap] = useState<ISortedMap | null>(null);
    const [photoWithFace, setPhotoWithFace] = useState<Array<DataPhotoListItem>>([]);
    const [photoWithoutFace, setPhotoWithoutFace] = useState<Array<DataPhotoListItem>>([]);
    const [totalWithFace, setTotalWithFace] = useState<number>(0);
    const [totalWithoutFace, setTotalWithoutFace] = useState<number>(0);

    const [idsWithFaces, setIdsWithFaces] = useState<ISelectedMap>({});
    const [idsWithoutFaces, setIdsWithoutFaces] = useState<ISelectedMap>({});
    const refIdsWithFaces = useRef<ISelectedMap>(idsWithFaces);
    const refIdsWithoutFaces = useRef<ISelectedMap>(idsWithoutFaces);
    const [isModerationBtn, setIsModerationBtn] = useState<boolean>(false);
    const [idsList, setIdsList] = useState<Array<number>>([]);

    const [validity, setValidity] = useState<boolean>(false);
    const [token, tokenWithFace, tokenWithoutFace, tokenAlbum, tokenEdit] = useCancelTokens(5);
    const [album, setAlbum] = useState<DataAlbumsItem | null>(null);
    const [pendingAlbum, setPendingAlbum] = useState<boolean>(true);
    const [resetIdsList, setResetIdsList] = useState<boolean>(false);
    const refReset = useRef<boolean>(resetIdsList);

    const registry = useRegistry();
    const [cancelToken, tokenEditUser, tokenUserSelfInfo] = useCancelTokens(3);
    const [pending, setPending] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [modalForm, setModalForm] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<boolean>(false);
    const [modalAccess, setModalAccess] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [tarifModal, setTarifModal] = useState<boolean>(false);
    const tarifRegistry = useRegistry();

    const [redirect, setRedirect] = useState<boolean>(false);

    if(!userId) {
        return <Redirect to="/login" />;
    }

    const _requestAlbum = useCallback(() => {
        setPendingAlbum(true);

        getAlbumById({
            cancelToken: tokenAlbum.new(),
            params     : {
                album_id: id
            }
        })
            .then((payload) => {
                setAlbum(payload);

                setPendingAlbum(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingAlbum(false);
                }

                history.push('/404');
            });
    }, []);

    const isEditRight = (): boolean => {
        return Boolean(album?.user_rights.find((el) => el === 'WRITE'));
    };

    const updPhotoInfo = useCallback((photo_data) => {
        bulkEditPhoto({
            cancelToken: tokenEdit.new(),
            data       : {
                photo_ids: idsList,
                album_id: id,
                photo_data
            }
        })
            .then(() => {
                if ('album_id' in photo_data || 'is_deleted' in photo_data) {
                    setResetIdsList(true);
                    setIdsList([]);
                }

                _requestPhotoWithFace();
                _requestPhotoWithoutFace();

                show('Ваши изменения успешно применены.');
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    show('Не удалось применить Ваши изменения.', 'warning');
                }
            });
    }, [idsList]);

    const onChangeForm = debounce(useCallback(() => {
        if(form.checkValidity()) {
            _requestPhotoWithFace();
            _requestPhotoWithoutFace();
        }
    }, [validity]), 300);

    const onSubmit = useCallback(() => {
        setPending(true);
        const fields = registry.form.getFields();

        if (fields) {
            const fieldName = fields.get('album_name');
            const fieldDescription = fields.get('album_description');
            const fieldPrivate = fields.get('album_private');
            const fieldPrepaid = fields.get('album_prepaid');
            const downloadCount = fields.get('download_count');

            if (fieldName && fieldDescription) {

                if (fieldName.value === t('global.reserved-names.my-photo')) {
                    setError(t('global.massage.reserved-name'));
                    setPending(false);
                } else {
                    editAlbum({
                        cancelToken: cancelToken.new(),
                        data: {
                            album_id: id,
                            album_data: {
                                name: String(fieldName.value),
                                download_count: Number(downloadCount?.value),
                                description: String(fieldDescription.value) ? fieldDescription.value : null,
                                is_private: fieldPrivate?.value,
                                is_prepaid: fieldPrepaid?.value
                            }
                        }
                    })
                        .then((resp) => {
                            setMessage(t('route.album.modal.message-ok'));
                            setModalForm(false);
                            setPending(false);
                            _requestAlbum();
                            show('Ваши изменения успешно применены.');

                            if (fieldPrepaid?.value && !resp.is_active_subscription) {
                                setTarifModal(true);
                            }
                        })
                        .catch((err) => {
                            if(!axios.isCancel(err)) {
                                setPending(false);
                                show('Не удалось применить Ваши изменения.', 'warning');
                            }
                        });
                }
            }
        }
    }, [modalForm]);

    const onClickModal = useCallback(() => {
        setModalForm(true);
    }, [modalForm, modalMessage]);

    const onClickCloseModal = (): void => {
        setModalForm(false);
        setModalAccess(false);
    };

    const elModal = useMemo(() => {
        if (modalForm && album) {
            return (
                <AlbumModal
                    title={t('route.album.modal.title')}
                    album={album}
                    registry={registry}
                    onClickClose={onClickCloseModal}
                    onSubmitClick={onSubmit}
                />
            );
        }
    }, [error, modalForm]);

    const elModalAccess = useMemo(() => {
        if (modalAccess && album) {
            return (
                <AlbumAccess
                    albumId={album.id}
                    onClickClose={onClickCloseModal}
                />
            );
        }
    }, [modalAccess]);

    const elTarifModal = useMemo(() => {
        if (tarifModal && !album?.is_active_subscription) {
            const closeModal = () => setTarifModal(false);

            return (
                <Modal onClickClose={closeModal}>
                    <TarifForm
                        registry={tarifRegistry}
                        albumId={id}
                        onSubmit={closeModal}
                    />
                </Modal>
            );
        }
    }, [tarifModal]);

    const onClickDelete = (): void => {
        if (totalWithFace || totalWithoutFace) {
            setMessage(t('route.album.message.del-error'));
            setModalMessage(true);
        } else {
            deleteAlbum({
                cancelToken: cancelToken.new(),
                data       : { album_id: id }
            })
                .then(() => {
                    setRedirect(true);
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                    }
                });
        }
    };

    const onClickAccess = (): void => {
        setModalAccess(true);
    };

    const elButtonAccess = useMemo(() => {
        if (album && album.is_private) {
            return (
                <Button
                    isSecondary={true}
                    className={cn('album__sidebar_button')}
                    onClick={onClickAccess}
                >
                    <div className={cn('album__sidebar_button-text')}>
                        {t('route.album.button-album-access')}
                    </div>
                </Button>
            );
        }
    }, [album]);

    const elSidebarButton = useMemo(() => {
        if (isEditRight()) {
            return (
                <UI.Box padding={true} className={cn('album__sidebar_box')}>
                    {Boolean(album?.owner?.id === userId) && (
                        <Fragment>
                            <Button
                                to="/upload"
                                className={cn('album__sidebar_button')}
                            >
                                <div className={cn('album__sidebar_button-icon')}>
                                    <FontAwesomeIcon icon={faPlusSquare} />
                                </div>
                                <div className={cn('album__sidebar_button-text')}>
                                    {t('global.button.photo-upload')}
                                </div>
                            </Button>
                            <Button
                                isSecondary={true}
                                className={cn('album__sidebar_button')}
                                onClick={onClickModal}
                            >
                                <div className={cn('album__sidebar_button-icon')}>
                                    <FontAwesomeIcon icon={faPenSquare} />
                                </div>
                                <div className={cn('album__sidebar_button-text')}>
                                    {t('route.album.button-album-update')}
                                </div>
                            </Button>
                            <Button
                                isSecondary={true}
                                className={cn('album__sidebar_button')}
                                onClick={onClickDelete}
                            >
                                <div className={cn('album__sidebar_button-icon')}>
                                    <FontAwesomeIcon icon={faWindowClose} />
                                </div>
                                <div className={cn('album__sidebar_button-text')}>
                                    {t('route.album.button-album-delete')}
                                </div>
                            </Button>
                            {
                                !album?.is_active_subscription && album?.is_prepaid && (
                                    <Button
                                        isSecondary={true}
                                        className={cn('album__sidebar_button')}
                                        onClick={() => setTarifModal(true)}
                                    >
                                        <div className={cn('album__sidebar_button-icon')}>
                                            <FontAwesomeIcon icon={faPenSquare} />
                                        </div>
                                        <div className={cn('album__sidebar_button-text')}>
                                            Оплатить
                                        </div>
                                    </Button>
                                )
                            }
                        </Fragment>
                    )}
                    <Button
                        className={cn('album__sidebar_button')}
                        onClick={() => setPhotoSaleModal(true)}
                    >
                        Скидки
                    </Button>
                    {elButtonAccess}
                </UI.Box>
            );
        }
    }, [album]);

    const elSettingsBtn = useMemo(() => {
        if (isMobile || isTablet) {
            return (
                <ActionButton
                    className={cn('album__btn-setting')}
                >
                    <ButtonCircular
                        text={'Редактировать альбом'}
                        isSmall={true}
                        onClick={onClickModal}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </ButtonCircular>
                    <ButtonCircular
                        text={'Удалить альбом'}
                        isSmall={true}
                        onClick={onClickDelete}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </ButtonCircular>
                    {album && album.is_private && (
                        <ButtonCircular
                            text={'Предоставить доступ'}
                            isSmall={true}
                            onClick={onClickDelete}
                        >
                            <FontAwesomeIcon icon={faUserPlus} />
                        </ButtonCircular>
                    )}
                </ActionButton>
            );
        }
    }, [album]);

    useEffect(() => {
        return () => {
            tokenWithFace.remove();
            tokenWithoutFace.remove();
            token.remove();
            tokenAlbum.remove();
            tokenEdit.remove();
            cancelToken.remove();
            tokenEditUser.remove();
            tokenUserSelfInfo.remove();
        };
    }, []);

    useEffect(() => {
        refIdsWithoutFaces.current = idsWithoutFaces;
        refIdsWithFaces.current = idsWithFaces;

        const photosWithFaces =  Object.keys(refIdsWithFaces.current).reduce((accum: Array<number>, current: string) => {
            return accum.concat(refIdsWithFaces.current[Number(current)]);
        }, []);
        const photosWithoutFaces =  Object.keys(refIdsWithoutFaces.current).reduce((accum: Array<number>, current: string) => {
            return accum.concat(refIdsWithoutFaces.current[Number(current)]);
        }, []);

        setIsModerationBtn(Boolean(photosWithoutFaces.length));
        setIdsList([...photosWithFaces, ...photosWithoutFaces]);
    }, [JSON.stringify(idsWithFaces), JSON.stringify(idsWithoutFaces)]);

    useEffect(() => {
        refReset.current = resetIdsList;
    }, [resetIdsList]);

    useEffect(() => {
        _requestAlbum();
    }, []);

    const _requestPhotoWithFace = (merge?: boolean, isBefore?: boolean) => {
        if (isBefore) {
            setPendingBeforeWithFace(true);
        } else {
            setPendingWithFace(true);
        }

        const formParams = getFormParams(form.getPayload());
        const params = {
            album_ids: [id],
            faces_detected: true,
            ...formParams
        };

        getAlbumPhotoList({
            cancelToken: tokenWithFace.new(),
            params: {
                limit : PHOTOS_LIMIT,
                offset: merge ? photoWithFace?.length : 0,
                ...params
            }
        }).then((resp) => {
            const newPhotos = merge ? [...photoWithFace, ...resp.photo_list] : resp.photo_list;

            if (newPhotos.length) {
                setPhotoWithFaceMap(sortPhotoArray(newPhotos));
            } else {
                setPhotoWithFaceMap(null);
            }

            setPhotoWithFace(newPhotos);
            setTotalWithFace(resp.total_count);
            setPendingWithFace(false);
            setPendingBeforeWithFace(false);
        }).catch((err) => {
            setPendingWithFace(false);
            setPendingBeforeWithFace(false);
        });
    };

    const _requestPhotoWithoutFace = (merge?: boolean, isBefore?: boolean) => {
        if (isBefore) {
            setPendingBeforeWithoutFace(true);
        } else {
            setPendingWithoutFace(true);
        }

        const formParams = getFormParams(form.getPayload());
        const params = {
            album_ids: [id],
            faces_detected: false,
            ...formParams
        };

        getAlbumPhotoList({
            cancelToken: tokenWithoutFace.new(),
            params: {
                limit : PHOTOS_LIMIT,
                offset: merge ? photoWithoutFace?.length : 0,
                ...params
            }
        }).then((resp) => {
            const newPhotos = merge ? [...photoWithoutFace, ...resp.photo_list] : resp.photo_list;

            if (newPhotos.length) {
                setPhotoWithoutFaceMap(sortPhotoArray(newPhotos));
            } else {
                setPhotoWithoutFaceMap(null);
            }

            setPhotoWithoutFace(newPhotos);
            setTotalWithoutFace(resp.total_count);
            setPendingWithoutFace(false);
            setPendingBeforeWithoutFace(false);
        }).catch((err) => {
            setPendingWithoutFace(false);
            setPendingBeforeWithoutFace(false);
        });
    };

    const _moderationPhotos = () => {
        const photosWithoutFaces =  Object.keys(idsWithoutFaces).reduce((accum: Array<number>, current: string) => {
            return accum.concat(idsWithoutFaces[Number(current)]);
        }, []);

        if (Boolean(photosWithoutFaces.length)) {
            sendModerationPhotos({
                data: {
                    photo_ids: photosWithoutFaces
                }
            })
                .then(() => {
                    show('Выбранные фотографии отправлены на модерацию.');
                })
                .catch((err) => {
                    console.error(err);
                    show('Не удалось отправить заявку на модерацию.', 'warning');
                });
        }
    };

    const onClickModerationPhotos = useCallback(() => {
        _moderationPhotos();
    }, [idsWithoutFaces]);

    const onChangeSelectedWithFaces = useCallback((index: number) => (ids: Array<number>) => {
        const newList = { ...refIdsWithFaces.current };
        newList[index] = ids;

        setIdsWithFaces(newList);
    }, []);

    const onChangeSelectedWithoutFaces = useCallback((index: number) => (ids: Array<number>) => {
        const newList = { ...refIdsWithoutFaces.current };
        newList[index] = ids;

        setIdsWithoutFaces(newList);
    }, []);

    const getFormParams = (data: IPayload) => {
        const faces = data.photo?.tmp_faces;
        let personsNew: Array<DataFilesTmpFacesPersonsItem> = [];

        if(faces?.length) {
            personsNew = faces.reduce((acc: Array<DataFilesTmpFacesPersonsItem>, curr: DataFilesTmpFacesItem) => {
                if(curr.persons?.length) {
                    curr.persons.forEach((person) => {
                        const isExist = acc.find((item) => item.id === person.id);

                        if(person.id && !isExist) {
                            acc.push(person);
                        }
                    });
                }

                return acc;
            }, []);
        }

        return {
            ...(personsNew.length && { person_ids: personsNew.map((item) => item.id) }),
            ...(data.name && { person_name: data.name }),
            ...(data.location?.value && { location_id: data.location?.value }),
            ...(data.event?.value && { event_id: data.event.value }),
            ...(data.price_range?.value_from && { price_from: data.price_range?.value_from }),
            ...(data.price_range?.value_to && { price_to: data.price_range?.value_to }),
            ...(data.event_date_range?.date_from && { date_from: data.event_date_range?.date_from }),
            ...(data.event_date_range?.date_to && { date_end: data.event_date_range?.date_to }),
            ...(data.clubs?.length && { club_ids: data.clubs.map((club: IValue) => club.value) }),
            ...(data.color && { color_ids: data.color }),
            ...(data.sport_number && { number: data.sport_number }),
            ...(data.time && { time: data.time }),
            ...(data.timeRange?.valueFrom && { time_from: data.timeRange.valueFrom }),
            ...(data.timeRange?.valueTo && { time_to: data.timeRange.valueTo })
        };
    };

    const elPhotosWithFaces = useMemo(() => {
        if (pendingWithFace) {
            return <Loader />;
        }

        if (photoWithFaceMap) {
            const keys = Object.keys(photoWithFaceMap);

            return keys.map((key, index) => {
                return (
                    <div key={index}>
                        <PhotoList
                            elControls={isEditRight()}
                            dateLink={true}
                            list={photoWithFaceMap[key]}
                            onChangeSelectedList={onChangeSelectedWithFaces(index)}
                            resetSelected={resetIdsList}
                        />
                    </div>
                );
            });
        }

        return <div className={cn('album__empty')}>Фото не найдены</div>;
    }, [JSON.stringify(photoWithFaceMap), resetIdsList, pendingWithFace]);

    const elPhotosWithoutFaces = useMemo(() => {
        if (pendingWithoutFace) {
            return <Loader />;
        }

        if (photoWithoutFaceMap) {
            const keys = Object.keys(photoWithoutFaceMap);

            return keys.map((key, index) => {
                return (
                    <div key={index}>
                        <PhotoList
                            elControls={isEditRight()}
                            dateLink={true}
                            list={photoWithoutFaceMap[key]}
                            onChangeSelectedList={onChangeSelectedWithoutFaces(index)}
                            resetSelected={resetIdsList}
                        />
                    </div>
                );
            });
        }

        return <div className={cn('album__empty')}>Фото не найдены</div>;
    }, [JSON.stringify(photoWithoutFaceMap), resetIdsList, pendingWithoutFace]);

    const onClickMorePhotoWithFace = (): void => {
        _requestPhotoWithFace(true, true);
    };

    const elMorePhotoWithFace = () => {
        if(!pendingWithFace && photoWithFace?.length) {
            if (!(photoWithFace?.length === totalWithFace)) {
                return (
                    <div className={cn('photos__btn-more')}>
                        <Button
                            disabled={pendingBeforeWithFace}
                            isLoading={pendingBeforeWithFace}
                            isSecondary={true}
                            onClick={onClickMorePhotoWithFace}
                        >
                            Загрузить ещё
                        </Button>
                    </div>
                );
            }
        }
    };

    const onClickMorePhotoWithoutFace = (): void => {
        _requestPhotoWithoutFace(true, true);
    };

    const elMorePhotoWithoutFace = () => {
        if(!pendingWithoutFace && photoWithoutFace?.length) {
            if (!(photoWithoutFace?.length === totalWithoutFace)) {
                return (
                    <div className={cn('photos__btn-more')}>
                        <Button
                            disabled={pendingBeforeWithoutFace}
                            isLoading={pendingBeforeWithoutFace}
                            isSecondary={true}
                            onClick={onClickMorePhotoWithoutFace}
                        >
                            Загрузить ещё
                        </Button>
                    </div>
                );
            }
        }
    };

    if (redirect) {
        return <Redirect to="/dashboard/photos" />;
    }

    // Photo Sale Modal
    const [photoSaleModal, setPhotoSaleModal] = useState<boolean>(false);

    const elPhotoSaleModal = useMemo(() => {
        if (photoSaleModal) {
            return (
                <PhotoSaleModal
                    albumId={Number(id)}
                    registry={registry}
                    onClickClose={() => setPhotoSaleModal(false)}
                />
            );
        }
    }, [photoSaleModal]);

    return (
        <SideAction
            title={album?.name ? album.name : ''}
            content={(
                <div>
                    {elSettingsBtn}
                    <section className={cn('album__top')}>
                        <UI.BoxHeader>Фотографии без лиц (требуют модерации)  {totalWithoutFace !== 0 && <span style={{marginLeft: '10px'}}>{totalWithoutFace} фото</span>}</UI.BoxHeader>
                        <UI.Main className={cn('album__top-content')}>
                            <UI.Box padding={true} className={cn('album__content-box')}>
                                {isModerationBtn && (
                                    <div className={cn('album__moderation-btn')}>
                                        <Button onClick={onClickModerationPhotos}>Отправить на модерацию</Button>
                                    </div>
                                )}
                                {elPhotosWithoutFaces}
                                {elMorePhotoWithoutFace()}
                            </UI.Box>
                        </UI.Main>
                    </section>
                    <section className={cn('album__top')}>
                        <UI.BoxHeader>Фотографии для продажи {totalWithFace !== 0 && <span style={{marginLeft: '10px'}}>{totalWithFace} фото</span>}</UI.BoxHeader>
                        <UI.Main className={cn('album__top-content')}>
                            <UI.Box padding={true} className={cn('album__content-box')}>
                                {elPhotosWithFaces}
                                {elMorePhotoWithFace()}
                            </UI.Box>
                        </UI.Main>
                    </section>
                </div>
            )}
            sidebar={elSidebarButton}
            idsList={idsList}
            form={form}
            field={field}
            update={updPhotoInfo}
            onChangeForm={onChangeForm}
            onChangeValidity={setValidity}
        >
            {elModal}
            {elModalAccess}
            {elPhotoSaleModal}
            {elTarifModal}
        </SideAction>
    );
};

// tslint:disable-next-line:max-file-line-count
export default Album;
