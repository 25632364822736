import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import UI from 'component/ui';
import Form from 'component/form';
import Input from 'component/form/input';
import InputEvents from 'component/form/input-events/index-tsx-async';
import InputRange from 'component/form/input-range';
import InputInterval from 'component/form/input-interval';
import InputLocation from 'component/form/input-location';
import InputClubs from 'component/form/input-clubs';
import useClassnames from 'hook/use-classnames';

import { IProps } from './types';
import style from './index.pcss';

const SearchForm: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);
    const { t } = useTranslation();

    return (
        <UI.Box padding={true} className={cn('box')}>
            <UI.BoxHeader>{t('route.search.sidebar.filter-header')}</UI.BoxHeader>
            <Form
                registry={props.registry}
                onChange={props.onChange}
                onChangeValidity={props.onChangeValidity}
            >
                <Input
                    registry={props.field}
                    name="name"
                    type="text"
                    className={cn('form__input-block')}
                    children={t('global.form.items.person')}
                />
                <InputEvents
                    registry={props.field}
                    name="event"
                    clearable={true}
                    children={t('global.form.items.event')}
                    direction="column"
                    className={cn('form__input-block')}
                />
                {/* @TODO инпут range поменять название параметра */}
                <InputRange
                    registry={props.field}
                    minValue={0}
                    maxValue={10000000}
                    className={cn('form__input-block')}
                    name="price_range"
                    direction="column"
                    children={t('global.form.items.cost')}
                />
                <InputInterval
                    registry={props.field}
                    name="date_range"
                    className={cn('form__input-block')}
                    children={t('global.form.items.interval')}
                    direction="column"
                />
                <InputLocation
                    registry={props.field}
                    name="location"
                    className={cn('form__input-block')}
                    children={t('global.form.items.theatre')}
                    direction="column"
                />
                <InputClubs
                    registry={props.field}
                    name="clubs"
                    className={cn('form__input-block')}
                    isMulti={true}
                    children={t('global.form.items.club')}
                    direction="column"
                />
            </Form>
        </UI.Box>
    );
};

export default SearchForm;
