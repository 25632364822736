import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import axios from 'axios';
import moment from 'moment';
import { stringify } from 'query-string';
import debounce from 'lodash.debounce';

import { useClassnames } from 'hook/use-classnames';
import { useCancelToken, useCancelTokens } from 'component/core/cancel-token';
// import { useRespondAd } from 'hook/use-respond-ad';
import UI from 'component/ui';
import Subscribe from 'component/subscribe';
import SearchResult from 'component/search-result';
import InputEvents from 'component/form/input-events';
import InputLocation from 'component/form/input-location';
import InputInterval from 'component/form/input-interval';
import Button from 'component/button';
import Form, { useRegistry } from 'component/form';
import Loader from 'component/loader';
import { DataPhotoListItem } from 'component/api/types/api/photo/smart-search/photos/get/code-200';
import { smartSearchPhotos } from 'component/api/photo';
import { Data as ISubscription } from 'component/api/types/api/subscription/subscription/create/post/code-200';
import { DataEventsPhotosItem } from 'component/api/types/api/main/smart-search/events/get/code-200';

import { IDataPersonEdit } from './types';
import style from './index.pcss';
import useIntersect from 'hook/use-intersect';
import history from 'component/core/history';
import { useSelector } from 'react-redux';
import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';
import PersonEdit from 'component/person-edit';
import api from 'src/api';
import { Event } from 'src/api/events/types';
import SearchPartnerModal from './search-partner/search-partner-modal';
import { Person, UserProfileRetrieve } from 'src/api/persons/types';
import Ok from 'component/icon/ok';

const getDate = (): { currentDate: string, prevDate: string } => {
    const date = new Date();
    const currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    date.setDate(date.getDate() - 7);
    const prevDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    return { currentDate, prevDate };
};

const Person = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [token, listToken] = useCancelTokens(2);
    const { form, field } = useRegistry();

    const { id }: { id: string } = useParams();

    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    // const [showSearchPartner, setShowSearchPartner] = useState<boolean>(false);

    const init = useRef(false);
    // const [onClickRespondCheck, elMessagesSubscription] = useRespondAd();

    const [validity, setValidity] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [listPending, setListPending] = useState<boolean>(false);
    const [listPendingBefore, setListPendingBefore] = useState<boolean>(false);
    const [person, setPerson] = useState<Person | null>(null);
    const [requested, setRequested] = useState<boolean>(false);
    const [list, setList] = useState<Array<Event>>([]);
    const [eventCount, setEventCount] = useState<number>(0);
    const [subscription, setSubscription] = useState<ISubscription | null>(null);
    const [personRetrieve, setPersonRetrieve] = useState<UserProfileRetrieve>();

    // Last photos
    const photosToken = useCancelToken();
    const [totalPhotos, setTotalPhotos] = useState<number>(0);
    const [lastPhotos, setLastPhotos] = useState<Array<DataPhotoListItem>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isNextPage, setIsNextPage] = useState<boolean>(true);

    useEffect(() => {
        if (id) {
            _requestPhotos();
        }

        return () => {
            photosToken.remove();
        };
    }, [id]);

    const _requestPhotos = (): void => {
        const { currentDate, prevDate } = getDate();

        smartSearchPhotos({
            params: {
                person_ids: [id],
                date_from: prevDate,
                date_to: currentDate,
                limit   : 5,
                offset  : 0
            },
            cancelToken: photosToken.new()
        }).then((resp) => {
            setLastPhotos(resp.photo_list);
            setTotalPhotos(resp.total_count);
        }).catch((err) => {
            console.warn(err);
        });
    };

    const _request = (): void => {
        setPending(true);

        api.person.getPerson(Number(id))
            .then((resp: {data: Person}) => {
                setPerson(resp.data);
            })
            .then((data) => {
                api.person.getUserProfileRetrieve(Number(id))
                .then((resp) => {
                    setPersonRetrieve(resp.data);
                    setPending(false);
                    setRequested(true);
                })
                .catch((err) => {
                    setPending(false);
                    setRequested(true);
                });
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setPending(false);
                    setRequested(true);
                }
            });
    };

    const _requestEvents = (merge?: boolean, isBefore?: boolean): void => {
        if (!listPendingBefore) {
            if (isBefore) {
                setListPendingBefore(true);
            } else {
                setListPending(true);
            }

            const payload = form.getPayload();

            if (isNextPage) {
                api.event.list(currentPage, 10, 0, {
                    // tslint:disable-next-line:no-shadowed-variable
                    person_id: [id],
                    ...(payload.location?.value && { location_id: payload.location.value }),
                    ...(payload.event?.value && { event_id: payload.event.value })
                })
                .then((resp) => {
                    setList(
                        merge
                            ? [...list, ...resp.data.results]
                            : resp.data.results
                    );
                    setEventCount(resp.data.count);
                    setListPending(false);
                    setListPendingBefore(false);
                    if (resp.data.next) {
                        setCurrentPage((prev) => prev + 1);
                    } else {
                        setIsNextPage(false);
                    }
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        setListPending(false);
                        setListPendingBefore(false);
                    }
                });
            }
        }
    };

    const _requestSubscription = () => {
        api.person.getPersonSubscription(Number(id))
        .then((resp) => {
            setSubscription(resp.data || null);
        });
    };

    useEffect(() => {
        _request();
        _requestEvents(false, false);
        // _requestUserInfo();
        _requestSubscription();

        return () => {
            token.remove();
            listToken.remove();
        };
    }, [id]);

    // const onClickRespond = () => {
    //     if (onClickRespondCheck() && person) {
    //         setPerson({
    //             ...person,
    //             isRespond: true
    //         });
    //     }
    // };

    // const elAd = useMemo(() => {
    //     if (person) {
    //         return (
    //             <ListItemAd person={person} isAvatarName={false} onClickRespond={onClickRespond} />
    //         );
    //     }
    // }, [person]);

    const onChangeForm = debounce(useCallback(() => {
        if(form.checkValidity()) {
            if(init.current) {
                _requestEvents(false, false);
            } else {
                init.current = true;
            }
        }
    }, [validity, JSON.stringify(form.getPayload()), JSON.stringify(person)]), 300);

    const onReset = useCallback(() => {
        form.clearForm();
        window.scrollTo(0, 0);

        _requestEvents();
    }, []);

    const onClickEditPerson = useCallback(() => {
        if (!isAuth) {
            history.push(`/login?from=/persons/${id}`);

            return;
        }

        setShowEditModal(true);
    }, [isAuth]);

    // const onClickSearchPartner = useCallback(() => {
    //     if (!isAuth) {
    //         history.push(`/login?from=/persons/${id}`);

    //         return;
    //     }

    //     setShowSearchPartner(true);
    // }, [isAuth]);

    const onCloseModal = useCallback(() => {
        setShowEditModal(false);
        // setShowSearchPartner(false);
    }, []);

    // TODO: Надо переписать компонент
    const elPersonEdit = useMemo(() => {
        if(showEditModal && person) {
            return (
                <PersonEdit
                    onCloseModal={onCloseModal}
                    person={person}
                />
            );
        }
    }, [showEditModal, JSON.stringify(person)]);

    // const elSearchPartner = useMemo(() => {
    //     if (showSearchPartner && userId) {
    //         return (
    //             <SearchPartnerModal
    //                 id={Number(id)}
    //                 onCloseModal={onCloseModal}
    //             />
    //         );
    //     }
    // }, [showSearchPartner, JSON.stringify(person)]);

    const  elSubscription = useMemo(() => {
        if(requested && !pending && person?.id) {
            return <Subscribe type="PERSON" subscription={subscription} persons={[person?.id]} className={cn('person__subscription')} />;
        }
    }, [JSON.stringify(person), requested, person, JSON.stringify(subscription)]);

    const elImageBlock = useMemo(() => {
        if(pending) {
            return <Loader />;
        }

        if(person?.photo) {
            return (
                <div className={cn('person__image-block')}>
                    <img alt="Person photo" src={person.photo} className={cn('person__image')} />
                </div>
            );
        }
    }, [JSON.stringify(person), pending]);

    const elAgeLocation = useMemo(() => {
        // let text = person?.date_of_birth ? moment().from(person?.date_of_birth, true) : 'Дата рождения не указана';
        let text = '';

        if(person?.location) {
            text = `${person?.location.name}`;
        }

        return <span className={cn('person__age')}>{text}</span>;
    }, [JSON.stringify(person)]);

    // const elPersonStyle = useMemo(() => {
    //     return (
    //         <div className={cn('person__info-item')}>
    //             <span className={cn('person__info-name')}>{t('route.person.info.style')}</span>
    //             <span className={cn('person__info-value')}>{person?.style || t('route.person.info.style-empty')}</span>
    //         </div>
    //     );
    // }, [JSON.stringify(person)]);

    // const elPersonComment = useMemo(() => {
    //     return (
    //         <div className={cn('person__info-item')}>
    //             <span className={cn('person__info-name')}>{t('route.person.info.comment')}</span>
    //             <span className={cn('person__info-value')}>{person?.club?.name || t('route.person.info.comment-empty')}</span>
    //         </div>
    //     );
    // }, [JSON.stringify(person)]);

    // const elPersonTrainer = useMemo(() => {
    //     return (
    //         <div className={cn('person__info-item')}>
    //             <span className={cn('person__info-name')}>{t('route.person.info.trainer')}</span>
    //             <span className={cn('person__info-value')}>{person?.trainer_name || t('route.person.info.trainer-empty')}</span>
    //         </div>
    //     );
    // }, [JSON.stringify(person)]);

    // const elPersonEvents = useMemo(() => {
    //     return (
    //         <div className={cn('person__info-item')}>
    //             <span className={cn('person__info-name')}>{t('route.person.info.events')}</span>
    //             <span className={cn('person__info-value')}>{person?.events_count || 0}</span>
    //         </div>
    //     );
    // }, [JSON.stringify(person)]);

    const $LoadMoreEvent = useIntersect((entry) => {
        if(entry.isIntersecting) {
            _requestEvents(true, true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const elLoadMoreEvent = () => {
        if (!listPending && list.length) {
            if (eventCount > list.length) {
                return <Loader ref={$LoadMoreEvent} />;
            }
        }
    };

    const elList = useMemo(() => {
        if(listPending) {
            return (
                <UI.Box padding={true} className={cn('person__empty')}>
                    <Loader />
                </UI.Box>
            );
        }

        if(list?.length && !listPending) {
            const query = {person_name: `${person?.last_name} ${person?.first_name}`};

            return (
                <div className={cn('person__results')}>
                    {list.map((item) => (
                        <SearchResult
                            photo_attachments={item.photos}
                            key={item.id}
                            link={`/events/${item.id}`}
                            title={item.name}
                            place={item.location_name}
                            date={item.date}
                            eventId={item.id}
                            query={stringify(query)}
                        />
                    ))}
                    {elLoadMoreEvent()}
                </div>
            );
        }

        return (
            <UI.Box padding={true} className={cn('person__empty')}>
                {t('route.person.results.empty')}
            </UI.Box>
        );
    }, [JSON.stringify(list), listPending, person]);

    const elLastPhoto = useMemo(() => {
        if (lastPhotos.length) {
            const { currentDate, prevDate } = getDate();

            return (
                <SearchResult
                    title={'Фото за последние 7 дней'}
                    link={'/persons/photos'}
                    attachments={lastPhotos.map((item): DataEventsPhotosItem => ({
                        url: item.url,
                        id: item.id,
                        price: item.price,
                        purchased_status: item.purchased_status,
                        upload_at: item.upload_at,
                        original_width: item.original_width,
                        original_height: item.original_height,
                        original_size: item.original_size,
                        event: item.event,
                        tags: item.tags,
                        updated_at: item.updated_at,
                        show_in_profile: item.show_in_profile,
                        vendor_code: item.vendor_code
                    }))}
                    photos_count={totalPhotos}
                    persons={[Number(id)]}
                    query={`date_from=${prevDate}&date_to=${currentDate}`}
                />
            );
        }
    }, [id, lastPhotos, totalPhotos]);

    return (
        <UI.Main className={cn('person')}>
            {elPersonEdit}
            {/* {elSearchPartner} */}
            <div className={cn('person__grid')}>
                <div className={cn('person__sidebar')}>
                    <UI.Box padding={true} className={cn('person__box')}>
                        {elImageBlock}
                        <div className={cn('person__data')}>
                            <div className={cn('person__name-age')}>
                                <h4 className={cn('person__name')}>{person?.full_name}</h4>
                                {elAgeLocation}
                            </div>
                            <div className={cn('person__info')}>
                                {/* {elPersonComment} */}
                                {/* {elPersonEvents} */}
                            </div>
                            <div className={cn('person__btn')}>
                                <Button
                                    onClick={onClickEditPerson}
                                    className={cn('person__button-submit')}
                                >
                                    Рассказать о персоне
                                </Button>
                                <div className={cn('person__search-partner')}>
                                {/* <button className={cn('person__search-partner-show', pending ? 'person__search-partner-show-gray' : '')} onClick={onClickSearchPartner}>
                                    {'Ищу партнера'}
                                </button> */}
                                {personRetrieve?.is_accepted &&
                                    <Ok className={cn('person__search-partner-moderated')} />
                                }
                                </div>

                            </div>
                        </div>
                        {/* {elAd} */}
                    </UI.Box>
                    <Form
                        registry={form}
                        onChangeValidity={setValidity}
                        onChange={onChangeForm}
                    >
                        <UI.Box padding={true} className={cn('person__box')}>
                            <UI.BoxHeader>{t('route.person.sidebar.header')}</UI.BoxHeader>
                            <InputEvents
                                registry={field}
                                name="event"
                                clearable={true}
                                children={t('global.form.items.event')}
                                direction="column"
                                className={cn('person__input-block')}
                            />
                            <InputLocation
                                registry={field}
                                clearable={true}
                                name="location"
                                children={t('global.form.items.theatre')}
                                direction="column"
                                className={cn('person__input-block')}
                            />
                            <InputInterval
                                registry={field}
                                name="date_range"
                                children="Период"
                                direction="column"
                                className={cn('person__input-block')}
                            />
                            <Button
                                onClick={onReset}
                                type="reset"
                                className={cn('person__button-submit')}
                                disabled={pending}
                            >
                                {t('route.search.sidebar.button-reset')}
                            </Button>
                        </UI.Box>
                    </Form>
                </div>
                <div className={cn('person__content')}>
                    {/*{elMessagesSubscription}*/}
                    {elLastPhoto}
                    {elSubscription}
                    {elList}
                </div>
            </div>
        </UI.Main>
    );
};

// tslint:disable-next-line:max-file-line-count
export default Person;
