import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import cancelToken from 'component/core/cancel-token';
import { useClassnames } from 'hook/use-classnames';
import { useSelector } from 'react-redux';
import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';
import UI from 'component/ui';
import Loader from 'component/loader';
import Album from 'component/album';
import { getAvailableAlbumWithPhotosList } from 'component/api/album';
import Button from 'component/button';
import IconError from 'component/icon/error';

import { DataAlbumsItem } from 'component/api/types/api/photo/album/get-available-album-with-photos-list/get/code-200';
import style from './index.pcss';

const ALBUMS_LIMIT = 15;

const InvitedAlbums = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const tokenAlbums = useMemo(cancelToken.create, []);

    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [albums, setAlbums] = useState<Array<DataAlbumsItem>>([]);
    const [totalAlbums, setTotalAlbums] = useState<number>(0);
    const [pendingAlbums, setPendingAlbums] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    if (!isAuth) {
        return <Redirect to="/login" />;
    }

    useEffect(() => {
        _requestGetInviteAlbums();

        return () => {
            tokenAlbums.remove();
        };
    }, []);

    const _requestGetInviteAlbums = (merge?: boolean) => {
        setError(null);
        setPendingAlbums(true);

        getAvailableAlbumWithPhotosList({
            params: {
                limit : ALBUMS_LIMIT,
                offset: merge ? albums?.length : 0
            },
            cancelToken: tokenAlbums.new()
        })
            .then((resp) => {
                const newAlbums = merge ? [...albums, ...resp.albums] : resp.albums;
                setPendingAlbums(false);
                setAlbums(newAlbums);
                setTotalAlbums(resp.total_count);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingAlbums(false);
                }
            });
    };

    const onClickMoreAlbums = (e: MouseEvent): void => {
        e.preventDefault();

        _requestGetInviteAlbums(true);
    };

    const elButtonBeforeAlbums = () => {
        if (!pendingAlbums && albums?.length) {
            if(albums?.length === totalAlbums) {
                return (
                    <span className={cn('invite__empty')}>{t('route.invited-albums.end-empty')}</span>
                );
            }

            return (
                <Button
                    disabled={pendingAlbums}
                    isLoading={pendingAlbums}
                    isSecondary={true}
                    className={cn('invite__button-before')}
                    onClick={onClickMoreAlbums}
                >
                    Загрузить ещё
                </Button>
            );
        }
    };

    const elContent = useMemo(() => {
        if (pendingAlbums) {
            return <Loader />;
        }

        if (albums.length) {
            return (
                <div className={cn('invite__items')}>
                    {albums.map((item, index) => (
                        <Album
                            key={index}
                            name={item.name}
                            id={item.id}
                            user_rights={item.user_rights}
                            isInvited={true}
                            description={item.description}
                            photos={item.photos}
                            photos_count={item.photos_count}
                            date={item.date}
                            created_at={item.created_at}
                            is_private={item.is_private}
                            className={cn('invite__post')}
                        />
                    ))}
                </div>
            );
        }

        return (
            <div className={cn('invite__empty-container')}>
                <UI.Box padding={true} className={cn('invite__empty')}>
                    {t('route.invited-albums.album-empty')}
                </UI.Box>
            </div>
        );
    }, [
        JSON.stringify(albums), pendingAlbums
    ]);

    const elError = useMemo(() => {
        if (error) {
            return (
                <div className={cn('invite__error')}>
                    <IconError className={cn('invite__error-icon')} />
                    {error}
                </div>
            );
        }
    }, [error]);

    return (
        <UI.Main className={cn('invite')}>
            <h1 className={cn('invite__header')}>{t('route.invited-albums.header')}</h1>
            {elError}
            {elContent}
            {elButtonBeforeAlbums()}
        </UI.Main>
    );
};

export default InvitedAlbums;
