import { AxiosRequestConfig } from 'axios';
import { request } from 'component/api/index';

import { Data as UserCartData } from 'component/api/types/api/payment/cart/get_user_cart/get/code-200';

export const createPhotoCartItem = (config?: AxiosRequestConfig) => {
    return request<UserCartData>({
        url   : '/api/payment/cart/photo/create/',
        method: 'post',
        ...config
    });
};

export const createPhotoAdCartItem = (config?: AxiosRequestConfig) => {
    return request<UserCartData>({
        url   : '/api/payment/cart/photo_ad/create/',
        method: 'post',
        ...config
    });
};

export const createAlbumPaidSubscriptionCartItem = (config?: AxiosRequestConfig) => {
    return request<UserCartData>({
        url   : '/api/payment/cart/album_paid_subscription/create/',
        method: 'post',
        ...config
    });
};

export const deleteCartItem = (config?: AxiosRequestConfig) => {
    return request<UserCartData>({
        url   : '/api/payment/cart/delete_user_cart_item/',
        method: 'post',
        ...config
    });
};

export const clearUserCart = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/payment/cart/clear_user_cart/',
        method: 'post',
        ...config
    });
};

export const GetUserCart = (config?: AxiosRequestConfig) => {
    return request<UserCartData>({
        url   : '/api/payment/cart/get_user_cart/',
        ...config
    });
};
