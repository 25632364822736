import React, { useEffect, useState, useRef, useMemo, useCallback, MouseEvent, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Chart from 'chart.js';

import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import Exit from 'component/icon/exit';
import Loader from 'component/loader';
import Error from 'component/error';
import Question from 'component/icon/question';
import Plus from 'component/icon/plus';
import Tags from 'component/icon/tags';
import UI from 'component/ui';
import Input from 'component/form/input';
import Button from 'component/button';
import Form, { useRegistry } from 'component/form';
import Modal from 'component/modal';
import Phone from './phone';

import { getOwnPhotoToSell } from 'component/api/photo';
import {
    getUserRevenue,
    getTopSells,
    getSoldPhotos,
    getSells,
    placeWithdrawal,
    getLatestUserSales
} from 'component/api/payment';
import { DataOrderItemsListItem } from 'component/api/types/api/payment/get-top-sells/get/code-200';

import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';

import img from './img/img.png';
import style from './style.pcss';
import InputInterval from 'component/form/input-interval';
import debounce from 'lodash.debounce';
import ReactTooltip from 'react-tooltip';

const MIN_MONEY_WITHDRAWAL = 1000;
const SELLS_LIMIT = 20;

const Dashboard = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [
        tokenRevenue,
        tokenSold,
        tokenTopsells,
        tokenSells,
        tokenPhotoList,
        tokenWithdrawal,
        tokenLatestUserSales
    ] = useCancelTokens(7);

    const withdrawalRegistry = useRegistry();
    const sellsRegistry = useRegistry();

    const [errorRevenue, setErrorRevenue] = useState<string | null>(null);
    const [errorTopSells, setErrorTopSells] = useState<string | null>(null);
    const [errorSold, setErrorSold] = useState<string | null>(null);
    const [errorSells, setErrorSells] = useState<string | null>(null);
    const [errorPhotoList, setErrorPhotoList] = useState<string | null>(null);
    const [errorWithdrawal, setErrorWithdrawal] = useState<string | null>(null);

    const [pendingRevenue, setPendingRevenue] = useState<boolean>(true);
    const [pendingTopSells, setPendingTopSells] = useState<boolean>(true);
    const [pendingSold, setPendingSold] = useState<boolean>(true);
    const [pendingSells, setPendingSells] = useState<boolean>(true);
    const [pendingSellsBefore, setPendingSellsBefore] = useState<boolean>(false);
    const [pendingPhotoList, setPendingPhotoList] = useState<boolean>(true);
    const [moneyWithdrawal, setMoneyWithdrawal] = useState<boolean>(false);

    const [modalMessageWithdrawal, setModalMessageWithdrawal] = useState<boolean>(false);
    const [modalFormWithdrawal, setModalFormWithdrawal] = useState<boolean>(false);

    const [revenue, setRevenue] = useState<number | null>(null);
    const [sellsTotal, setSellsTotal] = useState<number>(0);
    const [latestUserSales, setLatestUserSales] = useState<number>(0);
    const [lastLogin, setLastLogin] = useState<string | undefined>(undefined);
    const [photoListCount, setPhotoListCount] = useState<number | null>(null);
    const [photoListAllCount, setPhotoListAllCount] = useState<number | null>(null);
    const [sold, setSold] = useState<number | null>(null);
    const [topSells, setTopSells] = useState<Array<DataOrderItemsListItem>>([]);
    const [sells, setSells] = useState<Array<DataOrderItemsListItem>>([]);
    const $canvas = useRef<HTMLCanvasElement>(null);

    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);

    const _requestLatestUserSales = () => {
        getLatestUserSales({
            cancelToken: tokenLatestUserSales.new()
        }).then((resp) => {
            setLatestUserSales(resp.sales);
            setLastLogin(resp.last_login);
        }).catch((err) => {
            if (!axios.isCancel(err)) {
                console.error(err);
            }
        });
    };

    const _requestRevenue = () => {
        setPendingRevenue(true);

        getUserRevenue({
            cancelToken: tokenRevenue.new()
        })
            .then((resp) => {
                setRevenue(resp.revenue);
                setMoneyWithdrawal(resp.revenue ? resp.revenue >= MIN_MONEY_WITHDRAWAL : false);
                setPendingRevenue(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setErrorRevenue(err.message);
                    setPendingRevenue(false);
                }
            });
    };

    const _requestTopSells = () => {
        setPendingTopSells(true);

        getTopSells({
            cancelToken: tokenTopsells.new()
        })
            .then((resp) => {
                setTopSells(resp.order_items_list);
                setPendingTopSells(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setErrorTopSells(err.message);
                    setPendingTopSells(false);
                }
            });
    };

    const _requestSells = (merge?: boolean) => {
        if(merge) {
            setPendingSellsBefore(true);
        } else {
            setPendingSells(true);
        }

        const payload = sellsRegistry.form.getPayload();

        getSells({
            cancelToken: tokenSells.new(),
            params: {
                limit: SELLS_LIMIT,
                offset: merge ? sells?.length : 0,
                ...(payload.date_range?.date_from && { date_from: payload.date_range.date_from }),
                ...(payload.date_range?.date_to && { date_to: payload.date_range.date_to })
            }
        })
            .then((resp) => {
                const items = resp.order_items_list;

                setSellsTotal(resp.total_count);
                setSells((prev) => merge ? [...prev, ...items] : items);
                setPendingSells(false);
                setPendingSellsBefore(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setErrorSells(err.message);
                    setPendingSells(false);
                    setPendingSellsBefore(false);
                }
            });
    };

    const _requestSold = () => {
        setPendingSold(true);

        getSoldPhotos({
            cancelToken: tokenSold.new()
        })
            .then((resp) => {
                setSold(resp.count);
                setPendingSold(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setErrorSold(err.message);
                    setPendingSold(false);
                }
            });
    };

    const _requestOwnPhotoList = () => {
        setPendingPhotoList(true);

        getOwnPhotoToSell({
            cancelToken: tokenPhotoList.new()
        })
            .then((resp) => {
                setPhotoListCount(resp.on_sale_count);
                setPhotoListAllCount(resp.total_count);
                setPendingPhotoList(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setErrorPhotoList(err.message);
                    setPendingPhotoList(false);
                }
            });
    };

    const _requestMoneyWithdrawal = (amount: number) => {
        setPendingRevenue(true);
        setErrorWithdrawal(null);

        placeWithdrawal({
            data: {
                amount
            },
            cancelToken: tokenWithdrawal.new()
        })
            .then(() => {
                setModalFormWithdrawal(false);
                setModalMessageWithdrawal(true);
                setPendingRevenue(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);
                    setErrorWithdrawal(err.message);
                    setPendingRevenue(false);
                }
            });
    };

    const onClickCloseMessage = (): void => {
        setModalFormWithdrawal(false);
        setModalMessageWithdrawal(false);
    };

    const onClickMore = (e: MouseEvent): void => {
        e.preventDefault();

        _requestSells(true);
    };

    const onSubmitWithdrawal = useCallback(() => {
        const fields = withdrawalRegistry.form.getFields();
        if (fields) {
            const fieldAmount = fields.get('amount_withdrawal');
            if (fieldAmount) {
                if (!fieldAmount.value) {
                    fieldAmount.setError(t('route.dashboard.error.no-amount'));
                } else {
                    _requestMoneyWithdrawal(Number(fieldAmount.value));
                }
            }
        }
    }, [modalMessageWithdrawal, pendingRevenue, modalFormWithdrawal]);

    const elModalMessageWithdrawal = useMemo(() => {
        if (modalFormWithdrawal) {
            return (
                <Modal onClickClose={onClickCloseMessage}>
                    <Form
                        registry={withdrawalRegistry.form}
                        className={cn('dashboard__modal-withdrawal')}
                        onSubmit={onSubmitWithdrawal}
                    >
                        <Input
                            registry={withdrawalRegistry.field}
                            name="amount_withdrawal"
                            children={t('route.dashboard.modal.withdrawal.revenue')}
                            defaultValue={String(revenue)}
                            placeholder={t('route.dashboard.modal.withdrawal.revenue-placeholder')}
                            className={cn('dashboard__withdrawal-input')}
                            disabled={true}
                        />
                        <Button
                            type="submit"
                            disabled={pendingRevenue}
                            isLoading={pendingRevenue}
                            className={cn('dashboard__grid-item_phone-button')}
                        >
                            {t('route.dashboard.modal.withdrawal.button')}
                        </Button>
                    </Form>
                    {errorWithdrawal && <Error>{errorWithdrawal}</Error>}
                </Modal>
            );
        }

        if (modalMessageWithdrawal) {
            return (
                <Modal onClickClose={onClickCloseMessage}>
                    <div className={cn('persons__message')}>
                        {t('route.dashboard.modal.withdrawal.message-ok')}
                    </div>
                </Modal>
            );
        }
    }, [moneyWithdrawal, modalFormWithdrawal, modalMessageWithdrawal, pendingRevenue, revenue, errorWithdrawal]);

    useEffect(() => {
        _requestRevenue();
        // _requestTopSells();
        _requestSold();
        _requestSells();
        _requestOwnPhotoList();
        _requestLatestUserSales();

        return () => {
            tokenRevenue.remove();
            tokenPhotoList.remove();
            tokenSold.remove();
            tokenSells.remove();
            tokenTopsells.remove();
            tokenWithdrawal.remove();
            tokenLatestUserSales.remove();
        };
    }, []);

    useEffect(() => {
        const ctx = $canvas.current?.getContext('2d');

        if(ctx) {
            // tslint:disable-next-line no-unused-expression
            new Chart(ctx, {
                type: 'line',
                data: {
                    labels: [
                        t('route.dashboard.graph.jan').toString(),
                        t('route.dashboard.graph.feb').toString(),
                        t('route.dashboard.graph.mar').toString(),
                        t('route.dashboard.graph.apr').toString(),
                        t('route.dashboard.graph.may').toString(),
                        t('route.dashboard.graph.jun').toString(),
                        t('route.dashboard.graph.jul').toString(),
                        t('route.dashboard.graph.aug').toString(),
                        t('route.dashboard.graph.sep').toString(),
                        t('route.dashboard.graph.oct').toString(),
                        t('route.dashboard.graph.nov').toString(),
                        t('route.dashboard.graph.dec').toString()
                    ],
                    datasets: [
                        {
                            label: t('route.dashboard.graph.label'),
                            backgroundColor: '#FF5487',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                        }
                    ]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });
        }
    }, [JSON.stringify(sells)]);

    const onClickFormWithdrawal = useCallback(() => {
        if (moneyWithdrawal) {
            setErrorWithdrawal(null);
            setModalFormWithdrawal(true);
        }
    }, [modalFormWithdrawal, moneyWithdrawal]);

    const elLatestUserSales = useMemo(() => {
        if (latestUserSales) {
            return (
                <Fragment>
                    <ReactTooltip />
                    <sup data-tip="С последнего входа в систему вы заработали" className={cn('dashboard__latest-sales')}>+{latestUserSales}</sup>
                </Fragment>
            );
        }
    }, [latestUserSales]);

    const elMoney = useMemo(() => {
        if(pendingRevenue) {
            return <Loader />;
        }

        if(errorRevenue) {
            return <Error>{errorRevenue}</Error>;
        }

        return (
            <div className={cn('dashboard__grid-item', 'dashboard__grid-item_money')}>
                <div className={cn('dashboard__item-head')}>
                    <h3 className={cn('dashboard__item-header')}>{t('route.dashboard.stats.balance')}</h3>
                    <Exit
                        className={cn('dashboard__icon', {'dashboard__icon_exit': moneyWithdrawal})}
                        onClick={onClickFormWithdrawal}
                    />
                </div>
                <span className={cn('dashboard__stats-text')}>{revenue} {t('route.dashboard.money.unit')} {elLatestUserSales}</span>
                {!moneyWithdrawal && <div className={cn('dashboard__stats-info')}>{t('route.dashboard.money.message')}</div>}
            </div>
        );
    }, [moneyWithdrawal, pendingRevenue, latestUserSales]);

    const elPhotoListCount = () => {
        if(pendingPhotoList) {
            return <Loader />;
        }

        if(errorPhotoList) {
            return <Error>{errorPhotoList}</Error>;
        }

        return (
            <a
                href="/dashboard/photos"
                target="_blank"
                className={cn('dashboard__grid-item', 'dashboard__grid-item_count')}
            >
                <h3 className={cn('dashboard__item-header')}>{t('route.dashboard.stats.onsale')}</h3>
                <span className={cn('dashboard__stats-text')}>{photoListCount}</span>
                <span className={cn('dashboard__stats-separator')}> {t('route.dashboard.stats.from')} </span>
                <span className={cn('dashboard__stats-text')}>{photoListAllCount}</span>
            </a>
        );
    };

    const elSold = () => {
        if(pendingSold) {
            return <Loader />;
        }

        if(errorSold) {
            return <Error>{errorSold}</Error>;
        }

        return (
            <div className={cn('dashboard__grid-item', 'dashboard__grid-item_sells')}>
                <h3 className={cn('dashboard__item-header')}>{t('route.dashboard.stats.sales')}</h3>
                <span className={cn('dashboard__stats-text')}>{sold}</span>
            </div>
        );
    };

    const elTopSells = () => {
        if(pendingTopSells) {
            return <Loader />;
        }

        if(errorTopSells) {
            return <Error>{errorTopSells}</Error>;
        }

        if(!pendingTopSells && !topSells.length) {
            return <p>{t('route.dashboard.top.empty')}</p>;
        }

        return (
            <div className={cn('dashboard__top')}>
                {topSells.map((item, index) => (
                    <div className={cn('dashboard__top-item')} key={index}>
                        <span className={cn('dashboard__top-number')}>{`${index + 1}.`}</span>
                        <div className={cn('dashboard__top-content')}>
                            <span className={cn('dashboard__top-text')}>{item.photo?.event?.name || t('route.dashboard.top.no-event')}</span>
                            <div className={cn('dashboard__top-sub')}>
                                <span className={cn('dashboard__top-price')}>{`${item.price} ${t('route.dashboard.top.unit')}`}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const onChangeForm = debounce(useCallback(() => {
        _requestSells();
    }, []), 300);

    const elButtonBeforeSells = useMemo(() => {
        if (sells?.length < sellsTotal) {
            return (
                <div className={cn('dashboard__history-button')}>
                    <Button
                        disabled={pendingSellsBefore}
                        isLoading={pendingSellsBefore}
                        isSecondary={true}
                        onClick={onClickMore}
                    >
                        Загрузить ещё
                    </Button>
                </div>
            );
        }
    }, [sells, pendingSellsBefore]);

    const elHistoryList = useMemo(() => {
        if(pendingSells) {
            return <Loader />;
        }

        if(errorSells) {
            return <Error>{errorSells}</Error>;
        }

        if(!pendingSells && !sells.length) {
            return (
                <div className={cn('dashboard__history-empty')}>
                    <p>{t('route.dashboard.history.empty')}</p>
                </div>
            );
        }

        const userLastLogin = moment(lastLogin);

        return (
            <div>
                <div className={cn('dashboard__history-list')}>
                    {sells.map((item, index) => (
                        <div
                            key={index}
                            className={cn('dashboard__history-item')}
                        >
                            {
                                item.photo?.event
                                    ? (
                                        <Link to={`/events/${item.photo.event.id}/${item.photo.id}`}>
                                            <img src={item.photo?.thumbnail_url || item.photo?.url} className={cn('dashboard__history-image')} alt="" />
                                        </Link>
                                    )
                                    : <img src={item.photo?.thumbnail_url || item.photo?.url} className={cn('dashboard__history-image')} alt="" />
                            }
                            <div className={cn('dashboard__history-content')}>
                                {item.photo && (
                                    item.photo?.event
                                        ? (
                                            <Link to={`/events/${item.photo?.event?.id}`}>
                                                <span className={cn('dashboard__history-text')}>{item.photo.event.name}</span>
                                            </Link>
                                        )
                                        : <span className={cn('dashboard__history-text')}>{t('route.dashboard.history.no-event')}</span>

                                )}
                                <span className={cn('dashboard__history-date')}>{moment(item.paid_at).format('LL')}</span>
                                <span
                                    className={cn(
                                        'dashboard__history-sum',
                                        { 'dashboard__history-sum-green': userLastLogin.isBefore(item.paid_at) }
                                    )}
                                >
                                    +{item.price} {t('route.dashboard.history.unit')}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>

                {elButtonBeforeSells}
            </div>
        );
    }, [sells, pendingSells, pendingSellsBefore]);

    const elHistory = () => {
        return (
            <div className={cn('dashboard__history')}>
                <Form
                    registry={sellsRegistry.form}
                    onChange={onChangeForm}
                    className={cn('dashboard__history-form')}
                >
                    <InputInterval
                        registry={sellsRegistry.field}
                        name="date_range"
                        type={'datetime'}
                        children={t('global.form.items.interval')}
                        direction="column"
                    />
                </Form>
                {elHistoryList}
            </div>
        );
    };

    const elControls = () => {
        return (
            <div className={cn('dashboard__grid-item')}>
                <Link to="/upload" className={cn('dashboard__control')}>
                    <Plus className={cn('dashboard__control-icon')} />
                    <div className={cn('dashboard__control-content')}>
                        <span className={cn('dashboard__control-text')}>{t('route.dashboard.controls.upload')}</span>
                    </div>
                </Link>
                <Link to="/persons" className={cn('dashboard__control')}>
                    <Question className={cn('dashboard__control-icon')} />
                    <div className={cn('dashboard__control-content')}>
                        <span className={cn('dashboard__control-text')}>{t('route.dashboard.controls.persons')}</span>
                    </div>
                </Link>
                <Link to="/dashboard/photos" className={cn('dashboard__control')}>
                    <Tags className={cn('dashboard__control-icon')} />
                    <div className={cn('dashboard__control-content')}>
                        <span className={cn('dashboard__control-text')}>{t('route.dashboard.controls.my-photo')}</span>
                    </div>
                </Link>
                <Link to="/dashboard/banners" className={cn('dashboard__control')}>
                    <Tags className={cn('dashboard__control-icon')} />
                    <div className={cn('dashboard__control-content')}>
                        <span className={cn('dashboard__control-text')}>{t('route.dashboard.controls.my-banners')}</span>
                    </div>
                </Link>
            </div>
        );
    };

    if(!userId) {
        return <Redirect to="/login" />;
    }

    return (
        <UI.Main className={cn('dashboard')}>
            {elModalMessageWithdrawal}
            <div className={cn('dashboard__grid')}>
                {elPhotoListCount()}
                {elSold()}
                {elMoney}
                {/*<div className={cn('dashboard__grid-item', 'dashboard__grid-item_graph')}>*/}
                {/*    <h4 className={cn('dashboard__grid-header')}>{t('route.dashboard.graph.title')}</h4>*/}
                {/*    <canvas ref={$canvas} id="chart" />*/}
                {/*</div>*/}
                <div className={cn('dashboard__grid-item_controls')}>
                    <Phone />
                    {elControls()}
                </div>
                <div className={cn('dashboard__grid-item', 'dashboard__grid-item_history')}>
                    <h4 className={cn('dashboard__grid-header')}>{t('route.dashboard.history.title')}</h4>
                    {/*<h4 className={cn('dashboard__grid-header')}>{t('route.dashboard.history.title')} / <Link to="/reports">{t('route.dashboard.history.reports')}</Link></h4>*/}
                    {elHistory()}
                </div>
                {/*<div className={cn('dashboard__grid-item', 'dashboard__grid-item_top')}>*/}
                {/*    <h4 className={cn('dashboard__grid-header')}>{t('route.dashboard.top.title')}</h4>*/}
                {/*    {elTopSells()}*/}
                {/*</div>*/}
            </div>
        </UI.Main>
    );
};

// tslint:disable-next-line max-file-line-count
export default Dashboard;
