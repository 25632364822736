import { createAction } from '@reduxjs/toolkit';

import { Data as UserCartData } from 'component/api/types/api/payment/cart/get_user_cart/get/code-200';

export const addCart = createAction<UserCartData>('@@cart/ADD-CART');

export const removeCartItem = createAction<string>('@@cart/REMOVE-CART-ITEM');

export const clearCart = createAction('@@cart/CLEAR-CART');

export default {
    addCart,
    clearCart,
    removeCartItem
};
