import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import history from 'component/core/history';
import { useDispatch, useSelector } from 'react-redux';

import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import { stringify } from 'query-string';

import IconEdit from 'component/icon/edit';
import IconDelete from 'component/icon/delete';
import IconShare from 'component/icon/share';
import ArrowDown from 'component/icon/arrow-dir-down';

import Modal from 'component/modal';
import Button from 'component/button';
import Error from 'component/error';
import MiniInfo from 'component/mini-info';

import Form, { useRegistry } from 'component/form';
import Input from 'component/form/input';
import InputLocation from 'component/form/input-location';
import InputClubs from 'component/form/input-clubs';
import InputEvents from 'component/form/input-events';
import InputInterval from 'component/form/input-interval';
import { IValue } from 'component/form/input-clubs/types';
import SimilarPersonList from 'component/similar-person-list';

import { IStore } from 'src/types/reducers';
import { key as keyLocalConfig } from 'component/local-config/reducer';
import { key as keyUser } from 'component/user/reducer';
import { setSimilarPersonList } from 'component/local-config/actions';

import { unsubscribe, editSubscription } from 'component/api/subscriptions';

import { IProps } from './types';
import style from './index.pcss';
import api from 'src/api';
import Ok from 'component/icon/ok';
import SearchPartnerModal from 'route/person/search-partner/search-partner-modal';
import { UserProfileRetrieve } from 'src/api/persons/types';

export default (props: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [tokenUnsubscribe, tokenEdit] = useCancelTokens(2);
    const { form, field } = useRegistry();

    const similarPersonList = useSelector<IStore, boolean>((store) => store[keyLocalConfig].similarPersonList);
    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);

    const phoneNumber = useSelector<IStore, string | undefined>((store) => store[keyUser].fps_phone_number);
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);

    const [updatePending, setUpdatePending] = useState<boolean>(false);
    const [deletePending, setDeletePending] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [errorUpdating, setErrorUpdating] = useState<string | null>(null);

    const [pending, setPending] = useState<boolean>(false);
    // const [showSearchPartner, setShowSearchPartner] = useState<boolean>(false);
    const [personRetrieve, setPersonRetrieve] = useState<UserProfileRetrieve>();

    // useEffect(() => {
    //     setPending(true);
    //
    //     if (props.item?.search_persons) {
    //         api.person.getUserProfileRetrieve(Number(props.item?.search_persons[0]?.id))
    //         .then((resp) => {
    //             setPersonRetrieve(resp.data);
    //             setPending(false);
    //         })
    //         .catch((err) => {
    //             console.warn(err);
    //
    //             setPending(false);
    //         });
    //     }
    // }, []);

    // const onClickSearchPartner = () => {
    //     if (!isAuth) {
    //         history.push(`/login?from=/subscriptions`);

    //         return;
    //     }

    //     setShowSearchPartner(true);
    // };

    const onShowHideSimilarPersonList = () => {
        dispatch(setSimilarPersonList(!similarPersonList));
    };

    const generateLink = useCallback(() => {
        const data = {
            ...(props.item.search_person_name && { person_name: props.item.search_person_name }),
            ...(props.item.search_tmp_file && { file_id: props.item.search_tmp_file.id }),
            ...(props.item.search_location && { location_id: props.item.search_location.id }),
            ...(props.item.search_location && { location_name: props.item.search_location.name }),
            ...(props.item.search_club?.id && { club_id: props.item.search_club.id }),
            ...(props.item.search_club?.name && { club_name: props.item.search_club.name }),
            ...(props.item.search_event?.id && { event_id: props.item.search_event.id }),
            ...(props.item?.search_event_date_from && { event_date_from: props.item.search_event_date_from }),
            ...(props.item?.search_event_date_to && { event_date_to: props.item.search_event_date_to })
        };

        return stringify(data);
    }, []);

    const _unsubscribe = () => {
        if(!deletePending) {
            setDeletePending(true);

            unsubscribe({
                data: {
                    subscription_id: props.item.id
                },
                cancelToken: tokenUnsubscribe.new()
            })
                .then(() => {
                    props.onRenewList &&  props.onRenewList();
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        setDeletePending(false);
                        setError(err.message);
                    }
                });
        }
    };

    const _editSubscribe = () => {
        setUpdatePending(true);
        setErrorUpdating(null);

        const payload = form.getPayload();

        const data = {
            id: props.item.id,
            ...(payload.name && { name: payload.name }),
            ...(payload.person_name && { search_person_name: payload.person_name }),
            ...(payload.location && { search_location_id: payload.location.value }),
            ...(payload.club?.value && { search_club_id: payload.club.value }),
            ...(payload.event?.value && { search_event_id: payload.event.value }),
            ...(payload.date_range?.date_from && { search_event_date_from: payload.date_range.date_from }),
            ...(payload.date_range?.date_to && { search_event_date_to: payload.date_range.date_to })
        };

        editSubscription({
            data,
            cancelToken: tokenEdit.new()
        })
            .then(() => {
                props.onRenewList &&  props.onRenewList();
                setShowModal(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setUpdatePending(false);
                    setErrorUpdating(err.message);
                }
            });
    };

    const onShowModal = useCallback(() => {
        setShowModal(true);
    }, []);

    const onClickClose = useCallback(() => {
        setShowModal(false);
        setErrorUpdating(null);
    }, []);

    const elError = useMemo(() => {
        if(error) {
            return <Error>{error}</Error>;
        }
    }, [error]);

    const elErrorUpdating = useMemo(() => {
        if(errorUpdating) {
            return <Error>{errorUpdating}</Error>;
        }
    }, [errorUpdating]);

    useEffect(() => {
        return () => {
            tokenEdit.remove();
            tokenUnsubscribe.remove();
        };
    }, []);

    // const elSearchPartner = useMemo(() => {
    //     if (showSearchPartner && props.item.search_persons && userId) {
    //         return (
    //             <SearchPartnerModal
    //                 id={props.item?.search_persons[0]?.id}
    //                 onCloseModal={() => setShowSearchPartner(false)}
    //                 user={userId}
    //             />
    //         );
    //     }
    // }, [showSearchPartner, JSON.stringify(props.item.search_persons)]);

    const elModal = useMemo(() => {
        if(showModal) {
            const inputLocationDefault: IValue | undefined = props.item.search_location
                ? {
                    label  : props.item.search_location.name,
                    value  : String(props.item.search_location.id),
                    payload: props.item.search_location
                }
                : undefined;

            const inputClubDefault: IValue | undefined = props.item.search_club
                ? {
                    label  : props.item.search_club.name,
                    value  : String(props.item.search_club.id),
                    payload: props.item.search_club
                }
                : undefined;

            return (
                <Modal onClickClose={onClickClose}>
                    <div className={cn('subscription__modal-person')}>
                        <h3 className={cn('subscription__page-header')}>Редактирование подписки</h3>
                        <Form registry={form} className={{ 'form__element': cn('subscription__form') }}>
                            <Input
                                registry={field}
                                name="name"
                                children={t('global.form.items.subscription')}
                                direction="column"
                                className={cn('subscription__input-block')}
                                defaultValue={props.item.name}
                            />
                            <Input
                                registry={field}
                                name="person_name"
                                children={t('global.form.items.person')}
                                direction="column"
                                className={cn('subscription__input-block')}
                                defaultValue={props.item.search_person_name}
                            />
                            <InputLocation
                                registry={field}
                                name="location"
                                clearable={true}
                                defaultValue={inputLocationDefault}
                                children={t('global.form.items.theatre')}
                                className={cn('subscription__input-block')}
                                direction="column"
                                placeholder={t('global.form.placeholders.theatre')}
                            />
                            <InputClubs
                                registry={field}
                                clearable={true}
                                defaultValue={inputClubDefault}
                                name="club"
                                children={t('global.form.items.club')}
                                className={cn('subscription__input-block')}
                                direction="column"
                                placeholder={t('global.form.placeholders.club')}
                            />
                            <InputEvents
                                registry={field}
                                clearable={true}
                                default_id={props.item.search_event?.id}
                                name="event"
                                children={t('global.form.items.event')}
                                className={cn('subscription__input-block')}
                                direction="column"
                                placeholder={t('global.form.placeholders.event')}
                            />
                            <InputInterval
                                registry={field}
                                name="date_range"
                                firstDate={props.item.search_event_date_from ? new Date(props.item.search_event_date_from) : undefined}
                                secondDate={props.item.search_event_date_to ? new Date(props.item.search_event_date_to) : undefined}
                                children={t('global.form.items.interval')}
                                direction="column"
                                className={cn('search__input-block')}
                            />
                            <div className={cn('subscription__modal-buttons')}>
                                <Button onClick={onClickClose} className={cn('subscription__modal-button', 'subscription__modal-button_secondary')} isSecondary={true} isSmall={true}>
                                    Отмена
                                </Button>
                                <Button onClick={_editSubscribe} disabled={updatePending} isLoading={updatePending} className={cn('persons__modal-button')} isSmall={true}>
                                    Сохранить
                                </Button>
                            </div>
                        </Form>
                        {elErrorUpdating}
                    </div>
                </Modal>
            );
        }
    }, [showModal, JSON.stringify(props.item), updatePending, errorUpdating]);

    const elTitle = () => {
        if(props.item.subscription_type === 'SEARCH') {
            return props.item.name;
        }

        if(props.item.subscription_type === 'PERSON' && props.item.search_persons && props.item.search_persons[0]) {
            return (
                <MiniInfo
                    key={props.item.id}
                    className={cn('person-edit__list-item')}
                    avatar={{
                        imgSrc: props.item.search_persons[0].photo_url,
                        linkTo: `/persons/${props.item.search_persons[0].id}`
                    }}
                    titleTo={`/persons/${props.item.search_persons[0].id}`}
                    descTo={`/persons/${props.item.search_persons[0].id}`}
                    title={props.item.search_persons[0].name}
                    desc={props.item.search_persons[0].date_of_birth}
                />
            );
        }

        if(props.item.subscription_type === 'PHOTOGRAPHER' && props.item.search_photographer) {
            return (
                <MiniInfo
                    key={props.item.id}
                    className={cn('person-edit__list-item')}
                    title={props.item.search_photographer.first_name}
                    avatar={{
                        linkTo: `/photographer/${props.item.search_photographer.id}`
                    }}
                    desc={props.item.search_photographer.last_name}
                    titleTo={`/photographer/${props.item.search_photographer.id}`}
                    descTo={`/photographer/${props.item.search_photographer.id}`}
                />
            );
        }
    };

    return (
        <div className={cn('subscription')}>
            {/* {elSearchPartner} */}
            <div className={cn('subscription__content')}>
                {elTitle()}
                <div className={cn('subscription__menu')}>
                    {/*<>*/}
                    {/*    {props.item.subscription_type === 'PERSON' && props.item.search_persons && props.item.search_persons[0] && (*/}
                    {/*        <div className={cn('subscription__search-partner')}>*/}
                    {/*        <button*/}
                    {/*            className={*/}
                    {/*                cn('subscription__search-partner-show',*/}
                    {/*                pending ? 'subscription__search-partner-show-gray' : '',*/}
                    {/*                personRetrieve?.is_accepted ? 'subscription__search-partner-show-margin' : '')*/}
                    {/*            }*/}
                    {/*            onClick={onClickSearchPartner}*/}
                    {/*        >*/}
                    {/*            {'Ищу партнера'}*/}
                    {/*        </button>*/}
                    {/*        {personRetrieve?.is_accepted &&*/}
                    {/*            <Ok className={cn('subscription__search-partner-moderated')} />*/}
                    {/*        }*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</>*/}
                    {props.item.subscription_type === 'SEARCH' && (
                        <a title="Выдача" href={`search?${generateLink()}`} className={cn('subscription__menu-item', 'subscription__menu-item_blue')}>
                            <IconShare />
                        </a>
                    )}
                    {props.item.subscription_type === 'SEARCH' && (
                        <div title="Редактировать" onClick={onShowModal} className={cn('subscription__menu-item', 'subscription__menu-item_yellow')}>
                            <IconEdit />
                        </div>
                    )}
                    <div
                        onClick={_unsubscribe}
                        className={cn('subscription__menu-item', 'subscription__menu-item_red', {
                            'subscription__menu-item_disabled': deletePending
                        })}
                        title="Удалить"
                    >
                        <IconDelete />
                    </div>
                    <div
                        onClick={onShowHideSimilarPersonList}
                        className={cn('subscription__menu-item', 'subscription__menu-item_arrow', {
                            'subscription__menu-item_rotate': !similarPersonList
                        })}
                        title="Показать похожих"
                    >
                        <ArrowDown />
                    </div>
                </div>
            </div>
            {similarPersonList && (
                <SimilarPersonList
                    person={{
                        id: props.item?.search_persons?.length && props.item?.search_persons[0].id || 0,
                        first_name: props.item?.search_persons?.length && props.item?.search_persons[0].first_name || undefined,
                        last_name: props.item?.search_persons?.length && props.item?.search_persons[0].last_name || undefined,
                        photo_url: props.item?.search_persons?.length && props.item?.search_persons[0].photo_url || undefined
                    }}
                    similar_persons={
                        props.item.search_persons?.length && props.item.search_persons[0]?.top_similar_persons || []
                    }
                />
            )}
            {elError}
            {elModal}
        </div>
    );
};
