import React, { Fragment, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosResponse } from 'axios';
import { useParams } from 'react-router';
import moment from 'moment';
import debounce from 'lodash.debounce';

import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import UI from 'component/ui';
import Loader from 'component/loader';
import ErrorBlock from 'component/error';
import Form, { useRegistry } from 'component/form';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import history from 'component/core/history';
import InputPhoto from 'component/form/input-photo';
import IconCross from 'component/icon/cross';
import useDidUpdateEffect from 'hook/use-did-update-effect';
import PhotoList from 'component/photo-list';
import { DataPersonsItem } from 'component/api/types/api/main/get-person-list/get/code-200';
import { DataPhotoListItem } from 'component/api/types/api/photo/smart-search/photos/get/code-200';
import { Data as EventInfoData } from 'component/api/types/api/main/get-event-info/get/code-200';
import {
    DataFilesItem,
    DataFilesTmpFacesItem,
    DataFilesTmpFacesPersonsItem
} from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { smartSearchPhotos } from 'component/api/photo';
import { getEventInfo } from 'component/api/main';
import { getFile } from 'component/api/tmp-file';
import { parse, stringify } from 'query-string';
import { normalizeObject } from 'component/helper/normalize-object';
import useIntersect from 'hook/use-intersect';
import FilterForm from 'component/form/filter-form';
import PersonCarousel from 'component/person-carousel';
import CarouselItem from 'component/person-carousel/item';

import style from './index.pcss';
import { getSelfUserInfo } from 'component/api/account';
import { getStatisticsEventRetrieve } from 'src/api/statistics';
import { Statistic } from 'src/api/statistics/types';
import api from 'src/api';
import { PersonItem } from 'src/api/persons/types';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

const PHOTOS_LIMIT = 50;
const PERSONS_LIMIT = 30;

const Event = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [token, photosToken, tokenFile, tokenPersons] = useCancelTokens(4);

    const { id }: { id?: string } = useParams();
    const registry = useRegistry();

    const [validity, setValidity] = useState<boolean>(false);
    const [persons, setPersons] = useState<Array<DataPersonsItem>>([]);
    const [personsList, setPersonsList] = useState<Array<PersonItem>>([]);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());
    const [eventInfo, setEventInfo] = useState<EventInfoData>();
    const [pending, setPending] = useState<boolean>(true);
    const [pendingFile, setPendingFile] = useState<boolean>(false);
    const [pendingPhotos, setPendingPhotos] = useState<boolean>(false);
    const [pendingPersons, setPendingPersons] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [list, setList] = useState<Array<DataPhotoListItem>>([]);
    const [image, setImage] = useState<DataFilesItem | null>(null);
    const [chosenPersonIds, setChosenPersonIds] = useState<Array<number>>([]);
    const [pendingBefore, setPendingBefore] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);

    const [pendingPersonsBefore, setPendingPersonsBefore] = useState<boolean>(false);
    const [totalPersons, setTotalPersons] = useState<number>(0);

    const [showSlider, setShowSlider] = useState<boolean>(false);
    const [personIsAdmin, setPersonIsAdmin] = useState<boolean>(false);
    const [photoInfo, setPhotoInfo] = useState<Array<Statistic>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isNextPage, setIsNextPage] = useState<boolean>(true);

    const _requestPersons = (merge?: boolean, isBefore?: boolean): void => {
        if(!pendingPersonsBefore) {
            if(isBefore) {
                setPendingPersonsBefore(true);
            } else {
                setPendingPersons(true);
            }

            if (!isNextPage) {
                return;
            }

            api.person.getPersonsList(currentPage, PERSONS_LIMIT, {
                event_id: Number(id)
            })
                .then((resp) => {
                    const newList = merge ? [...personsList, ...resp.data.results] : resp.data.results;

                    setPersonsList(newList);
                    setTotalPersons(resp.data.count);

                    setPendingPersons(false);
                    setPendingPersonsBefore(false);
                    if (resp.data.next) {
                        setCurrentPage((prev) => prev + 1);
                    } else {
                        setIsNextPage(false);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    setPendingPersons(false);
                    setPendingPersonsBefore(false);
                });
        }
    };

    const _requestAccount = () => {
        getSelfUserInfo()
        .then((resp) => {
            if (resp.is_admin) {
                setPersonIsAdmin(true);
            }
        })
        .catch((err) => {
            console.warn(err);
        });
    };

    const _requestPhotoInfo = () => {
        getStatisticsEventRetrieve(Number(id))
        .then((resp: AxiosResponse<Statistic>) => {
            setPhotoInfo(() => [resp.data]);
        });
    };

    useEffect(() => {
        _requestPhotoInfo();
    }, []);

    useEffect(() => {
        _requestAccount();
    }, []);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        _request();
    }, []);

    useEffect(() => {
        return () => {
            photosToken.remove();
            token.remove();
            tokenFile.remove();
        };
    }, []);

    useEffect(() => {
        _requestPersons();
    }, [queryParams.only_find_partner_participant]);

    useEffect(() => {
        if(registry.form.checkValidity() && !pendingFile) {
            _requestPhotos();
        }
    }, [JSON.stringify(queryParams), pendingFile]);

    useDidUpdateEffect(() => {
        onChangeForm();
    }, [JSON.stringify(chosenPersonIds)]);

    const _requestFile = (tmp_file_id?: string): void => {
        if(tmp_file_id) {
            setPendingFile(true);

            getFile({
                params: {
                    tmp_file_id,
                    basket: 'SEARCH'
                },
                cancelToken: tokenFile.new()
            })
                .then((resp) => {
                    setImage(resp);
                    setPendingFile(false);
                })
                .catch((err) => {
                    console.warn(err);
                    setPendingFile(false);
                });
        }
    };

    useEffect(() => {
        const query = getNormalizedQuery();

        if(query.file_id) {
            _requestFile(query.file_id);
        } else {
            setPendingFile(false);
        }
    }, []);

    const _requestPhotos = debounce((merge?: boolean, isBefore?: boolean) => {
        if(id && !pendingBefore) {
            if(isBefore) {
                setPendingBefore(true);
            } else {
                setPendingPhotos(true);
            }

            smartSearchPhotos({
                params: {
                    ...queryParams,
                    event_id: id,
                    limit   : PHOTOS_LIMIT,
                    offset  : merge ? list.length : 0
                },
                cancelToken: photosToken.new()
            })
                .then((resp) => {
                    const newList = merge ? [...list, ...resp.photo_list] : resp.photo_list;

                    setList(newList);
                    setTotal(resp.total_count);
                    setPendingPhotos(false);
                    setPendingBefore(false);
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);

                        setError(err.message);
                        setPendingPhotos(false);
                        setPendingBefore(false);
                    }
                });
        }
    }, 300);

    const _request = () => {
        if(id) {
            setPending(true);

            getEventInfo({
                params: {
                    event_id: id
                },
                cancelToken: token.new()
            })
                .then((resp) => {
                    setEventInfo(resp);
                    setPending(false);
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);

                        setError(err.message);
                        setPending(false);
                    }
                });
        }
    };

    const onFileLoaded = (file: DataFilesItem): void => {
        setImage(file);
    };

    const choosePersons = (personId: number) => {
        const index = chosenPersonIds.indexOf(personId);
        let newList = [...chosenPersonIds];

        if(index > -1) {
            newList = newList.filter((item) => item !== personId);
        } else {
            newList.push(personId);
        }

        setChosenPersonIds(newList);
    };

    const onClickPerson = (personId: number) => (event: MouseEvent): void => {
        event.stopPropagation();
        event.preventDefault();

        choosePersons(personId);
    };

    const onChangeForm = debounce(useCallback(() => {
        const payload = registry.form.getPayload();
        const faces = payload.photo?.tmp_faces;
        let personsFaces: Array<DataFilesTmpFacesPersonsItem> = [];

        if(faces?.length) {
            personsFaces = faces.reduce((acc: Array<DataFilesTmpFacesPersonsItem>, curr: DataFilesTmpFacesItem) => {
                if(curr.persons?.length) {
                    curr.persons.forEach((person) => {
                        const isExist = acc.find((item) => item.id === person.id);

                        if(person.id && !isExist) {
                            acc.push(person);
                        }
                    });
                }

                return acc;
            }, []);
        }

        setPersons(personsFaces);

        let person_ids: Array<number> = [];
        if (personsFaces.length) {
            person_ids = chosenPersonIds.length ? chosenPersonIds.map((person_id) => person_id) : personsFaces.map((item) => item.id);
        } else if (personsList.length && chosenPersonIds.length) {
            person_ids = chosenPersonIds.map((person_id) => person_id);
        }

        const data = {
            ...(person_ids.length && {person_ids}),
            ...(payload.photo && { file_id: payload.photo.id }),
            ...(payload.name && { person_name: payload.name }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.club?.value && { club_id: payload.club.value }),
            ...(payload.club?.label && { club_name: payload.club.label }),
            ...(payload.date_range?.date_from && { event_date_from: payload.event_date_range.date_from }),
            ...(payload.date_range?.date_to && { event_date_to: payload.event_date_range.date_to }),
            ...(payload.color && { color_ids: payload.color }),
            ...(payload.sport_number && { number: payload.sport_number }),
            ...(payload.time && { time: payload.time }),
            ...(payload.partner_search && { only_find_partner_participant: payload.partner_search }),
            ...(payload.suit_sale && { suit_sale: payload.suit_sale }),
            ...(payload.timeRange?.valueFrom && { time_from: payload.timeRange.valueFrom }),
            ...(payload.timeRange?.valueTo && { time_to: payload.timeRange.valueTo }),
            ...(queryParams.photographer_id && { photographer_id: queryParams.photographer_id })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [
        JSON.stringify(chosenPersonIds),
        validity,
        JSON.stringify(image),
        JSON.stringify(registry.form.getPayload()),
        JSON.stringify(persons),
        JSON.stringify(personsList)
    ]), 300);

    const onReset = useCallback(() => {
        setChosenPersonIds([]);
        registry.form.clearForm();
        window.scrollTo(0, 0);

        _requestPhotos();
    }, []);

    const onClickRemove = useCallback((e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setChosenPersonIds([]);
        registry.form.clearForm();
        setImage(null);
    }, []);

    const $bottomPreviousPosts = useIntersect((entry) => {
        if(entry.isIntersecting) {
            _requestPhotos(true, true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const elButtonBeforePosts = () => {
        if(!pendingPhotos && list.length) {
            if (!(list.length === total)) {
                return <Loader ref={$bottomPreviousPosts} />;
            }
        }
    };

    const elImageResult = () => {
        if(image?.url) {
            return (
                <div className={cn('event__image-box')}>
                    <img alt={image.url} className={cn('event__photo-image')} src={image.url} />
                </div>
            );
        }
    };

    const elUploadContent = useMemo(() => {
        return (
            <Fragment>
                {elImageResult()}
                <InputPhoto defaultValue={image || undefined} onFileLoaded={onFileLoaded} registry={registry.field} name="photo" />
                {image?.url && (
                    <div className={cn('event__photo-menu')}>
                        <div onClick={onClickRemove} className={cn('event__photo-menu-item')}>
                            <IconCross className={cn('event__photo-menu-icon')} />
                            Сбросить поиск
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }, [JSON.stringify(image)]);

    const elPhotos = useMemo(() => {
        if(list?.length) {
            const query_data = {...parse(location.search), event_id: id};
            const query = stringify(query_data, { arrayFormat: 'none' });
            const link = `/persons/photos`;

            return (
                <PhotoList
                    className={cn('event__photo-list')}
                    elIcons={false}
                    elControls={false}
                    elPortal={false}
                    list={list}
                    link={link}
                    query={`?${query}`}
                />
            );
        }

        return <span className={cn('event__empty')}>{t('route.event.content.empty')}</span>;
    }, [JSON.stringify(list)]);

    const elContent = useMemo(() => {
        if(pendingPhotos) {
            return <Loader className={cn('event__loader')} />;
        }

        return (
            <Fragment>
                <div className={cn('event__content-photo')}>
                    {elPhotos}
                    {elError}
                    {elButtonBeforePosts()}
                </div>
            </Fragment>
        );
    }, [JSON.stringify(list), error, pendingPhotos]);

    const elError = useMemo(() => {
        if(error) {
            return <ErrorBlock className={cn('event__error')}>{error}</ErrorBlock>;
        }
    }, [error]);

    const elEventDate = useMemo(() => {
        if(eventInfo?.date) {
            return moment(eventInfo?.date).format('LL');
        }
    }, [eventInfo?.date]);

    const elEventLocation = useMemo(() => {
        if(eventInfo?.location) {
            let locationText = eventInfo.location.name;

            if(eventInfo.location.region_name) {
                locationText = `${locationText}, ${eventInfo.location.region_name}`;
            }

            return locationText;
        }
    }, [eventInfo?.location]);

    const elPageHeader = useMemo(() => {
        if(eventInfo?.name) {
            const separate = !elEventLocation || !elEventDate ? '' : ', ';

            return (
                <div className={cn('event__header-wrapper')}>
                    <h1 className={cn('event__header')}>{eventInfo.name}</h1>
                    <div className={cn('event__subheader')}>
                        <div className={cn('event__subheader-count', 'event__subheader-margin')}>
                            <div>
                                Фотографий: {photoInfo[0]?.photo_count}
                            </div>
                            {personIsAdmin && (
                                <div>
                                    На модерации: {photoInfo[0]?.unverified_photo_count}
                                </div>
                            )}
                        </div>
                        <div className={cn('event__subheader-event', 'event__subheader-margin')}>
                            {elEventLocation}{separate}{elEventDate}
                        </div>
                    </div>
                </div>
            );
        }
    }, [eventInfo?.name, photoInfo, personIsAdmin]);

    const $loadMorePersons = useIntersect((entry) => {
        if(entry.isIntersecting) {
            _requestPersons(true, true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const elLoadMorePersons = () => {
        if(!pendingPersons && personsList.length) {
            if (totalPersons !== personsList.length) {
                return <Loader ref={$loadMorePersons} />;
            }
        }
    };

    const elPersonCarousel = useMemo(() => {
        if (pendingPersons) {
            return (
                <UI.Box className={cn('event__box')} padding={true}>
                    <div className={cn('search__persons-content')}>
                        <Loader />
                    </div>
                </UI.Box>
            );
        }

        if (personsList.length) {
            return (
                <PersonCarousel>
                    {
                        personsList.map((item, index) => {
                            const params = {
                                item: {
                                    name: item.full_name,
                                    photo_url: item.photo
                                },
                                onClick: onClickPerson(item.id),
                                isSelected: chosenPersonIds.indexOf(item.id) > -1
                            };

                            return <CarouselItem key={index} {...params} />;
                        })
                    }
                    {elLoadMorePersons()}
                </PersonCarousel>
            );
        }
    }, [pendingPersons, JSON.stringify(personsList), JSON.stringify(chosenPersonIds)]);

    if(pendingFile) {
        return (
            <UI.Main className={cn('event__loader')}>
                <Loader />
            </UI.Main>
        );
    }

    return (
        <UI.Main className={cn('event')}>
            {elPageHeader}
            <div className={cn('event__grid')}>
                <div>
                    {totalPersons > 0 ? (
                        <>
                            {showSlider ? (
                                <>
                                    <div className={cn('event__person-amount')}>
                                        Общее количество персон: {totalPersons}
                                    </div>
                                    <UI.Box padding={false} className={cn('event__slider')}>
                                        {elPersonCarousel}
                                    </UI.Box>
                                </>
                            )
                            : <button className={cn('event__slider-show', !list.length ? 'event__slider-show-gray' : '')} onClick={() => setShowSlider(true)}>Показать всех персон события</button>}
                        </>
                        ) : null}
                    <UI.Box padding={true} className={cn('event__content')}>
                        {elContent}
                    </UI.Box>
                </div>
                <div className={cn('event__sidebar')}>
                    <UI.Box padding={true} className={cn('event__box')}>
                        <UI.BoxHeader>Поиск по фото</UI.BoxHeader>
                        <Form registry={registry.form}>
                            {elUploadContent}
                        </Form>
                    </UI.Box>
                    <UI.Box padding={false} className={cn('event__box')}>
                        <FilterForm
                            registry={registry}
                            color={true}
                            personNumber={true}
                            timeRange={true}
                            name={true}
                            // location={true}
                            // event={true}
                            // comment={true}
                            eventDate={true}
                            // partnerSearch={true}
                            suitSale={true}
                            onChange={onChangeForm}
                            onChangeValidity={setValidity}
                            onReset={onReset}
                        />
                    </UI.Box>
                </div>
            </div>
        </UI.Main>
    );
};

// tslint:disable-next-line max-file-line-count
export default Event;
