import { AxiosRequestConfig } from 'axios';
import { request } from 'component/api/index';
import { Data as ConfigurationCostData } from 'component/api/types/api/configuration/configuration/cost/get/code-200';

export const getConfigurationCost = (config?: AxiosRequestConfig) => {
    return request<ConfigurationCostData>({
        url   : '/api/configuration/configuration/cost/',
        ...config
    });
};
