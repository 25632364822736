import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { parse } from 'query-string';
import axios from 'axios';

import useClassnames from 'hook/use-classnames';
import cancelToken from 'component/core/cancel-token';
import history from 'component/core/history';

import UI from 'component/ui';
import Button from 'component/button';

import Form, { createRegistry } from 'component/form';
import { IPayload, IError } from 'component/form/types';
import FormInput from 'component/form/input';

import { IStore } from 'src/types/reducers';
import { key as keyUser } from 'component/user/reducer';
import { key as keyDeviceInfo } from 'component/device-info/reducer';
import { set, reset } from 'component/user/actions';
import { login, userSelfInfo, yandexAuth } from 'component/api/user';
import { ErrorCode } from 'component/api/types/api/account/login/post/code-400';

import style from './style.pcss';
import { useAlert } from 'component/alert/provider';
import { authTokenCreate } from 'src/api/auth';

import useScript from 'hook/use-script';
import api from 'src/api';

const Login = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const qs = useMemo(() => parse(history.location.search), [history.location.search]);
    const dispatch = useDispatch();
    const { show } = useAlert();
    const registry = useMemo(createRegistry, []);
    const cancelTokenLogin = useMemo(cancelToken.create, []);
    const cancelTokenUserSelfInfo = useMemo(cancelToken.create, []);
    const cancelTokenSendVerifiEmail = useMemo(cancelToken.create, []);

    const isAuth = useSelector<IStore, boolean>((store) => !!store[keyUser].id);
    const isMobile = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].mobile);

    const [pending, setPending] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [errorCode, setErrorCode] = useState<ErrorCode | null>(null);
    const [errors, setErrors] = useState<Array<IError> | null>(null);

    useEffect(() => {
        const promise = useScript('https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js');

        promise.then(
        () => {
            authYa();
        },
        // tslint:disable-next-line: no-shadowed-variable
        (error: { message: string; }) => {
            alert(`Ошибка: ${error.message}`);
        }
        );

        return () => {
            cancelTokenLogin.remove();
            cancelTokenUserSelfInfo.remove();
            cancelTokenSendVerifiEmail.remove();
        };
    }, []);

    const getUserInfo = async (access_token: string) => {
        if(!pending) {
            setPending(true);

            api.auth.authYandexCreate(access_token)
                .then((resp) => {
                    localStorage.setItem('jwt_token', resp.data.access_token);
                    localStorage.setItem('jwt_token_refresh', resp.data.refresh_token);
                })
                .then(() => {
                    return userSelfInfo({
                        cancelToken: cancelTokenUserSelfInfo.new()
                    });
                })
                .then((payload) => {
                    dispatch(set(payload));
                })
                .catch((payload) => {
                    if(!axios.isCancel(payload)) {
                        setError(payload.message);
                        setErrorCode(payload.error_code);
                        setPending(false);

                        show(payload.message, 'danger');

                        if(payload.errors) {
                            setErrors(payload.errors);
                        }

                        dispatch(reset());
                    }
                });
    }
    };

    const authYa = useCallback(() => {
        // @ts-ignore
        YaAuthSuggest.init({
            client_id: process.env.YANDEX_API_CLIENT_ID,
            response_type: 'token',
            redirect_uri: `${location.origin}/auth-ya/suggest/token`
            },
            `${location.origin}`, {
                view: 'button',
                parentId: 'auth-container',
                buttonView: 'main',
                buttonTheme: 'light',
                buttonSize: 'm',
                buttonBorderRadius: 4
             }
         )
            .then((result: {handler: Function}) => {
                return result.handler();
            })
            .then((data: {'access_token': string; 'token_type': string; 'expires_in': string; 'extra_data': {}}) => {
                getUserInfo(data.access_token);
            })
            // tslint:disable-next-line: no-shadowed-variable
            .catch((error: unknown) => {
                alert(`Что-то пошло не так: ${JSON.stringify(error)}`);
            });
    }, [pending]);

    const onSubmit = useCallback((formData: IPayload) => {
        if(isFormValid && !pending) {
            setPending(true);

            if(errorCode) {
                setErrorCode(null);
            }

            if(error) {
                setError(null);
            }

            if(errors) {
                setErrors(null);
            }

            login({
                cancelToken: cancelTokenLogin.new(),
                data       : formData
            })
                .then(() => {
                    authTokenCreate(
                        formData.email,
                        formData.password
                    )
                    .then((resp) => {
                        localStorage.setItem('jwt_token', resp.data.access);
                        localStorage.setItem('jwt_token_refresh', resp.data.refresh);
                    });
                })
                .then(() => {
                    return userSelfInfo({
                        cancelToken: cancelTokenUserSelfInfo.new()
                    });
                })
                .then((payload) => {
                    dispatch(set(payload));
                })
                .catch((payload) => {
                    if(!axios.isCancel(payload)) {
                        setError(payload.message);
                        setErrorCode(payload.error_code);
                        setPending(false);

                        show(payload.message, 'danger');

                        if(payload.errors) {
                            setErrors(payload.errors);
                        }

                        dispatch(reset());
                    }
                });
        }
    }, [isFormValid, pending]);

    const onChangeValidState = useCallback((payload: boolean) => {
        if(isFormValid !== payload) {
            setIsFormValid(payload);
        }
    }, [isFormValid]);

    // const elError = useMemo(() => {
    //     if(error) {
    //         return (
    //             <Error className={cn('login__error')}>
    //                 {error}
    //             </Error>
    //         );
    //     }
    // }, [JSON.stringify(registry.form.getPayload().email), errorCode, error, i18n.language]);

    if(isAuth) {
        if(typeof qs.from === 'string') {
            return <Redirect to={qs.from} />;
        } else if(typeof qs['from-external'] === 'string') {
            location.href = location.origin + qs['from-external'];
        } else if(history.location.state && history.location.state.from) {
            return <Redirect to={history.location.state.from} />;
        } else {
            return <Redirect to="/subscriptions" />;
        }
    }

    return (
        <UI.Main className={cn('login__ui-main')}>
            <Helmet
                title={t('helmet.title.login')}
                meta={[{
                    name   : 'document-state',
                    content: 'static'
                }]}
            />

            <UI.Box padding={true} className={cn('login__ui-box')}>
                <div className={cn('login__content')}>
                    <h2 className={cn('login__header')}>
                        {t('route.login.header')}
                        <Link
                            to="/registration"
                            className={cn('login__link-registration')}
                        >
                            {t('route.login.link.registration')}
                        </Link>
                    </h2>
                    <Form
                        registry={registry.form}
                        className={cn('login__form')}
                        onSubmit={onSubmit}
                        onChangeValidity={onChangeValidState}
                        errors={errors}
                    >
                        <FormInput
                            registry={registry.field}
                            name="email"
                            type="email"
                            className={cn('login__input')}
                            direction="column"
                            required={true}
                            autoFocus={true}
                            tabIndex={1}
                        >
                            {t('route.login.input.email.label')}
                        </FormInput>
                        <FormInput
                            registry={registry.field}
                            name="password"
                            type="password"
                            className={cn('login__input')}
                            direction="column"
                            required={true}
                            tabIndex={2}
                        >
                            {t('route.login.input.password.label')}
                        </FormInput>
                        <Link
                            to={{
                                pathname: '/password/reset',
                                state   : {
                                    modal: !isMobile
                                }
                            }}
                            className={cn('login__link-reset-password')}
                        >
                            {t('route.login.link.reset-password')}
                        </Link>
                        <Button
                            type="submit"
                            className={cn('login__button-submit')}
                            isLoading={pending}
                            disabled={!isFormValid || pending}
                        >
                            Войти
                        </Button>
                    </Form>
                    <div id="auth-container" className={cn('login__auth-container')} />
                </div>
            </UI.Box>
        </UI.Main>
    );
};

export default Login;
