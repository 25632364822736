import { AxiosResponse } from 'axios';
import { Event, EventFilter, EventAPIType } from './types';
import { PaginationResponse } from 'src/api/base';
import httpClient from 'src/utils/http-сlient';
import queryString from 'query-string';

const list = (
    page: number,
    page_size: number,
    is_empty?: number,
    filter?: EventFilter
): Promise<AxiosResponse<PaginationResponse<Event>>> => {
    return httpClient.get<PaginationResponse<Event>>('/api/v2/events/', {
        params: {
            page,
            page_size,
            is_empty,
            ...(filter?.location_id && { location_id: filter.location_id }),
            ...(filter?.year && { year: filter.year }),
            ...(filter?.search && { search: filter.search }),
            ...(filter?.event_id && { event_id: filter.event_id }),
            ...(filter?.person_id && { person_id: filter.person_id }),
            ...(filter?.photographer_id && { photographer_id: filter.photographer_id})
        },
        paramsSerializer(params) {
            return queryString.stringify(params, {arrayFormat: 'none'});
        }
    });
};

export default {
    list
} as EventAPIType;
