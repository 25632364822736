import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassnames } from 'hook/use-classnames';
import Form from 'component/form';
import Input from 'component/form/input';
import Textarea from 'component/form/textarea';
import Button from 'component/button';
import Modal from 'component/modal';
import Checkbox from 'component/form/checkbox';

import style from 'component/modal/album/style.pcss';
import { IProps } from 'component/modal/album/types';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

const AlbumModal: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();

    const [isReq, setIsReq] = useState<boolean>(false);

    const onChange = () => {
        const fields = props.registry.form.getFields();

        if (fields) {
            const fieldPrepaid = fields.get('album_prepaid');

            setIsReq(fieldPrepaid?.value);
        }
    };

    const elRecommendations = (text: string) => {
        return (
            <div className={cn('album-modal__recommendations')}>
                <p>{text}</p>
            </div>
        );
    };

    return (
        <Modal onClickClose={props.onClickClose} className={cn('album-modal')}>
            <h2>{props.title}</h2>
            <Form registry={props.registry.form} onChange={onChange} onSubmit={props.onSubmitClick}>
                <Input
                    registry={props.registry.field}
                    name="album_name"
                    children={t('route.album.modal.name')}
                    defaultValue={props.album?.name}
                    required={true}
                    maxLenght={30}
                    direction="column"
                    disabled={props.pending}
                />
                {
                    isReq && (
                        <Input
                            registry={props.registry.field}
                            name="download_count"
                            // children={'Лимит на скачивание фото'}
                            defaultValue={props.album?.download_count ? String(props.album?.download_count) : ''}
                            required={isReq}
                            min={1}
                            direction="column"
                            disabled={props.pending}
                        >
                            <ReactTooltip
                                id="input-limit"
                                place="top"
                                effect="solid"
                                clickable={true}
                                border={true}
                                borderColor="#dee1e4"
                                backgroundColor="#fff"
                            >
                                <div>
                                    <p>Вы можете указать лимит, который ограничит количество бесплатных</p>
                                    <p>скачиваний фотографий одним пользователем. Так если вы укажите 5,</p>
                                    <p>то пользователь сможет скачать 5 фотографий, а сверх данного лимита</p>
                                    <p>система предложит ему купить фото по выставленной стоимости.</p>
                                </div>
                            </ReactTooltip>
                            Лимит на скачивание фото
                            <span
                                data-type="light"
                                data-tip={true}
                                data-for="input-limit"
                            >
                                <FontAwesomeIcon icon={faQuestion} />
                            </span>
                        </Input>
                    )
                }
                <Textarea
                    registry={props.registry.field}
                    name="album_description"
                    children={t('route.album.modal.description')}
                    defaultValue={props.album?.description}
                    direction="column"
                    disabled={props.pending}
                    maxLenght={100}
                />
                <div className={cn('album-modal__checkbox-wrapper')}>
                    <Checkbox
                        className={{input: cn('album-modal__checkbox')}}
                        classNames={{input: cn('album-modal__checkbox-input')}}
                        registry={props.registry.field}
                        name="album_private"
                        defaultValue={props.album?.is_private}
                        disabled={props.pending}
                    >
                        {t('route.album.modal.private')}
                        <ReactTooltip
                            id="input-private"
                            place="top"
                            effect="solid"
                            clickable={true}
                            border={true}
                            borderColor="#dee1e4"
                            backgroundColor="#fff"
                        >
                            <div>
                                <p>Приватные альбомы позволяют дать доступ к вашим фотографиям ограниченному</p>
                                <p>кругу лиц, которым вы его предоставите.</p>
                            </div>
                        </ReactTooltip>
                        <span
                            data-type="light"
                            data-tip={true}
                            data-for="input-private"
                        >
                            <FontAwesomeIcon icon={faQuestion} />
                        </span>
                    </Checkbox>
                    <Checkbox
                        className={{input: cn('album-modal__checkbox')}}
                        classNames={{input: cn('album-modal__checkbox-input')}}
                        registry={props.registry.field}
                        name="album_prepaid"
                        defaultValue={props.album?.is_prepaid}
                        disabled={props.pending}
                    >
                        Предоплаченный альбом
                        <ReactTooltip
                            id="input-prepaid"
                            place="top"
                            effect="solid"
                            clickable={true}
                            border={true}
                            borderColor="#dee1e4"
                            backgroundColor="#fff"
                        >
                            <div>
                                <p>Данный вид альбома позволяет предоставлять пользователям фотографии бесплатно – вам</p>
                                <p>достаточно создать и оплатить альбом, указать лимиты на скачивание фотографий</p>
                            </div>
                        </ReactTooltip>
                        <span
                            data-type="light"
                            data-tip={true}
                            data-for="input-prepaid"
                        >
                            <FontAwesomeIcon icon={faQuestion} />
                        </span>
                    </Checkbox>
                </div>
                <div className={cn('album-modal__button')}>
                    <Button type="submit" disabled={props.pending} isLoading={props.pending}>
                        {t('route.album.modal.button')}
                    </Button>
                </div>
            </Form>
            {/*{error && <Error className={cn('album__modal-error')}>{error}</Error>}*/}
        </Modal>
    );
};

AlbumModal.defaultProps = {
    pending: false
};

export default AlbumModal;
