import { AxiosRequestConfig } from 'axios';
import { request } from 'component/api/index';

export const sendModerationPhotos = (config?: AxiosRequestConfig) => {
    return request({
        url   : '/api/moderation/photo/',
        method: 'post',
        ...config
    });
};
