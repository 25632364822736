import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { useClassnames } from 'hook/use-classnames';
import { useRespondAd } from 'hook/use-respond-ad';
import { useCancelToken } from 'component/core/cancel-token';
import UI from 'component/ui';
import Loader from 'component/loader';
import Button from 'component/button';
import { smartSearchPhotos } from 'component/api/photo';
import ErrorBlock from 'component/error';
import Carousel from 'component/carousel';
import { DataEventsPhotosItem } from 'component/api/types/api/main/smart-search/events/get/code-200';
import { DataPhotoListEvent } from 'component/api/types/src/components/api/schemas/api/photo/get-own-photo-list/get/code-200';
import { DataPhotoListItem } from 'component/api/types/api/photo/get-photo-list/get/code-200';
import { IStore } from 'src/types/reducers';
import { key as cartKey } from 'route/cart/reducer';
import { IStore as IStoreCart } from 'route/cart/types/reducer';
import { addCart } from 'route/cart/actions';
import ListItemAd from 'component/person-list/list-item-ad';
import PersonList from 'component/person-list';
import { getPersonList } from 'component/api/main';

import style from './index.pcss';
import { IDataPersonsItemExt } from './types';

import Banner from 'component/banner';
import { createPhotoCartItem, deleteCartItem } from 'component/api/cart';
import { key as keyUser } from 'component/user/reducer';

const bytesToMegaBytes = (bytes: number) => (bytes / (1024 * 1024)).toFixed(2);

const Photo = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const token = useCancelToken();

    const [onClickRespondCheck, elMessagesSubscription] = useRespondAd();

    const { id, albumId, photoId }: { id?: string, albumId?: string, photoId?: string } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();

    const [event, setEvent] = useState<DataPhotoListEvent | null>(null);
    const [pending, setPending] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [list, setList] = useState<Array<DataPhotoListItem>>();
    const [currentPhoto, setCurrentPhoto] = useState<DataPhotoListItem | null>(null);
    const [selected, setSelected] = useState<number>(0);

    // Persons
    const personToken = useCancelToken();
    const [pendingPersons, setPendingPersons] = useState<boolean>(false);
    const [personList, setPersonList] = useState<Array<IDataPersonsItemExt>>([]);

    const cart = useSelector<IStore, IStoreCart>((store) => store[cartKey]);
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    // Cart
    const cartToken = useCancelToken();

    useEffect(() => {
        _request();

        return () => {
            token.remove();
            personToken.remove();
        };
    }, []);

    useEffect(() => {
        _requestPersons();
    }, [currentPhoto]);

    useEffect(() => {
        if(photoId) {
            const newSelected = list ? list.findIndex((item) => item.id === parseInt(photoId, 10)) : null;

            setSelected(newSelected || 0);
        }
    }, [photoId, JSON.stringify(list)]);

    useEffect(() => {
        return () => {
            cartToken.remove();
        };
    }, []);

    const _requestCreateCartItem = () => {
        if (currentPhoto) {
            createPhotoCartItem({
                data: {
                    vendor_code: currentPhoto.vendor_code
                },
                cancelToken: cartToken.new()
            })
                .then((resp) => {
                    dispatch(addCart(resp));
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                    }
                });
        }
    };

    const _requestDeleteCartItem = () => {
        if (currentPhoto) {
            deleteCartItem({
                data: {
                    vendor_code: currentPhoto.vendor_code
                },
                cancelToken: cartToken.new()
            })
                .then((resp) => {
                    dispatch(addCart(resp));
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                    }
                });
        }
    };

    // Sidebar
    const onClickAddToCart = () => {
        if (!isAuth) {
            history.push(`/login?from=${history.location.pathname}${history.location.search}`);
        }

        _requestCreateCartItem();
    };

    const onClickRemoveFromCart = (): void => {
        _requestDeleteCartItem();
    };

    const onSelectSlide = (slide: DataEventsPhotosItem) => {
        const newCurrentPhoto = list?.find((item) => item.url === slide.url) || null;

        if(newCurrentPhoto?.id) {
            if (id) {
                history.replace(`/events/${id}/${newCurrentPhoto?.id}`);
            }
            if (albumId) {
                history.replace(`/invited-album/${albumId}/${newCurrentPhoto?.id}`);
            }
        }

        setCurrentPhoto(newCurrentPhoto);
    };

    const onClickRespond = (profile_id: number) => {
        if (onClickRespondCheck()) {
            const newList = personList.map((item) => {
                if (profile_id === item.id) {
                    item.isRespond = true;
                }

                return item;
            });
            setPersonList(newList);
        }
    };

    const _request = () => {
        setPending(true);

        smartSearchPhotos({
            params     : {
                before: 150,
                after: 150,
                cursor: photoId,
                ...(id && {event_id: id}),
                ...(albumId && {album_id: albumId})
            },
            cancelToken: token.new()
        })
            .then((resp) => {
                const photoList = resp.photo_list;
                const newEvent = resp?.photo_list[0] && resp?.photo_list[0].event || null;

                setList(photoList);
                setCurrentPhoto(photoList[0]);
                setEvent(newEvent);
                setPending(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setError(err.message);
                    setPending(false);
                }
            });
    };

    const _requestPersons = (): void => {
        setPendingPersons(true);

        getPersonList({
            params: {
                photo_ids: currentPhoto?.id,
                limit: 1000
            },
            cancelToken: personToken.new()
        })
            .then((resp) => {
                setPersonList(resp.persons);
                setPendingPersons(false);
            })
            .catch((err) => {
                console.warn(err);
                setPendingPersons(false);
            });
    };

    const elContent = useMemo(() => {
        return (
            <UI.Box padding={true} className={cn('photo__content-box')}>
                <UI.BoxHeader>{event?.name}</UI.BoxHeader>
                <Carousel onSelectSlide={onSelectSlide} isModal={false} selected={selected} className={cn('photo__carousel')}>{list}</Carousel>
            </UI.Box>
        );
    }, [JSON.stringify(event), JSON.stringify(list)]);

    const elError = useMemo(() => {
        if(error) {
            return <ErrorBlock className={cn('photo__error')}>{error}</ErrorBlock>;
        }
    }, [error]);

    const elRemoveButton = (show: boolean) => {
        if(show) {
            return (
                <Button
                    className={cn('photo__button', 'photo__button_remove')}
                    isSecondary={true}
                    onClick={onClickRemoveFromCart}
                >
                    {t('route.photo.sidebar.remove-from-cart')}
                </Button>
            );
        }
    };

    const elControls = useMemo(() => {
        const alreadyInCart = Object.keys(cart).find((item) => item === String(currentPhoto?.id));
        const mainButtonProps = {
            onClick  : alreadyInCart ? undefined : onClickAddToCart,
            to       : alreadyInCart ? '/cart' : undefined,
            className: cn('photo__button'),
            disabled : !currentPhoto,
            children : alreadyInCart ? t('route.photo.sidebar.already-in-cart') : t('route.photo.sidebar.add-to-cart')
        };

        return (
            <Fragment>
                {elRemoveButton(!!alreadyInCart)}
                <Button {...mainButtonProps} />
            </Fragment>
        );
    }, [JSON.stringify(currentPhoto), JSON.stringify(cart)]);

    const elPhotoInfo = () => {
        return (
            <UI.Box padding={true} className={cn('photo__sidebar-block')}>
                <UI.BoxHeader>О снимке</UI.BoxHeader>
                <div className={cn('photo__photo-info')}>
                    <div className={cn('photo__photo-info-item')}>
                        <span>Дата</span>
                        <span>
                            {
                                currentPhoto?.created_at
                                    ? moment(currentPhoto?.created_at).format('LL')
                                    : 'Дата не указана'
                            }
                        </span>
                    </div>
                    <div className={cn('photo__photo-info-item')}>
                        <span>Разрешение</span>
                        <span>{`${currentPhoto?.original_width}x${currentPhoto?.original_height}`}</span>
                    </div>
                    {currentPhoto?.original_size && (
                        <div className={cn('photo__photo-info-item')}>
                            <span>Размер</span>
                            <span>{`${bytesToMegaBytes(currentPhoto?.original_size)} Мб`}</span>
                        </div>
                    )}
                    {currentPhoto?.photographer?.id && (
                        <div className={cn('photo__photo-info-item')}>
                            <span>Фотограф</span>
                            <Link
                                to={`/photographer/${currentPhoto.photographer.id}`}
                                className={cn('photo__photo-info-item-link')}
                            >
                                {`${currentPhoto.photographer.first_name} ${currentPhoto.photographer.last_name}`}
                            </Link>
                        </div>
                    )}
                </div>
            </UI.Box>
        );
    };

    const elAdBlock = useMemo(() => {
        if (personList.length) {
            return (
                <UI.Box padding={true} className={cn('photo__sidebar-block')}>
                    {pendingPersons && <Loader />}
                    {!pendingPersons && (
                        <PersonList>
                            {personList.map((item) => <ListItemAd person={item} key={item.id} onClickRespond={onClickRespond} />)}
                        </PersonList>
                    )}
                </UI.Box>
            );
        }
    }, [pendingPersons, personList]);

    const elBanner = useMemo(() => {
        return (
            <UI.Box padding={true} className={cn('photo__sidebar-block')}>
                <UI.BoxHeader>{t('route.photo.sidebar.banner.title')}</UI.BoxHeader>
                <Banner photoId={Number(photoId)} />
            </UI.Box>
        );
    }, [photoId]);

    const elSidebar = useMemo(() => {
        return (
            <div className={cn('photo__sidebar')}>
                <UI.Box padding={true} className={cn('photo__sidebar-block')}>
                    <div className={cn('photo__block-price')}>
                        <div className={cn('photo__price')}>
                            <b>{currentPhoto?.price}₽</b>
                        </div>
                        {elControls}
                    </div>
                </UI.Box>
                {elPhotoInfo()}
                {id && elAdBlock}
                {id && elBanner}
            </div>
        );
    }, [JSON.stringify(currentPhoto), JSON.stringify(cart), pendingPersons, JSON.stringify(personList)]);

    if(pending) {
        return <Loader className={cn('photo__loader')} />;
    }

    return (
        <UI.Main className={cn('photo')}>
            <div className={cn('photo__grid')}>
                <div className={cn('photo__content')}>
                    {elMessagesSubscription}
                    {elError}
                    {elContent}
                </div>
                {elSidebar}
            </div>
        </UI.Main>
    );
};

export default Photo;
